import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useReusablePrescription} from "../../../DoctorsModule/Prescription/hooks/useReusablePrescription";
import React, {useState} from "react";
import {useSundries} from "../../../DoctorsModule/Prescription/hooks/useSundries";
import {NewPrescriptionTable, SundriesData} from "../../../DoctorsModule/Prescription/NewPrescription";
import {RightAlignedContainer} from "../../../Utils/styledComponents";
import {ConfirmationDialog} from "../../../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../../Helpers/history";
import {ModuleTable} from "../../../Utils/Lists/CollapsibleList";


export const useSelfRequestPrescription = ( obj, isInpatientBilling=false) => {
    const  {visit_id,actions,setOpenDialog} = obj
    const [isSundries, setIsSundries] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const {sundryProps} = useSundries({...obj, setIsBlocking})
    const {sundries, setSundries,initialState, handleResetSundries} = sundryProps
    const {drugProps} = useReusablePrescription({...obj, setIsBlocking,previousDrugs:[]})
    const {submitted, prescription, others,  setPrescription, setOthers, setSubmitted,other,
        isSubmitted,  setIsSubmitted, setOther, initialOthers, initialPrescState, drugs, retrieveDrugs, clickDrug,
    openConfirmation, handleCancelConfirmation, handleCloseConfirmation, addItemsToReceipt,
    alert,setAlert,handleCloseAlert} = drugProps

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''


    const handleToggleSundries = (e) => {
        setIsSundries(e.target.checked)
        setIsBlocking(true)
    }

    const handleChangeOther = (event) => {
        setOther(event.target.checked)
        setIsBlocking(true)
    }



    const handleChangeItems = (event, index) => {
        const {name, value} = event.target;
        const arr = prescription.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value, isEdited: !!item.product_bill_detail_id}
            }
            return item
        })
        setPrescription(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const handleChangeOtherMedicine = (event, index) => {
        const {value, name} = event.target
        const arr = others.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setOthers(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    }

    const handleAddOther = () => {
        setOthers([...others, ...initialOthers])
    }

    const handleRemoveOther = (index) => {
        const arr = others.filter((_, idx) => idx !== index)
        setOthers(arr)
        setIsSubmitted('idle')
    }

    const handleAddRow = () => {
        const filtered_Prescription = prescription.filter(item=>!item.isExpired)
        setPrescription([...filtered_Prescription, ...initialPrescState])
        setAlert({...alert, open: false})
    };

    const removeRow = (item_uuid) => {
        const arr = prescription.filter((e) => e.uuid !== item_uuid)
        setPrescription(arr);
        setAlert({...alert, open: false})
    }

    const handleSubmitPrescription = (event) => {
        event.preventDefault();
        const arr = prescription.every(item => ((item.product_id && item.quantity && item.quantity <= item.stock && !item.isExpired) || (other && (!item.product_id && item.quantity && item.other_medicine))))
        setSubmitted(true);
        setIsBlocking(false)
        const sundriesArr = sundries.every(item=>item.product_id)
        const isRequired = isInpatientBilling ? sundriesArr : arr
        if (isRequired) {
            setIsSubmitted('pending');
            const params = {
                bill_type: 3,
                products: prescription.filter(Boolean).filter(drug => drug.product_id && !drug.isExpired).map(presc => ({
                    dosage: presc.dosage,
                    number_of_days: +presc.number_of_days,
                    key_information: presc.key_information,
                    rate: presc.rate,
                    product_id: presc.product_id,
                    frequency: presc.frequency,
                    quantity_prescribed:parseInt(presc.quantity),
                    is_provided: presc.is_provided,
                    is_provider: presc.is_provider,
                    currency_id:presc.currency,
                    quantity_billable:presc.quantity_billable,
                    quantity_to_bill: +presc.quantity_to_bill
                })),
                sundries: sundries.filter(item=>Boolean(item)).filter(item=>item.product_id).map(item=>({
                    bill_type:3,
                    ...item
                }))
            }
            axios.post(`${config.smsUrl}/cdoctor/save_prescription`, {
                visit_id, user_roles_id,
                other_products: others.filter(Boolean).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                    drug.frequency || drug.number_of_days), sundries:[], ...params
            }).then(() => {
                actions.snackbarActions.snackSuccess(`Prescription added successfully`);
                setIsSubmitted('resolved');
                setSubmitted(false);
                setOther(false)
                setIsSundries(false)
                setOthers(initialOthers)
                setPrescription(initialPrescState)
                setSundries(initialState)
                if(setOpenDialog){
                    setOpenDialog(false)
                }
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);

            })
        }
    };

    const handleResetForm = () =>{
        if(setOpenDialog){
            setOpenDialog(false)
        }
        setIsBlockDialog(false)
        setIsBlocking(false)
        const resetProducts = prescription.map(item=>({
            ...item, drug_name: '', dosage: '', disabled: false,generic_name:'', stock:'',
            number_of_days: 0, key_information: '', rate: 0, date: '', invoice_no: '',
            product_id: '', showDrug: false,  frequency: '', isError: false,
            prescription_detial_id: '', bill_id: '', isEdited: false, status:0,currency:'',
            currency_symbol:'', uuid : ''
        }))
        setPrescription(resetProducts)

        const resetOthers = others.map(item=>({
            ...item, other_medicine: '', dosage: '', disabled: false, quantity:0,
            number_of_days: 0, key_information: '', frequency: '', rate: 0
        }))
        setIsSundries(false)

        setOther(false)

        setOthers(resetOthers)

        handleResetSundries()
    }

    const handleClosePrescriptionDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const sundriesHeadData = [ {item:' Sundry Name'}, {item:'Cost'},{item:'Action'} ];

    const prescriptionTable = (
        <NewPrescriptionTable {...{prescription, retrieveDrugs, clickDrug, handleChangeItems, handleAddRow,
            removeRow, handleChangeOther, other, others, handleChangeOtherMedicine,isResolved,alert,
            handleAddOther, handleRemoveOther, submitted, drugs,isSundries, sundries,sundryProps, handleToggleSundries,
            handleCloseAlert}}/>
    )

    const sundriesTable = (
        <ModuleTable headData={sundriesHeadData}>
            <SundriesData {...{...sundryProps,  isEdit:false}} submitted={submitted} />
        </ModuleTable>
    )

    return {
        isResolved,
        isRejected,
        handleSubmitPrescription,
        handleClosePrescriptionDialog,
        renderPrescription:(
            <div  className='mt-3'>
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}
                                    title='Make cash payment'
                                    confirmItems={addItemsToReceipt}
                                    removeItems={handleCloseConfirmation} service='product'/>
                <form onSubmit={handleSubmitPrescription}>
                    {isInpatientBilling ? sundriesTable : prescriptionTable}

                    <RightAlignedContainer>
                        <button type="submit" id='submit_form'
                                className="sms-btn btn btn-sm px-3 mt-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save Prescription'}</button>
                        {/*<button type="button"  id="reset_form"  className="sms-gray-btn btn btn-sm ml-2" >Cancel</button>*/}
                    </RightAlignedContainer>
                </form>

            </div>
        )
    }

}