import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history"

export const useEditRequisition = (actions, match) => {
    const [products, setProducts] = useState([{
        product_name: '', unit: '', quantity_requested: '', isBatch: false,
        quantity: '', product_id: '', available_quantity: '', showProduct: false, batches: [], batch_id: ''
    }]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [state, setState] = useState({
        date_issued: dateConvert(), endorsed_by: '', received_by: '',
        supplied_by: '', remarks: '', date_requested: '', requesting_department: '', from_department: '',
        to_department: ''
    })
    const [requisition, setRequisition] = useState({});
    const [dept, setDept] = useState({form_department_id: '', to_department_id: ''});
    const [openDialog, setDialog] = useState(false);
    const [dispatch_id, setDispatch] = useState('');
    const [allProds, setProds] = useState([]);

    const {requisition_id, status} = match.params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const issued_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState('idle')

    const [current_batches, setCurrentBatches] = useState([]);
    const [current_idx, setCurrentIdx] = useState();


    useEffect(() => {
        const formData = new FormData();
        formData.append('requisition_id', requisition_id);
        //const url = status ? 'pharmacy_requisition_update_form' : 'requisition_update_form'
        //const url =  'pharmacy_requisition_update_form';
        const url = 'requisition_update_form';
        setLoading('loading')
        axios.post(`${config.smsUrl}/cinventory/${url}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            console.log(data)
            const prods = dt.products ? dt.products : [];
            setProds(prods)
            setRequisition(dt)
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
            setLoading('error')
        })
    }, [])

    useEffect(() => {
        const {
            date_created, first_name, last_name, from_department, to_store,
            from_department_name, to_store_name
        } = requisition;
        const dt = date_created ? date_created : dateConvert();
        setState({
            date_issued: dateConvert(), endorsed_by: '', received_by: '',
            supplied_by: '', remarks: '', date_requested: dt,
            requesting_department: '', from_department: to_store_name,
            to_department: from_department_name,
            requested_by: `${!first_name ? '' : first_name} ${!last_name ? '' : last_name}`
        })
        setDept({form_department_id: to_store, to_department_id: from_department})
        const prdts = !allProds ? [] : allProds;
        const prods = prdts.map(item => {
            if (status) {
                return ({
                    product_name: item.product_name,
                    unit: item.unit_name,
                    quantity_requested: item.quantity,
                    quantity: '',
                    product_id: item.product_id,
                    available_quantity: item.available_qty ? (item.available_qty < 0 ? 0 : item.available_qty) : 0,
                })
            } else {
                return ({
                    product_name: item.product_name,
                    batches: item.batches ? item.batches : [],
                    unit: item.name,
                    quantity_requested: item.quantity,
                    quantity: '',
                    product_id: item.product_id,
                    available_quantity: item.available_qty ? (item.available_qty < 0 ? 0 : item.available_qty) : 0,
                    batch_id: ''
                })
            }

        })
        setProducts(prods)
    }, [requisition, allProds])

    const handleChangeBatch = (event, idx) => {
        let allRows = [...products];
        allRows[idx]['batch_id'] = event.value;
        const formData = new FormData();
        formData.append('batch_id', event.value)
        formData.append('product_id', allRows[idx]['product_id'])
        axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_id`, formData).then(response => {
            const res = response.data;
            const data = res ? res : {};
            const t_product = data.total_product ? data.total_product : 0;
            allRows[idx]['available_quantity'] = t_product < 0 ? 0 : t_product;
            allRows[idx]['isBatch'] = false
            setProducts(allRows)
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    }

    const handleOpenDialog = (_id) => {
        setDialog(true);
        setDispatch(_id);
    }

    const handleCloseDialog = () => {
        setDialog(false);
        const arr = [{
            product_name: '', product_id: '', available_quantity: '',
            quantity: '', showProduct: false, isError:false
        }];
        setProducts(arr)
        history.push('/dispatchlist/new');
    }


    const handleChangeItems = (event, index) => {
        const {value} = event.target;
        const productCopy = [...products];
        const arr = productCopy.map((item, idx) => {
            if (idx === index) {
                if (status) {
                    return {...item, quantity: value}
                } else {
                    if (item.batch_id) {
                        return {...item, quantity: value, isBatch: false}
                    } else {
                        return {...item, quantity: '', isBatch: true}
                    }
                }
            }
            return item
        })
        setProducts(arr)

    };


    const handleAddRow = () => {
        const allRows = [...products];
        const row = {
            product_name: '', unit: '', quantity_requested: '', isBatch: false,
            quantity: '', product_id: '', available_quantity: '', showProduct: false, batches: [], batch_id: ''
        };
        allRows.push(row);
        setProducts(allRows)
    };

    const removeRow = (index) => {
        const arr = products.filter((_, idx)=>idx !== index)
        setProducts(arr);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {to_department_id} = dept;
        const productIds = products.filter(drug=>drug.product_id !== '' && drug.quantity !== '').map(({product_id, quantity, batch_id})=>({product_id, quantity, batch_id}))
        const arr = products.every(drug=>drug.product_id !== '' && drug.quantity !== '' && drug.quantity <= drug.available_quantity && drug.batch_id);

        if (arr && to_department_id) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cinventory/add_pharmacy_dispatch_note`, 
            {
                created_by:user_roles_id, 
                to_store:to_department_id, 
                dispatched_products:productIds,
                requisition_id:+requisition_id
            }).then((res) => {
                const data = res.data;
                const dt = !data.data ? {} : data.data;
                const _id = !dt.dispatch_id ? '' : dt.dispatch_id;
                handleOpenDialog(_id);
                setDialog(true);
                actions.snackbarActions.snackSuccess('Products dispatch saved successfully');
                setIsSubmitted('resolved');
                setSubmitted(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);
            })
        }
    };

    const handleClick = () => {
        history.push(status ? `/dispatchnote/${dispatch_id}/${status}` : `/dispatchnote/${dispatch_id}`)
    }

    const handleCancel = () => {
        const arr = [{
            product_name: '', product_id: '', available_quantity: '',
            quantity: '', showProduct: false, isError:false
        }];
        setProducts(arr)
        history.push('/requisitionlist/1')
    }
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const getBatchDetails = (event, idx) => {
        let allRows = [...products];
        if (event.innerText) {
            const formData = new FormData();
            formData.append('batch_id',  event.innerText);
            formData.append('product_id', products[idx]['product_id']);
            axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_id`, formData).then(response => {
                const res = response.data;
                const data = res ? res : {};
                const t_product = data.total_product ? data.total_product : 0;
                allRows[idx]['available_quantity'] = t_product < 0 ? 0 : t_product;
                allRows[idx]['isBatch'] = false;
                allRows[idx]['batch_id'] =  event.innerText;
                setProducts(allRows);      
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        } else {
            allRows[idx]['available_quantity'] = 0;
            allRows[idx]['isBatch'] = true;
            allRows[idx]['batch_id'] = '';
            setProducts(allRows);   
        }
    }

    const handleOnClick = (idx) => {
        if (idx !== current_idx) {
            setCurrentIdx(idx);
        }
        const formData = new FormData();
        formData.append('product_id', products[idx]['product_id']);
        formData.append('location', 1);
        axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_ids`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const batches = data.product ? data.product : []
            const btches = batches.map(item=>({value:item.batch_id, label:item.batch_id}))
            setCurrentBatches(btches);
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }


    const retrieveBatches = (eventTxt, idx) => {
        if (idx !== current_idx) {
            setCurrentIdx(idx);
        }
        const arr = products.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('product_id', item['product_id']);
                formData.append('location', 1);
                let btches = [];
                    axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_ids`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? {} : resp;
                        const batches = data.product ? data.product : [];
                        btches = batches.map(item=>({value:item.batch_id, label:item.batch_id}))
                        setCurrentBatches(btches);
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, test_name: eventTxt, batches: btches, isError: false};
            }
            return item
        })
        setProducts(arr);
    }

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'
    
    return {
        products, isSubmitted, state, issued_by, handleChange, handleClick, handleCancel, requisition,
        handleChangeItems, handleAddRow, removeRow, dispatch_id, handleChangeBatch, status, 
        handleOnClick, current_batches, retrieveBatches, getBatchDetails, handleSubmit, closeSnackBar,
        openDialog, handleOpenDialog, handleCloseDialog, submitted, isLoading, isSuccess, isError,isPending
    }
}
