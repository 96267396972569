import ReusableDialog from "../Utils/Dialogs/ReusableDialog";
import {PatientDetails} from "../Visit/NewService/SelectService";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {ErrorMessage} from "../Utils/styledComponents";
import React, {useState} from "react";
import {useQuery} from "react-query";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";

function ReassignPatient({openAssign, setOpenAssign,isSubmitted, setIsSubmitted, ids, actions}) {
    const [doctor_name, setDoctor] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [department, setDepartment] = useState('');


    const departmentsQuery = useQuery(
        ["departmentsQuery"],
        async () => {
            try {
                const response = await axios.get(`${config.smsUrl}/cvisit/retrieve_department`);

                const data = response.data ?? {}

                let departments = data.departments ?? [];

                departments = departments.map((department) => ({ label: department.department_name, value: department.department_id }))

                return departments;
            } catch (error) {
                logoutErrorMessage(error, null, actions)
            }
        },
        { retry: 1 });

    const departments = departmentsQuery.isSuccess ? departmentsQuery.data : [];


    const retrieveDoctors = (d_id) => {
        const formData = new FormData();
        formData.append('department_id', d_id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const doctors = !dt.doctors ? [] : dt.doctors;
            const arr = doctors.map(doctor => ({
                value: doctor.user_roles_id,
                label: `${doctor.first_name} ${doctor.last_name}`
            }))
            setDoctors(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }



    const handleCloseAssign = () => {
        setOpenAssign(false)
    };


    const handleChangeDoctor = value => {
        setDoctor(value)
    }

    const handleChangeDepartment = value => {
        setDepartment(value)
        retrieveDoctors(value.value)
    }

    const assignDoctor = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const { visit_id } = ids;
        // formData.append('patient_number', patient_number);
        formData.append('visit_id', visit_id);
        formData.append('user_roles_id', doctor_name?.value);
        setSubmitted(true)
        if (doctor_name && department) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cvisit/reassign_patient`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Patient reassigned successfully');
                setIsSubmitted('resolved')
                setOpenAssign(false)
                setDepartment('');
                setDoctor('')
                setSubmitted(false)
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setOpenAssign(false)
            });

        }
    };

const {patient_number,patient_name} = ids

    return(
        <ReusableDialog message='Reassign Doctor' openDialog={openAssign} handleClose={handleCloseAssign}
                        isSubmitted={isSubmitted} handleSubmit={assignDoctor} width='sm'>
            <PatientDetails {...{patient_number,patient_name}}/>
            <div className="my-2">
                <Form.Group>
                    <Label name="Department" htmlFor='department' type/>
                    <CustomSelect onChange={handleChangeDepartment} id='department' value={department} options={departments}/>
                    {(submitted && !department) && <ErrorMessage>This is a required field</ErrorMessage>}
                </Form.Group>
                <Form.Group>
                    <Label name="Doctor" htmlFor='doctor' type/>
                    <CustomSelect onChange={handleChangeDoctor} id='doctor' value={doctor_name} options={doctors}/>
                    {(submitted && !doctor_name) && <ErrorMessage>This is a required field</ErrorMessage>}
                </Form.Group>
            </div>
        </ReusableDialog>
    )
}
export default ReassignPatient