/** @jsxImportSource @emotion/react */
import {jsx } from '@emotion/react'
import React from 'react';
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import { useOutPatientMedicalHistory } from './CustomHooks/useOutPatientMedicalHistory';
import {dateConvert} from '../Utils/ConvertDate';
import { ReusableTreatmentHistory, Spacer } from './ReusableTreatmentHistory';
import {Container, RightAlignedContainer } from '../Utils/styledComponents';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import * as snackbarActions from "../../actions/snackbarActions";
import { BackButton } from '../Utils/Buttons/DataExportationButton';
import {usePrint} from "../Utils/Templates/usePrint";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {PatientInformation} from "./PatientInfo/PatientInformation";
import {
    Border,
    Heading, SelectForm,
    VisitsList,
    ResultsHeading, PreviousTriage, PreviousClinicalNotes,
    PreviousDiagnosis,
} from "./PatientPreviousVisits";
import {PatientMedicalHistory} from "./PatientHistory/PatientMedicalHistory";


const PatientPreviousVisitInfo = ({actions,match}) => {
    const {patient_number} = match.params;

    const {visits, patientInfo, handleOpenVisit, handleClickComponent,option, options, handleChangeOption,
        handleOpenResults,visitDetails,openPrint, handleOpenPrint, handleClosePrint, handleOpenNote,
        handleOpenPrintNote, handleOpenPrintResults} = useOutPatientMedicalHistory(actions, patient_number)



    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');

    const {patientDiagnosis, patientTriage, clinicalNotes, visit_id} = visitDetails

    const triageComponent = (
        patientTriage?.length > 0 ?  <>
                <ResultsHeading>
                    <Heading>Triage</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousTriage {...{patientTriage}}/>
            </>: null
    )

    const clinicalNotesComponent = (
        clinicalNotes?.length > 0 ?
                <Spacer>
                    <ResultsHeading>
                        <Heading>Clinical Notes</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousClinicalNotes {...{clinicalNotes, visit_id, handleOpen:handleOpenPrintNote}}/>
                </Spacer>:  null
    )

    const diagnosisComponent = (
        patientDiagnosis?.length > 0 ?
                <Spacer>
                    <PreviousDiagnosis {...{patientDiagnosis}} />
                </Spacer>
                : null
    )
    return (
        <div className="journals">
            <PageTitle title='Patient History'/>
            <SubHeader title="Doctor" subTitle='Patient History'>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <CustomDialog open={openPrint} handleClose={handleClosePrint} title='Print Preview' maxWidth='lg'>
                <PatientMedicalHistory actions={actions} visit={{patient_number, visit_id}}/>
                {/*<SelectForm {...{options, option, handleChangeOption,handlePrint}}/>*/}
                {/*<div ref={componentRef}>*/}
                {/*    {fontSize}*/}
                {/*    <ReusableTreatmentHistory {...{patient_number, patientInfo, treatmentDetails:visitDetails, option,*/}
                {/*    diagnosisComponent, clinicalNotesComponent, triageComponent,handleOpenResults:handleOpenPrintResults}}/>*/}
                {/*</div>*/}
            </CustomDialog>
    
            <Container>
                <div className="mb-2">
                    <BackButton to='/patientDetails/doctor' text='Patients'/>
                </div>
                <div className='mb-3'>
                    <PatientInformation style={{paddingRight:0, paddingLeft:0, marginBottom:'16px'}} {...patientInfo}
                                    date={dateConvert()}/>
                </div>
                <Heading>Previous Visits</Heading>
                <Border/>
                <VisitsList {...{visits,handleOpenVisit, handleClickComponent,handleOpenPrint,handleOpenResults,handleOpenNote}}/>
               
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientPreviousVisitInfo);
