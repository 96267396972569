import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import { AutoCompleteControlled } from "../Utils/FormInputs/AutoCompleteInput";
import TextField from "../Utils/FormInputs/TextField";
import {useTheme} from "@material-ui/core/styles";
import {ErrorMessage} from "../Utils/styledComponents";

const AddLabParameters = ({hospital_test_name, hospital_test_type,  labTests, showTest, submitted, 
    tests, hospitalTypes, hospital_test_code, positive, result_type,
    handleChangeCode, handleAddRow, removeRow, handleChange, handleChangeHospitalType, handleChangeResultType,
    retrieveTests, getTestDetails, handleSubmit, handleChangePositive,  isPending, isEditable=false}) => {
        const theme = useTheme();
        const them = !theme ? {} : theme;
        const palette = !them.palette ? {} : them.palette;

        const isParam = tests.some(item=>item.hospital_test_detail_id && item.disabled === true)
    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={3}>
                            <Label name="Lab Test"  htmlFor="hospital_test_name"/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <TextField type="text" disabled={true}
                                            name="hospital_test_name"
                                            value={hospital_test_name}
                                            onChange={retrieveTests}
                                            id="hospital_test_name"/>
                            {showTest && <Form.Control as="select" multiple id="lab_tests"
                                                        className="drug-ul lab-ul">
                                {labTests.map((val, index) => (
                                    <option className="drug-li" key={index}
                                            onClick={() => getTestDetails(val.label)}
                                            value={val.value} id="tests">{val.label}</option>
                                ))}
                            </Form.Control>}
                            {(submitted && !hospital_test_name) &&
                            <div className="help-block">Lab test is required</div>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={3}>
                            <Label name="Lab Test Code"  htmlFor="hospital_test_code" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <TextField value={hospital_test_code} onChange={handleChangeCode} type="text"
                                       name="hospital_test_code"
                                       id="hospital_test_code"
                                       submitted={submitted}
                                       />
                            {submitted && !hospital_test_code ? <ErrorMessage>Lab test code is required</ErrorMessage>: null}
                        </Col>
                    </Form.Group>

                    {result_type?.value && result_type?.value === 1 ? <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={3}>
                            <Label name="Parameter" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <AutoCompleteControlled options={[{value: '+ve/-ve', label: 'Positive/Negative'},
                                            {
                                                value: 'reactive/non-reactive',
                                                label: 'Reactive/Non-reactive'
                                            },
                                            {value: 'blood_group', label: 'Blood Group'}]} handleChange={handleChangePositive}  
                                medicine_value={positive} testId="qualitative_parameter"
                                value_text='value' label_text='label' submitted={submitted} errorMsg="Parameter is required" />
                        </Col>
                    </Form.Group> : null}
                </div>
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={3}>
                            <Label name="Lab Type" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                        <AutoCompleteControlled options={hospitalTypes.map(item => ({
                             label: item.hospital_test_type,
                             value: item.hospital_test_type_id
                             }))} submitted={submitted} handleChange={handleChangeHospitalType}  
                     errorMsg="Lab Type is required" medicine_value={hospital_test_type}
                     value_text='value' label_text='label' testId="lab_type"/>  
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={3}>
                            <Label name="Result Type"  type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <AutoCompleteControlled options={[{value: 1, label: 'Qualitative'},
                         {value: 2, label: 'Quantitative'},
                         {value: 3, label: 'Notes'}]} submitted={submitted} handleChange={handleChangeResultType}  
                        errorMsg="Result Type is required" medicine_value={result_type} id="result_type"
                        value_text='value' label_text='label' testId="result_type" isDisabled={isParam}/>
                                        
                        </Col>
                    </Form.Group>
                </div>
            </div>
            { result_type?.value && result_type?.value === 2 ?
            <table className={`table table-sm billing-invoice-table table-bordered ${palette.type === 'dark' &&
            'dark-table dark-table-bordered'}`}>
                <thead>
                <tr>
                    <td width="15%" className={palette.type === 'dark' && 'dark-theme-color'}>Code
                    </td>
                    <td width="15%" className={palette.type === 'dark' && 'dark-theme-color'}>Parameter<span className='error'>*</span>
                    </td>
                    <td width="15%" className={palette.type === 'dark' && 'dark-theme-color'}>Unit
                    </td>
                    <td width="50%" className={palette.type === 'dark' && 'dark-theme-color'}>Reference Range
                    </td>
                    <td width="5%"/>
                </tr>
                </thead>
                <tbody>
                {tests.map((test, index) => (
                    <tr key={index}>
                        <td><TextField type="text"
                                            name="code"
                                            value={test.code}
                                            onChange={(e) => handleChange(e, index)}
                                            id={`code-${index}`} disabled={test.disabled === true}/></td>
                        <td><TextField type="text" submitted={submitted}
                                            name="parameter_name"
                                            value={test.parameter_name}
                                            onChange={(e) => handleChange(e, index)}
                                            id={`parameter_name-${index}`} disabled={test.disabled === true}/>
                            {submitted && !test.parameter_name ? <ErrorMessage>Parameter is required</ErrorMessage>: null}
                        </td>
                        <td><TextField type="text"
                                            name="unit"
                                            value={test.unit}
                                            onChange={(e) => handleChange(e, index)}
                                            id={`unit-${index}`} disabled={test.disabled === true}/></td>

                        <td className={palette.type === 'dark' && 'dark-theme-color'}>
                            <TextField type="text"
                                            name="reference_range"
                                            value={test.reference_range}
                                            onChange={(e) => handleChange(e, index)}
                                            id={`reference_range-${index}`} disabled={test.disabled === true}/>
                        </td>
                        <td>
                            {test.disabled === false && <button onClick={() => removeRow(index)} type="button"
                                    className="btn btn-sm sms-btn-dismiss" id="remove-row">
                                <FontAwesomeIcon icon={faMinus}/>
                            </button>}
                        </td>
                    </tr>
                ))}
                {!isEditable ? <tr>
                    <td colSpan={6}>
                        <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                id="add-row">
                            Add another line
                        </button>
                    </td>
                </tr>: null}
                </tbody>
            </table> : null}
            <button type="submit" disabled={isPending}
                className="btn sms-btn mt-2">{isPending ? "Saving..." : "Save"}</button>
        </form>
    );
};


export default AddLabParameters;
