import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {titleCase} from "../../Users/addUser";


export const useAddParameters = (actions) => {
    const [hospital_test_name, setHospitalTestName] = useState('');
    const [hospital_test_type, setTestType] = useState('');
    const [labTests, setLabTests] = useState([]);
    const [showTest, setShowTest] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [tests, setTests] = useState([{
        hospital_test_detail_id:'',
        parameter_name: '',
        reference_range:'',
        unit: '',
        code: '',
        disabled: false
    }]);
    // const [parameters, setParameters] = useState([]);
    const [hospitalTypes, setHospitalTypes] = useState([]);
    const [hospital_test_code, setHospitalCode] = useState('');
    const [positive, setPositive] = useState('');
    const [result_type, setResultType] = useState({value: 1, label: 'Qualitative'});
    const [hospital_test_id, setId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [edit_id, setEditId] = useState('');
  


    useEffect(() => {
        axios.get(`${config.smsUrl}/claboratory/lab_type_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const all_type = !dt.lab_list ? [] : dt.lab_list;
            setHospitalTypes(all_type);
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    const mapData = (parameters, test) =>{

        setHospitalTestName(test.hospital_test_name);

        setTestType((test.hospital_test_type_id && test.hospital_test_type) ? {
            value: test.hospital_test_type_id ? test.hospital_test_type_id : '',
            label: test.hospital_test_type ? test.hospital_test_type : ''
        } : {value:null, label:null});

        setHospitalCode(test.hospital_test_code ? test.hospital_test_code : '');

        setResultType(test.result_type ? {
            value: test.result_type ? test.result_type : '', label: test.result_type ? (test.result_type === 1 ?
                'Qualitative' : test.result_type === 2 ? 'Quantitative' : 'Notes'):''
        } : {value:null, label:null});

        const params = parameters.map(param => ({
            code: param.sub_test_code,
            unit: param.unit,
            parameter_name: param.sub_test_name,
            reference_range: param.reference_range,
            hospital_test_detail_id:param.hospital_test_detail_id ? param.hospital_test_detail_id:'',
            disabled: true
        }));

        const param = parameters ? parameters : []
        const para = param[0] ? param[0] : {};
        const val = para.sub_test_name ? para.sub_test_name : '';
        const label = val === 'blood_group' ? 'Blood Group' :
            val === '+ve/-ve' ? 'Positive/Negative':
                val === 'reactive/non-reactive' ? 'Reactive/Non-reactive':'';
        setPositive({value:val, label})
        if (params.length > 0) {
            setTests(params)
        }else {
            setTests([{
                hospital_test_detail_id:'',
                parameter_name: '',
                reference_range:'',
                unit: '',
                code: '',
                disabled: false
            }])
        }
    }

    const handleOpenDialog = (hospital_id) => {
        setId(hospital_id)
        setOpenDialog(true)
        const formData = new FormData();
        formData.append('hospital_test_id', hospital_id);
        axios.post(`${config.smsUrl}/claboratory/parameter_update_form`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            const para = !dt.para ? [] : dt.para;
            const details = !dt.test ? {} : dt.test;
            mapData(para, details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    };



    const handleChangePositive = (value) => {
        setPositive(value);
    }

    const handleChangeCode = (event) => {
        setHospitalCode(event.target.value)
    };

    const handleAddRow = () => {
        const row = {parameter_name: '',// critical_low: '', critical_high: '', 
        unit: '', reference_range:'',
        code: '',hospital_test_detail_id:'', disabled: false};
        setTests([...tests, row])
    };

    const removeRow = (index) => {
        const arr = tests.filter((_,idx)=>idx !== index)
        setTests(arr);
    };

    const handleChange = (event, idx) => {
        const {name, value} = event.target;
        const arr = tests.map((item, index)=>{
            if(index === idx){
                return {...item,[name]:value}
            }
            return item
        })
        setTests(arr)
    };

    const handleChangeHospitalType = (value) => {
        setTestType(value)
    };

    const handleChangeResultType = (value) => {
        setResultType(value)
    }

    const retrieveTests = (event) => {
        const formData = new FormData();
        formData.append('hospital_test_name', titleCase(event.target.value));
        if (event.target.value !== '') {
            axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_tests`, formData).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                setLabTests(dt);
            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
        }
        setShowTest(true);
        setHospitalTestName(event.target.value)
    };

    const getTestDetails = (event) => {
        setHospitalTestName(event.innerText);
        setShowTest(false)
    };


    const handleCloseDialog = () => {
        setOpenDialog(false)
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        setSubmitted(true);
        const items = tests.map(item=>({
            ...item,reference_range: +item.reference_range // critical_high: +item.critical_high, critical_low: +item.critical_low
        }))
        //const arr = items.every(item=>item.critical_low && item.critical_high && item.critical_high > item.critical_low)
        const arr = items.every(item=>item.parameter_name)
        const isQualitative = (result_type.value === 1 && positive.value) || (result_type.value === 2 && arr)

        const isNotes = result_type.value === 3 && !isQualitative

        if ((isQualitative || isNotes) && hospital_test_type && hospital_test_code) {
            setIsSubmitted('pending');
            formData.append('hospital_test_id', hospital_test_id);
            formData.append('hospital_test_code', hospital_test_code);
            formData.append('hospital_test_type_id', hospital_test_type.value);
            formData.append('result_type', result_type?.value ? +(result_type.value) : '');
            if (result_type?.value && result_type?.value === 1) {
                formData.append('sub_test_name', positive.value);
            } else {
                tests.filter(item => !item.disabled).forEach(item => {
                    formData.append('sub_test_name', item.parameter_name);
                    formData.append('sub_test_code', item.code);
                    formData.append('unit', item.unit);
                    formData.append('reference_range', item.reference_range);
                    //formData.append('critical_low', item.critical_low);
                    //formData.append('critical_high', item.critical_high);
                });
            }
            axios.post(`${config.smsUrl}/claboratory/add_parameters_to_lab_test`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Lab test parameters added successfully');
                setIsSubmitted('resolved');
                setOpenDialog(false)
                setSubmitted(false)
                const arr = tests.map(item=>({
                    ...item,
                    code: '',
                    unit: '',
                    parameter_name: '',
                    reference_range: '',
                    hospital_test_detail_id:'',
                    disabled: true
                }))
                setTests(arr)
            }).catch(e => {
                setIsSubmitted('rejected');
                errorMessages(e, null, actions)
                setSubmitted(false)
            })
        }

    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const parameterProps = { hospital_test_name, hospital_test_type,  labTests, showTest, submitted, isSubmitted,
        tests,  hospitalTypes, hospital_test_code, positive, result_type, hospital_test_id,
        handleChangeCode, handleAddRow, removeRow, handleChange, handleChangeHospitalType, handleChangeResultType,
        retrieveTests, getTestDetails, handleSubmit, closeSnackbar, setTestType, setHospitalTestName,
        handleChangePositive, edit_id, isPending, isResolved, isRejected}

    return {parameterProps,openDialog, handleOpenDialog, handleCloseDialog, isResolved, edit_id}
}