/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, {useEffect, useRef} from 'react'
import DentalDashboard from "./DentalDashboard";
import DentalPatientDetails from "./DentalPatientDetails";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import {CustomCheckInput, CustomInput, CustomResizableTextarea} from "../Inventory/CustomInput";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {Steppers} from "../Utils/Buttons/Steppers";
import DentalDiagnosisMedication, {DentalDiagnosis} from "./DentalDiagnosisMedication";
import {useDentalDiagnosis} from "./hooks/useDentalDiagnosis";
import {useDentalMedication} from "./hooks/useDentalMedication";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import axios from "axios";
import {config} from "../../Helpers/env";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {usePrint} from "../Utils/Templates/usePrint";
import DentalExaminationReport from "./DentalExaminationReport";

const Heading = styled.h6({
    margin:'0 0 12px 0',
    fontWeight:600,
    fontSize:'14px'
})

const SubHeading = styled.p({
    fontWeight:600,
    fontSize:'13px',
    margin:'10px 0',
    textDecoration:'underline',
    lineHeight:1
})

const DentalContainer = styled.div({
    margin:'0 auto',
    maxWidth:'1000px',
    width:'90%',
})

const printDiv = css`
    display: none;
  @media print{
    display: block;
  }
`

function DentalExamination(props) {

    const {actions, snackbars} = props
    const formRef = useRef()
    const {patient_number, visit_id} = props.match.params
    const {diagnosisProps} = useDentalDiagnosis(formRef)
    const {prescriptionProps} = useDentalMedication({formRef, visit_id})
    const {prescribedDrugs, removedProducts, setPrescribedDrugs} = prescriptionProps
    const {addedDiseases, setAddedDiseases, removedDiagnosis} = diagnosisProps

    const [extraOral, setExtraOral] = React.useState([{label:'Scalp', name:'scalp'},{label:'Hair', name:'hair'},
        {label:'Ears', name:'ears'},{label:'Eyes', name:'eyes'}, {label:'Nose', name:'nose'},{label:'TMJ', name:'tmj'},
        {label:'Lymph', name:'lymph'}, {label:'Nodes', name:'nodes'},{label:'Lips', name:'lips'}])

    const [softIntraOral, setSoftIntraOral] = React.useState([{label:'Tongue', name:'tongue'},{label:'Tonsilis', name:'tonsilis'},
        {label:'Mucosa', name:'mucosa'},{label:'Palate', name:'palate'}, {label:'Gingiva', name:'gingiva'},{label:'Vestibule', name:'vestibule'},
        {label:'Uvula', name:'uvula'}])

    const [hardIntraOral, setHardIntraOral] = React.useState([{label:'Occlusion', name:'occlusion'},{label:'Decayed', name:'decayed'},
        {label:'Filled', name:'filled'},{label:'Missing', name:'missing'}, {label:'Artificial tooth/teeth', name:'artificial_teeth'}])

    const [activeStep, setActiveStep] = React.useState(0);

    const [apiData, setApiData] =  React.useState({})

    const [printData, setPrintData] = React.useState({extraOral:{},softIntraOral:{},hardIntraOral:{}})

    const [id, setDentalId] =  React.useState({dental_exam_id:'', prescription_id:''})
    const [isSubmitted, setIsSubmitted] = React.useState('idle')
    const {dental_exam_id, prescription_id} = id

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;

    const isResolved = isSubmitted === 'resolved'

    const formatArr = (arr = [], obj) =>{
        return arr.map(item=>({
            ...item,value:obj[item['name']],
            ...obj
        }))
    }

    useEffect(() => {
        if (!(visit_id)){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const exam_notes = details.exam_notes ? details?.exam_notes?.filter(item=>item.done_by === user_roles_id) : []
            const notes_obj = exam_notes[0] ? exam_notes[0] : {}
            const dental_exam = details.dental_exam ? details.dental_exam : {}
            const diagnosis = details.diagnosis ? details.diagnosis : {}
            const medication = details.prescription ? details.prescription?.filter(item=>item.prescribed_by === user_roles_id) : []

            const extra_oral = dental_exam.extra_oral ? dental_exam.extra_oral : {}
            const soft_intra_oral = dental_exam.intra_oral_soft_tissue ? dental_exam.intra_oral_soft_tissue : {}
            const hard_intra_oral = dental_exam.intra_oral_hard_tissue ? dental_exam.intra_oral_hard_tissue : {}
            const _id = dental_exam.id ? dental_exam.id : ''
            const med_obj = medication[0] ? medication[0] : {}
            const presc_id = med_obj.prescription_id ? med_obj.prescription_id : ''


            const diagnosis_arr = diagnosis.map(item=>({
                ...item, isAdded:false
            }))


            const obj = diagnosis[diagnosis.length - 1] ? diagnosis[diagnosis.length - 1] : {};
            const id = obj.diagnosis_id ? obj.diagnosis_id : ''

            setExtraOral(formatArr(extraOral, extra_oral))
            setSoftIntraOral(formatArr(softIntraOral, soft_intra_oral))
            setHardIntraOral(formatArr(hardIntraOral, hard_intra_oral))


            setApiData({investigation:dental_exam?.investigations,
                treatment_plan:dental_exam?.treatment_plan,diagnosis_id:id,
                ...notes_obj
            })
            setAddedDiseases(diagnosis_arr)

            setDentalId({dental_exam_id:_id, prescription_id:presc_id})

            const med_arr = medication.map(item=>({
                ...item, isApi:true,
                previous_quantity:item.quantity_prescribed,
                previous_dosage:item.dosage,
                previous_duration:item.number_of_days,
                previous_instructions:item.key_information
            }))

            setPrescribedDrugs(med_arr)

            setPrintData({extraOral: extra_oral,softIntraOral: soft_intra_oral, hardIntraOral: hard_intra_oral})
        }).catch(err => {
            logoutErrorMessage(err,null, actions)
        })
    }, [isResolved, visit_id]);

    function flattenArr(arr=[]) {
        return arr.reduce((result, obj) => {
            result[obj['name']] = obj['value'];
            return result;
        }, {})
    }


    const filteredArr = (arr) =>{
        return arr.filter(item=>Boolean(item)).filter(item=>item.product_id && !item.isExpired)
            .map(({isAdded, isExpired, isApi, status,...presc}) => ({
            ...presc,
            quantity_prescribed:Number(presc.quantity_prescribed),
            bill_details:{
                product_id: presc.product_id,
                rate: +presc.rate,
                quantity:+presc.quantity_prescribed,
                invoice_no: presc.invoice_no ? presc.invoice_no : '',
                bill_id: +presc.bill_id,
                prescription_id: +presc.prescription_id,
                prescription_detial_id: presc.prescription_detial_id
            },
            bill_type:3,
        }))
    }


    const handleSubmit = (event) =>{
        event.preventDefault()
        const formData = new FormData(formRef.current)
        const diagnosis = addedDiseases.filter(item=>item.diagnosis).map(item=>({
            ...item, code:item.code ? item.code : item.diagnosis_code
        }))
        const existing_diagnosis = diagnosis.filter(item=>!item.isNew)
        const new_diagnosis = diagnosis.filter(item=>item.isNew)
        const treatment_plan = formData.get(`treatment_plan`)
        const update_data = dental_exam_id ? {dental_exam_id:dental_exam_id, prescription_id}:{}

        const diagnosis_object = dental_exam_id ? {notes_diagnosis_object: {notes:'',diagnosis_id:apiData?.diagnosis_id},
            removed_diagnosis:removedDiagnosis, diagnosis:existing_diagnosis.filter(item=>!item.isAdded).map(item=>({...item, code:item.diagnosis_code})),
            new_diagnosis:[...existing_diagnosis,...new_diagnosis].filter(item=>item.isAdded).map(item=>({...item, code:item.code ? item.code:''}))} : {
            diagnosis:[...existing_diagnosis, ...new_diagnosis].map(item=>({...item, code:item.code ? item.code:''})),
        }

        const productsEdited = prescribedDrugs.map((item)=>{
            const dosage = formData.get(`dosage_${item['product_id']}`)
            const frequency = formData.get(`frequency_${item['product_id']}`)
            const number_of_days = formData.get(`number_of_days_${item['product_id']}`)
            const quantity_prescribed = formData.get(`quantity_prescribed_${item['product_id']}`)
            const key_information = formData.get(`key_information_${item['product_id']}`)
            const quantity_to_bill = formData.get(`quantity_to_bill_${item['product_id']}`)
            return {...item, number_of_days, dosage, frequency, quantity_prescribed, key_information, quantity_to_bill}
        })

        const invoicesEdited = productsEdited.filter(item=>item.isApi && (Number(item.previous_quantity) !== Number(item.quantity_prescribed) ||
            item.previous_dosage !== item.dosage || item.previous_duration !== item.number_of_days ||  item.previous_instructions !== item.key_information
        ))


        const productsData = dental_exam_id ? {products:filteredArr(prescribedDrugs.filter(item=>!item.prescription_detial_id)),
                invoices_edited:filteredArr(invoicesEdited), invoices_removed:filteredArr(removedProducts.filter(drug => drug.prescription_detial_id))
        } : {products:prescribedDrugs.map(({quantity_prescribed, isAdded, isExpired, status,...item})=>({quantity_prescribed:Number(quantity_prescribed), ...item}))}

        const exam_object = dental_exam_id ? {examination_notes_id:apiData?.examination_notes_id}:{}
        const params = {
            ...update_data,
            user_roles_id,
            exam_notes:{  visit_id,complaints:apiData?.complaints,history_of_presenting_complaints:apiData?.history_of_presenting_complaints,
                past_medical_history:apiData?.past_medical_history, chronic_illness:{},allergy:'',
                allergies:'',past_obstetric_history:'',family_history:'',notes:'',physical_examination:'',
                ...exam_object
            },
            dental_notes:{ visit_id,extra_oral:flattenArr(extraOral), intra_oral_soft_tissue:flattenArr(softIntraOral),
                intra_oral_hard_tissue:flattenArr(hardIntraOral)
            },
            investigation:apiData?.investigation,
            treatment_plan: treatment_plan ? treatment_plan : apiData?.treatment_plan,
            other_products:[],sundries:[],
            visit_id,
            bill_type:3,
            ...productsData,
            diagnosis:{...diagnosis_object,  visit_id, notes:""}
        }

        const url = dental_exam_id ? 'dental_exam_update' : 'add_dental_exam'

        setIsSubmitted('pending')

        axios.post(`${config.smsUrl}/cdental/${url}`, params).then(res => {
            const dt = res.data;
            const _id = dt.dental_exam_id ? dt.dental_exam_id : ''
            const presc_id = dt.prescription_id ? dt.prescription_id : ''
            setDentalId({dental_exam_id:_id, prescription_id:presc_id})
            actions.snackbarActions.snackSuccess('Dental examination saved successfully')
            setIsSubmitted('resolved')
            setActiveStep(0)
            setApiData({})
        }).catch(error => {
            errorMessages(error, null, actions)
            setIsSubmitted('rejected')
        });
    }



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const steps = [`${dental_exam_id ? 'Update':'Record'} examination notes`, `${dental_exam_id ? 'Update':'Record'} diagnosis and medication`];

    const handleNext = () => {
        const formData = new FormData(formRef.current)
        const complaints = formData.get(`complaints`)
        const history_of_presenting_complaints = formData.get(`history_of_presenting_complaints`)
        const past_medical_history = formData.get(`past_medical_history`)
        const investigation = formData.get(`investigation`)
        const treatment_plan = formData.get(`treatment_plan`)
        const extra_oral = extraOral.map(item=>{
            const item_name = formData.get(item['name'])
            return {...item, value:item_name}
        })
        const soft_intra_oral = softIntraOral.map(item=>{
            const item_name = formData.get(item['name'])
            return {...item, value:item_name}
        })
        const hard_intra_oral = hardIntraOral.map(item=>{
            const item_name = formData.get(item['name'])
            return {...item,value:item_name}
        })
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setApiData({...apiData,complaints, history_of_presenting_complaints, past_medical_history, investigation, treatment_plan})
        setExtraOral(extra_oral)
        setSoftIntraOral(soft_intra_oral)
        setHardIntraOral(hard_intra_oral)
    };

    const handleBack = () => {
        const formData = new FormData(formRef.current)
        const treatment_plan = formData.get(`treatment_plan`)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setApiData({...apiData, treatment_plan})

    };

    const {openBar, type, message} = snackbars;



    const examinationDetails = (
        <div className='row'>
            <div className="col-lg-6">
                <Form.Group>
                    <Label name='Presenting complaints(PC)' htmlFor="complaints"/>
                    <CustomResizableTextarea reset={isResolved} rows={2} placeholder="Write presenting complaints..." id='complaints' name='complaints' inputValue={apiData?.complaints}/>
                </Form.Group>
                <Form.Group>
                    <Label name='History of presenting complaints(HPC)' htmlFor="history_of_presenting_complaints"/>
                    <CustomResizableTextarea reset={isResolved} rows={2} placeholder="Write history of presenting complaints..." id='history_of_presenting_complaints'
                                             name='history_of_presenting_complaints' inputValue={apiData?.history_of_presenting_complaints}/>
                </Form.Group>
                <Form.Group>
                    <Label name='Past medical history(PMH)' htmlFor="past_medical_history"/>
                    <CustomResizableTextarea reset={isResolved} rows={2} placeholder="Write past medical history..." id='past_medical_history'
                                             name='past_medical_history' inputValue={apiData?.past_medical_history}/>
                </Form.Group>
                <Form.Group>
                    <SubHeading>Investigations</SubHeading>
                    <CustomCheckInput type='radio' name='investigation' label='OPG' inline value='opg' inputValue={apiData?.investigation} id='opg'/>
                    <CustomCheckInput type='radio' name='investigation' label='Periapical X-ray' inline value='periapical_xray' id='periapical_xray' inputValue={apiData?.investigation}/>
                </Form.Group>
                <Heading>Examination</Heading>
                <SubHeading>Extra oral</SubHeading>
                <div className='row '>
                    {extraOral.map((item, index)=>{
                        return(
                            <div className='col-lg-4 pl-0' key={index}>
                                <Form.Group as={Row}>
                                    <Col lg={3} xs={12} sm={12} md={12}>
                                        <Label name={item.label} htmlFor={item.name}></Label>
                                    </Col>
                                    <Col lg={9} xs={12} sm={12} md={12}>
                                        <CustomInput id={item.name} name={item.name} type='text' inputValue={item.value}/>
                                    </Col>

                                </Form.Group>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="col-lg-6">
                <SubHeading>Intra oral(soft tissue)</SubHeading>
                <div className='row'>
                    {softIntraOral.map((item, index)=>{
                        return(
                            <div className='col-lg-6 col-md-12 col-sm-12 pl-0' key={index}>
                                <Form.Group as={Row}>
                                    <Col lg={3} xs={12} sm={12} md={12}>
                                        <Label name={item.label} htmlFor={item.name}/>
                                    </Col>
                                    <Col lg={9} xs={12} sm={12} md={12}>
                                        <CustomInput reset={isResolved} name={item.name} id={item.name} type='text' inputValue={item.value}/>
                                    </Col>
                                </Form.Group>
                            </div>
                        )
                    })}
                </div>

                <SubHeading>Intra oral(hard tissue)</SubHeading>
                <div className='row'>
                    {hardIntraOral.map((item, index)=>{
                        return(
                            <div className='col-lg-6 col-md-12 col-sm-12 pl-0' key={index}>
                                <Form.Group as={Row}>
                                    <Col lg={3} xs={12} sm={12} md={12}>
                                        <Label name={item.label} htmlFor={item.name}/>
                                    </Col>
                                    <Col lg={9} xs={12} sm={12} md={12}>
                                        <CustomInput reset={isResolved} name={item.name} id={item.name} type='text' inputValue={item.value}/>
                                    </Col>

                                </Form.Group>
                            </div>
                        )
                    })}
                </div>
                <RightAlignedContainer>
                    <button type='button' onClick={handleNext} className='btn sms-info-btn btn-sm small-btn px-3'>Next</button>
                </RightAlignedContainer>
            </div>
        </div>
    )

    const stepper = (
        <Steppers {...{activeStep, steps}}/>
    )
    const {componentRef, handlePrint} = usePrint()

    const patientComponent = (
        <>
            {dental_exam_id ? <RightAlignedContainer>
                <button className='btn btn-sm small-btn sms-amber-btn' onClick={handlePrint}>Print</button>
            </RightAlignedContainer>: null}
            <DentalPatientDetails {...{patient_number}}/>
        </>

    )

    const isPending = isSubmitted === 'pending'

    return (
        <DentalDashboard {...{...props, title:"Dental Examination"}}>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            {activeStep === 0 ? patientComponent  :  <DentalContainer>{patientComponent}</DentalContainer>}
            <div ref={componentRef} css={[printDiv]}>
                <DentalExaminationReport {...{...apiData, ...printData,
                    medication:prescribedDrugs, diagnosis:addedDiseases, patient_number}}/>
            </div>
            <form ref={formRef} onSubmit={handleSubmit}>
                {activeStep === 0 ? stepper : <DentalContainer>{stepper}</DentalContainer>}
                {activeStep === 0 ? examinationDetails : null}
                {activeStep === 1 ?  <DentalContainer>
                    <DentalDiagnosisMedication {...{...prescriptionProps, treatment_plan: apiData?.treatment_plan, diagnosis: (<DentalDiagnosis {...diagnosisProps}/>)}}/>
                    <RightAlignedContainer>
                        <button type='button' onClick={handleBack} className='btn sms-gray-btn btn-sm small-btn px-3 mr-3'>Back</button>
                        <button type='submit' onClick={()=>{}} className='btn sms-info-btn btn-sm small-btn px-3'>{isPending ? 'Saving...':'Save'}</button>
                    </RightAlignedContainer>
                </DentalContainer>: null}
            </form>
        </DentalDashboard>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DentalExamination);
