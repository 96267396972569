/** @jsxImportSource @emotion/react */
import {jsx} from "@emotion/react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {textWeight} from "../../../styles/tableStyles";
import {titleCase} from "../../Users/addUser";
import {
    PreviousLabResults,
    PreviousPrescription,
    PreviousProcedure,
    PreviousRadiology,
    PreviousTriage,
    PreviousClinicalNotes,
    PreviousDiagnosis, ResultsHeading, Heading, Border, PatientAdmission,
} from "./PatientHistoryComponents";
import {PrintTemplate} from "../../Utils/Templates/PrintTemplate";
import {formatDateTime} from "../../Utils/ConvertDate";
import {Spacer} from "../ReusableTreatmentHistory";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {usePrint} from "../../Utils/Templates/usePrint";
import {SelectForm} from "../PatientPreviousVisits";
import {ExaminationDetails} from "../../Dental/DentalExaminationReport";
import {DentalServiceResults, heading} from "../../Dental/DentalServiceReport";

const optionFilter = (option = [], filter_type = '') => (
    option.length > 0 ? Boolean(option.filter(item => item.value === filter_type).map(({value}) => value).join('')) : false
)

const options = [{label: 'Triage', value: 'triage'}, {label: ' Clinical Notes', value: 'notes'},
    {label: 'Procedure', value: 'procedure'},
    {label: 'Laboratory Result(s)', value: 'lab'}, {label: 'Radiology Report(s)', value: 'radiology'},
    {label: 'Diagnosis', value: 'diagnosis'}, {label: 'Prescription', value: 'prescription'},
    {label: 'Dental', value: 'dental'}, {label: 'Admission', value: 'admission'}]

function PatientMedicalHistory({actions, visit, isDentalDepartment = false}) {

    console.log('dental', isDentalDepartment)

    const {visit_id, patient_number} = visit

    const [patient, setPatient] = useState({});
    const [treatmentDetails, setTreatmentDetails] = useState({
        labResults: [], radiologyResults: [], patientDiagnosis: [],
        medication: [], clinicalNotes: [], patientTriage: [], visit_date: '', visit_type: '', visit_id: '',
        examination: {}, dental_services: [], admission: []
    })


    const [visitDetails, setVisitDetails] = useState({})

    const [option, setOption] = useState([{label: 'Triage', value: 'triage'}, {
        label: ' Clinical Notes',
        value: 'notes'
    },
        {label: 'Diagnosis', value: 'diagnosis'}, {label: 'Prescription', value: 'prescription'}])


    useEffect(() => {
        if (!visit_id) {
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err => {
        })
    }, [visit_id]);


    useEffect(() => {
        if (!patient_number) {
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);

    useEffect(() => {
        if (!visit.visit_id) {
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit.visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const {lab_result, scan_result, diagnosis, prescription, other_medicine, exam_notes, triage} = details
            const presc = prescription ? prescription : [];
            const others = other_medicine ? other_medicine : [];
            const other_meds = others.filter(item => Boolean(item));
            const medicationArr = [...presc, ...other_meds];
            const labArr = lab_result?.map(item => ({...item, open: true}))
            const scanArr = scan_result?.map(item => ({...item, open: true}))
            const dental = details.dental_results ? details.dental_results : [];
            const dental_exam = details.dental_exam ? details.dental_exam : {}
            const procedure = details.procedure ? details.procedure : []
            const admission = details.admission ? details.admission : []
            setTreatmentDetails({
                ...treatmentDetails,
                labResults: labArr,
                radiologyResults: scanArr,
                patientDiagnosis: diagnosis,
                medication: medicationArr,
                patientTriage: triage,
                procedure,
                admission,
                clinicalNotes: exam_notes.map(note => ({
                    ...note, openNote: true
                })),
                dental_services: dental,
                examination: {...dental_exam},
                visit_date: details?.visit_detail?.begin_datetime,
                visit_type: details?.visit_detail?.patient_type,
                visit_id: details?.visit_detail?.visit_id

            })
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [visit.visit_id])


    const handleOpenNote = (visit_id, notes_id) => {
        const obj = {
            ...treatmentDetails, clinicalNotes: treatmentDetails?.clinicalNotes?.map((note, idx) => {
                if (note.examination_notes_id === notes_id) {
                    return {...note, openNote: !note.openNote}
                }
                return note
            })
        }
        setTreatmentDetails(obj)
    }

    const handleOpenResults = (visit_id, index, arr, item_name) => {
        const openedArr = arr.map((child, idx) => {
            if (idx === index) {
                return {...child, open: !child.open}
            } else {
                return child
            }
        })
        const obj = {...treatmentDetails, [item_name]: openedArr}
        setTreatmentDetails(obj)
    }


    const {
        labResults, radiologyResults, medication, procedure, visit_type, visit_date,
        patientTriage, patientDiagnosis, clinicalNotes, dental_services, examination,
        admission
    } = treatmentDetails


    const optionValue = option ? option : [];

    const isProcedure = optionFilter(optionValue, 'procedure')
    const isLab = optionFilter(optionValue, 'lab')
    const isRadiology = optionFilter(optionValue, 'radiology')
    const isTriage = optionFilter(optionValue, 'triage')
    const isNotes = optionFilter(optionValue, 'notes')
    const isDiagnosis = optionFilter(optionValue, 'diagnosis')
    const isPrescription = optionFilter(optionValue, 'prescription')
    const isDental = optionFilter(optionValue, 'dental')
    const isAdmission = optionFilter(optionValue, 'admission')


    const type = {
        1: 'OPD Visit',
        2: 'IPD Visit',
        3: 'Patient Emergency Visit'
    }

    const {componentRef, handlePrint, fontSize} = usePrint(
        "Medical History Report"
    );


    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient No.: </span> <span css={[textWeight]}>{patient?.patient_number}
                    </span></td>
            <td><span>Age:</span> <span css={[textWeight]}>{patient['Age']}</span></td>

        </tr>
        <tr>

            <td><span>Patient Name: </span> <span
                css={[textWeight]}>{`${patient.first_name ? patient.first_name : ''} ${patient.last_name ? patient.last_name : ''}`}</span>
            </td>
            <td><span>Phone No.:</span> <span css={[textWeight]}>{patient?.phone_no}</span></td>
        </tr>
        <tr>
            <td><span>Gender: </span> <span
                css={[textWeight]}>{titleCase(!patient['gender'] ? "" : patient['gender'])}</span></td>
            <td><span>Address:</span> <span
                css={[textWeight]}>{titleCase(!patient['address'] ? "" : patient['address'])}</span></td>
        </tr>
        <tr>
            <td><span>Payment Mode: </span> <span
                css={[textWeight]}>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ? 'CASH' : ''}</span>
            </td>
            {visitDetails.is_on_insurance === 1 ?
                <td><span>Provider Name: </span> <span css={[textWeight]}>{visitDetails?.insurance_provider_name}</span>
                </td> : <td/>}
        </tr>
        <tr>
            {visitDetails.is_on_insurance === 1 ?
                <td><span>Account Name: </span> <span css={[textWeight]}>{visitDetails?.account_name}</span></td> :
                <td/>}
            <td/>
        </tr>
        </tbody>
    )


    const itemDetails = (
        <>
            {isTriage && patientTriage.length > 0 &&
                <Spacer>
                    <ResultsHeading>
                        <Heading>Triage</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousTriage {...{patientTriage}}/>
                </Spacer>

            }
            {isNotes && clinicalNotes.length > 0 && <Spacer>
                <ResultsHeading>
                    <Heading>Clinical Notes</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousClinicalNotes {...{clinicalNotes, visit_id, handleOpen: handleOpenNote}}/>
            </Spacer>}
            {isProcedure ? <PreviousProcedure procedure={procedure}/> : null}
            {isDiagnosis && <PreviousDiagnosis {...{patientDiagnosis}}/>}
            {isLab && labResults?.length > 0 ?
                <Spacer>
                    <PreviousLabResults {...{labResults}}/>
                </Spacer> : null}
            {isRadiology && radiologyResults?.length > 0 ?
                <Spacer>
                    <PreviousRadiology {...{radiologyResults, handleOpenResults, visit_id}}/>
                </Spacer>
                : null}
            {isPrescription && medication?.length > 0 ?
                <Spacer>
                    <PreviousPrescription {...{medication}} />
                </Spacer> : null}
            {isDental && (dental_services.length > 0 || Object.keys(examination).length > 0) ?
                (
                    <Spacer>
                        <ResultsHeading>
                            <Heading>Dental</Heading>
                            <Border/>
                        </ResultsHeading>
                        <ExaminationDetails {...{patient_number, ...examination}}>
                            <DentalServiceResults {...{
                                dental_services,
                                title: dental_services.length > 0 ? (
                                    <thead>
                                    <tr>
                                        <td><span css={[heading, textWeight]}>Services performed</span></td>
                                    </tr>
                                    </thead>
                                ) : ''
                            }}/>
                        </ExaminationDetails>
                    </Spacer>

                ) :
                null}
            {isAdmission && admission?.length > 0 ?
                <Spacer>
                    <PatientAdmission {...{admission}} />
                </Spacer> : null}
        </>
    )

    const dentalItemDetails = (
        <ExaminationDetails {...{patient_number, ...examination}}>
            <DentalServiceResults {...{
                dental_services,
                title: dental_services.length > 0 ? (
                    <thead>
                    <tr>
                        <td><span css={[heading, textWeight]}>Services performed</span></td>
                    </tr>
                    </thead>
                ) : ''
            }}/>
        </ExaminationDetails>
    )

    return (
        <div>
            {!isDentalDepartment &&
                <SelectForm {...{options, option, handleChangeOption: (value) => setOption(value), handlePrint}} />}
            <div ref={componentRef}>
                {fontSize}
                <PrintTemplate title={`${type[visit_type]}–${visit_date ? formatDateTime(visit_date) : ''}`} {...{
                    patientDetails,
                    itemDetails: isDentalDepartment ? dentalItemDetails : itemDetails
                }}/>
            </div>
        </div>

    )
}

export {PatientMedicalHistory}