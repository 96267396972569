import React from "react";
import {BorderlessTextInput} from "./MainReferralNote";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {useInternalReferral} from "./hooks/useInternalReferral";
import * as departmentActions from "../../../actions/DoctorsActions/departmentActions";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import Row from "react-bootstrap/Row";
import TextField from "../../Utils/FormInputs/TextField";

const OutGoingReferralNote = (props) => {

    const {openBar, type, message} = props.snackbars;
    const {patient_name,  patient_number, visit_id} = props;
    const {
        state, submitted, isSubmitted, handleChange, companyName,
        handleSubmit, handleCloseReferral, handleClick, openPrint,
        handleReset, handleCloseSnackbar
    } = useInternalReferral(props, 'outgoing', {patient_number, visit_id})
    const {referral_date,  to_hospital, visit_date, patient_history, diagnosis, treatment} = state;
    return (
        <div className='p-3'>
            <form id="save_form" onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                    <PrintDialog message="View Referral Note" openDialog={openPrint} handleClose={handleCloseReferral}
                                 text="Would you like to view and print this referral note" handleClick={handleClick}
                                 handleCancel={handleCloseReferral}/>
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseSnackbar}/>
                    <div className="col-md-7 mt-4  ">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Date of Referral" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField submitted={submitted} value={referral_date}
                                           name="referral_date"  type="date"
                                           disabled={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="To" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput onChange={handleChange} value={to_hospital}
                                                     name="to_hospital" type="text"
                                                     />
                            </Col>
                        </Form.Group>
                        <p className="referral-paragraph">FROM: <strong>{companyName}</strong></p>
                        <p className="referral-paragraph"><strong>REFERENCE:</strong></p>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Patient Number" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput value={patient_number}  name="patient_number" type="text"
                                                     disabled={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Patient Name" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput value={patient_name}
                                                     type="text"
                                                     disabled={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Date of first visit" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput onChange={handleChange} value={visit_date}  name="visit_date" type="date"/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-5 mt-3">
                        <Form.Group as={Col}>
                            <Label name="Reason"/>
                            <ResizableTextarea value={patient_history} onChange={handleChange} name="patient_history" />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label name="Treatment Given"/>
                            <ResizableTextarea value={treatment} onChange={handleChange} name="treatment" />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label name="Diagnosis"/>
                            <ResizableTextarea value={diagnosis} onChange={handleChange} name="diagnosis"/>
                        </Form.Group>
                    </div>
                </div>
                <div className="text-right mr-5">
                    <button type="button" className="btn btn-sm sms-grey-btn px-4 mr-2" onClick={handleReset}
                            id="reset_form">Reset
                    </button>
                    <button type="submit" className="btn btn-sm sms-btn px-4"
                            disabled={!!isSubmitted}>{isSubmitted ? "Saving..." : "Save"}</button>
                </div>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departments: state.departments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutGoingReferralNote);
