/** @jsxImportSource @emotion/react */
import React from "react";
import {HospitalAddress} from "../HospitalAddress";
import {
    table,
    textWeight,
    borderedCell,
    smallTable,
    tableMargin,
    detailsTable,
    borderlessTableFooter,
    thUppercase,
    coloredHead,
    coloredThead,
    heading,
    paddingCell,
    tableBordered
} from '../../../styles/tableStyles'
import {formatDateTime, formatLocalTime} from "../ConvertDate";

const PrintTemplate = ({title,patientDetails,patientNotes, guardianDetails,itemDetails, footer, dischargeDetails,tableHeading=table,
    admissionNotes=null,prescriptionDetails=null, bioBordered=borderedCell, bioMargin=tableMargin}) => {
    const date = new Date()
    const date_printed = formatLocalTime(date)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const printed_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    return (
        <div className='table-responsive print-document'>
            {/*<table css={[tableHeading]}>*/}

            <HospitalAddress/>
            <div className="page-header-space"></div>

            <table css={[tableHeading, smallTable]}>
                <tbody>
                <tr>
                    <td css={[textWeight, coloredHead, thUppercase]} align="center">
                        <p css={[heading]}>
                            <strong>{title}</strong>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table
                css={[
                    tableHeading,
                    detailsTable,
                    bioBordered,
                    smallTable,
                    paddingCell,
                    bioMargin,
                ]}
            >
                {patientDetails}
            </table>
            {guardianDetails}
            {dischargeDetails}
            {admissionNotes}
            {itemDetails}
            {patientNotes}
            {prescriptionDetails}
            <table >
                <tfoot  className="mpeke-footer">
                <tr>
                    <td className="mpeke-footer-text">
                        Printed by: <strong>{printed_by}</strong> at <strong>{date_printed}</strong>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    );
};

function ItemsTable({
                        children, headData=[], parentHeadData= null,zeroMargin="",capitalize="",
                        coloredTableHead= coloredThead,testId="", tableHeading=table}) {
    return(
        <table css={[tableHeading,detailsTable, tableMargin, tableBordered,zeroMargin]} data-testid={testId}>
        <thead>
            {parentHeadData}
            <tr>
            {headData.map((item, index)=>(
                <th css={[textWeight, capitalize]} key={index} className={`table-items ${item?.isPrint ? 'print_button' : ''}`}><span><strong>{item.value}</strong></span></th>
            ))}
            </tr>
        </thead>
        {children}
    </table>
    )
}

export {PrintTemplate,ItemsTable}

