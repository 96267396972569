import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {CollapsibleList, DataCell, ItemText, ModuleTable} from "../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import {history} from "../../Helpers/history";
import {LoadingGif} from "../Utils/Loader";
import {CenteredContainer} from "../Utils/styledComponents";
import {PendingStatus, FinishedStatus, OtherMedicine, StyledIcon, StyledLibraryIcon, P, Container, Processing, ExpiredStatus} from "../DoctorsModule/doctorStyles"
import {NewDentalPrescription} from "./NewDentalPrescription";
import DentalDashboard from "./DentalDashboard";
import DentalPatientDetails from "./DentalPatientDetails";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {formatDateTime} from "../Utils/ConvertDate";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import DentalPrescriptionReport from "./DentalPrescriptionReport";


const headData = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true}, {item:'Quantity Prescribed',  toolTip:'Enter quantity to prescribed'},  {item:'Dosage',  toolTip:'Enter dosage'},
    {item:'Frequency',  toolTip:'Enter frequency'},{item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'},{item:'Status',  toolTip:'status'}];

const sundriesHeadData = [{item:'Sundry Name',}, {item: 'status'}]

const DentalPrescription = ({actions, snackbars, match:{params}}) => {
    const {patient_number, visit_id} = params;
    const [allPrescriptions, setAllPrescriptions] = useState([])
    const [status, setStatus] = useState('idle')
    const [openDialog, setOpenDialog] = useState(false)
    const [prescriptionDetails, setPrescriptionDetails] = useState({})
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [openReport, setOpenReport] = useState(false)
    const [prescriptionId, setPrescriptionId] = useState(null)
    const [previousPrescription, setPreviousPrescription] = useState([])

    const user = JSON.parse(sessionStorage.getItem("user"));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];

    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

    const isResolved = isSubmitted === 'resolved'
    const groupByPrescriptionId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.prescription_id;
            if(id in knowledge){
                result[knowledge[id]].drugs.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    prescribed_by: item.prescribed_by,
                    date: formatDateTime(item.date_created),
                    doctor_name:`${item.prescribed_by_first_name ? item.prescribed_by_first_name:''} ${item.prescribed_by_last_name ? item.prescribed_by_last_name : ''}`,
                    dispensed_by: `${!item.dispensed_by_frist_name ? "" : item.dispensed_by_frist_name} ${!item.dispensed_by_last_name ? "" : item.dispensed_by_last_name}`,
                    dispensed_at: item.dispensed_at ? formatDateTime(item.dispensed_at) : '',
                    drugs: [item],
                })

            }
        })
        return result
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_prescription_by_visit_id`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const prescrip = data.prescription ? data.prescription : [];
            const other_meds = data.other_med ? data.other_med : [];
            const others = other_meds.map(item=>({...item, product_name:item.other_medicine}))
            const drugs = [...prescrip, ...others]
            const presc = groupByPrescriptionId(drugs)
            const arr = presc.map(item=>({...item, open:true}))
            setAllPrescriptions(arr)
            setPreviousPrescription(prescrip)
            setStatus('success')
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, [isResolved])

    const handleOpen = (id) =>{
        const itemsArr = allPrescriptions.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setAllPrescriptions(itemsArr)
    }

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    const {openBar, type, message} = snackbars;

    const viewReport = (presc_id) =>{
        history.push(`/prescriptiondetails/${patient_number}/${presc_id}`)
    }


    return (
        <DentalDashboard {...{title:'Prescription',match:{params}}}>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <CustomDialog open={openReport} handleClose={()=>{
                setOpenReport(false)
            }} title='Prescription Report' maxWidth='md'>
                <DentalPrescriptionReport {...{actions, match:{visit_id, prescription_id:prescriptionId}}}/>
            </CustomDialog>
            <DentalPatientDetails {...{patient_number}}/>
            <CustomDialog open={openDialog} handleClose={()=>{
                setOpenDialog(false)
            }} title={prescriptionId ? 'Edit Prescription':'Add Prescription'} maxWidth='md'>
            <NewDentalPrescription {...{actions, match:params,openDialog, setOpenDialog,isSubmitted, setIsSubmitted,
                prescriptionDetails, prescription_id:prescriptionId, previousPrescription}}/>
            </CustomDialog>
            <button onClick={()=>{
                setOpenDialog(true)
                setPrescriptionId(null)
            }} type='button' className='btn btn-sm sms-info-btn mb-2'>Add Prescription</button>
            {isLoading ? <CenteredContainer>
                <LoadingGif/>
            </CenteredContainer> : null}
            {isSuccess ? allPrescriptions.length > 0 ? allPrescriptions.map((item)=>{
                const isDoctor = item.prescribed_by === user_roles_id
                const isAdded = item.drugs
                    .filter((item) => item.visit_id)
                    .some((item) => (item.status === 1 && item.pay_status === 3) /* insurance */|| (item.status === 0 && item.pay_status === 0)) /*cash*/;
                const sundry = item.drugs.filter(item => item.category_name === 'Sundries')
                const presc = item.drugs.filter(item => (item.category_name === 'Medicine')||(!item.category_name))

                const user = (
                    <>
                        <ItemText  primary={<span>Prescribed By: <strong>{item.doctor_name}</strong></span>} />
                        {item.dispensed_by ? <ItemText  primary={<span>Dispensed By: <strong>{item.dispensed_by}</strong></span>} />:null}
                        {item.dispensed_at ? <ItemText  primary={<span>Dispensed At: <strong>{item.dispensed_at}</strong></span>} /> :  null}
                    </>
                )

                return(
                    <CollapsibleList key={item.id} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={
                        <>
                            {isAdded && isDoctor ? <Tooltip title='Edit Prescription'>
                                <IconButton onClick={()=> {
                                   setOpenDialog(true)
                                    setPrescriptionDetails(item)
                                    setPrescriptionId(item.id)
                                }}>
                                    <StyledIcon/>
                                </IconButton>
                            </Tooltip> : null}
                            <Tooltip title='Prescription Report'>
                                <IconButton onClick={()=>{
                                    setOpenReport(true)
                                    setPrescriptionId(item.id)
                                }}>
                                    <StyledLibraryIcon/>
                                </IconButton>
                            </Tooltip>
                        </>
                    } user={user}>
                        {presc.length > 0 ?
                            <ModuleTable headData={headData}>
                                {presc.map((row, index) => {
                                    const isOther = row.status === null || row.status === undefined;

                                    const outPatientStatus = {
                                        0:<PendingStatus>Pending</PendingStatus>,
                                        1:<Processing>Paid</Processing>,
                                        2:<FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                                        3:<PendingStatus>Pending</PendingStatus>,
                                        4:<FinishedStatus>Dispensed</FinishedStatus>,
                                        7:<ExpiredStatus>Stopped</ExpiredStatus>
                                    }

                                    const inPatientStatus = {
                                        0:<PendingStatus>Pending</PendingStatus>,
                                        1:<Processing>Pending</Processing>,
                                        3:<PendingStatus>Pending</PendingStatus>,
                                        2:<FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                                        4:<FinishedStatus>Dispensed</FinishedStatus>,
                                        7:<ExpiredStatus>Stopped</ExpiredStatus>
                                    }

                                    const statusItem =  outPatientStatus[row.status]
                                    return(
                                        <TableRow
                                            key={index}
                                        >
                                            <DataCell>
                                                <span>{row.product_name}{isOther ? <OtherMedicine>Other</OtherMedicine> : null}</span>
                                            </DataCell>
                                            <DataCell>{row.quantity_prescribed}</DataCell>
                                            <DataCell>{row.dosage}</DataCell>
                                            <DataCell>{row.frequency}</DataCell>
                                            <DataCell>{row.number_of_days}</DataCell>
                                            <DataCell>{row.key_information}</DataCell>
                                            <DataCell>{statusItem}</DataCell>
                                        </TableRow>

                                    )})}

                            </ModuleTable> : null }
                        {sundry.length > 0 ?
                            <ModuleTable headData={sundriesHeadData}>
                                {sundry.map((row, index) => {
                                    const isPending = row.status === 0;
                                    const isFinished = row.status === 1;
                                    const isOther = row.status === null || row.status === undefined;
                                    return(
                                        <TableRow
                                            key={index}
                                        >
                                            <DataCell>
                                                <span>{row.product_name}{isOther ? <OtherMedicine>Other</OtherMedicine> : null}</span>
                                            </DataCell>
                                            <DataCell>{isPending ? <PendingStatus>Pending</PendingStatus>:isFinished ? <FinishedStatus>Dispensed</FinishedStatus>: null}</DataCell>
                                        </TableRow>
                                    )})}
                            </ModuleTable>: null }
                    </CollapsibleList>
                )
            }) : <CenteredContainer>
                <P>No drugs prescribed for patient, click the add prescription button to prescribe drugs for patient</P>
            </CenteredContainer>:null}
            {isError ? <CenteredContainer>
                <P>An error occurred on the server</P>
            </CenteredContainer>:null}
        </DentalDashboard>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DentalPrescription);