import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container } from "../../Utils/styledComponents";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import { history } from "../../../Helpers/history";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { useItemsProviderAllows } from "./hooks/useItemsProviderAllows";
import { AddItem, EditItem } from "./EditItem";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {formatDate} from "../../Utils/ConvertDate";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date_created', numeric: false, disablePadding: false, label: 'Date created'},
    {id: 'item_name', numeric: false, disablePadding: false, label: 'Item Name'},
    {id: 'item_type', numeric: false, disablePadding: false, label: 'Item Type'},
    {id: 'provider_cost', numeric: false, disablePadding: false, label: 'Provider Cost'},
    {id: 'currency', numeric: false, disablePadding: false, label: 'Currency'},
    {id: 'qty_billable', numeric: false, disablePadding: false, label: 'Qty Billable'},
    {id: 'pack_unit', numeric: false, disablePadding: false, label: 'Pack Unit'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
];

const ItemsProviderAllow = ({actions, snackbars, match}) => {
    const {provider_id, provider_name, item_status, currency_id} = match.params;
    const {openBar, type, message} = snackbars;

    const id = currency_id.split('-')[0]
    const currency_symbol = currency_id.split('-')[1]

    const {
        handleDeactivate, handleCloseDeactivate, openDeactivate, handleOpenDeactivate,
        handleCloseEditItem, openEditItem, handleOpenEditItem,
        openNewItems, newItemProps, handleCloseNewItems, handleOpenNewItems,
        provider_items,
        current_item, handleItemUpdate, handleChangeItem,
        closeSnackbar, handleChangeRowsPerPage, handleChangePage, handleSearch,
        searchValue, page, rowsPerPage, isLoading, isSuccess, isError, uploadProps, exportItems
    } = useItemsProviderAllows(actions, provider_id, item_status, id);


 

    const components = [
        {label: 'Active Items', path: `/items_provider_allows/${provider_id}/${provider_name}/${1}/${currency_id}`},
        {label: 'In-Active Items', path: `/items_provider_allows/${provider_id}/${provider_name}/${0}/${currency_id}`},
    ]

    const excelObj = {file_name:'Item List', url:'cbilling/provider_items_excel', params:{ provider_id: provider_id, item_status: item_status}}
    const pdfObj = {file_name:'Item List',url:'cbilling/provider_items_pdf', params:{ provider_id: provider_id, item_status: item_status}}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions, excelObj)

    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions, pdfObj)

    return (
        <div className='journals'>
            <PageTitle title="Invoice Providers"/>
            <SubHeader title="Billing" subTitle={`Items Allowed for Provider: ${provider_name}`}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>


            {/*  Add New Item dialog box */}
            <CustomDialog open={openNewItems} handleClose={handleCloseNewItems} title="New Items" maxWidth="lg" isContentOverflow={false} isPaperStyle={true}>
                <AddItem {...{...newItemProps, currency_symbol}}/>
            </CustomDialog>

            {/*  Edit Item dialog box */}
            <CustomDialog open={openEditItem} handleClose={handleCloseEditItem} title="Edit Item" maxWidth="lg" isContentOverflow={false} isPaperStyle={true}>
                <EditItem current_item={current_item} handleItemUpdate={handleItemUpdate} handleChangeItem={handleChangeItem} uploadProps={uploadProps}/>
            </CustomDialog>

            {/*  Delete dialog box */}
            <DeleteDialog message={current_item.status === 0 ? `activate` : `delete`} openDialog={openDeactivate} handleClose={handleCloseDeactivate}
            text={"item"} title={current_item.status === 0 ? `Activate Item for Provider` : `Deactivate Item for Provider`}>
                <button className='btn sms-btn-dismiss btn-sm' onClick={handleDeactivate}>{current_item.status === 0 ? `Activate` : `Deactivate`}</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDeactivate}>No</button>    
            </DeleteDialog>

            {/*<CsvDialog {...{...uploadProps, title:'Upload Items'}}/>*/}

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{paddingLeft: '44.46px', paddingTop:'15px'}}>
                    <BackButton to={"/providers"} text={'Providers'}/>
                </div>
                <div className="text-right flex"  style={{padding: '10px 44.46px'}}>

                    <button onClick={exportExcel} disabled={isLoadingExcel}  className='btn sms-gray-btn mr-3' >{isLoadingExcel ? 'Exporting...' :'Excel'}</button>
                    <button onClick={exportPdf} disabled={isLoadingPdf}  className='btn sms-gray-btn mr-3' >{isLoadingPdf ? 'Exporting...':'PDF'}</button>
                    <button onClick={handleOpenNewItems} className="btn sms-btn mr-4"><FontAwesomeIcon icon={faPlus}/> Add New Items</button>
                    {/*<button onClick={handleOpenCsvDialog} id="upload-services" className="btn sms-info-btn btn-sm">Upload Items*/}
                    {/*</button>*/}
                </div>
            </div>

            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable handleChangePage={handleChangePage}
                             handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                             data={provider_items} colSpan={headData.length}
                             id="searchValue" term={searchValue}>
                    <TableBody>
                        {isLoading ?
                        <TableRow>
                            <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                alt="loader"/></TableCell>
                        </TableRow>:null}
                        {isSuccess ?
                            (provider_items.length && provider_items.length > 0) ?
                            provider_items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                                const cnt = (page * rowsPerPage) + index + 1;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'
                                        id="services"
                                    >
                                        <TableCell>{cnt}</TableCell>
                                        <TableCell>{item.date_created ? formatDate(item.date_created) : null}</TableCell>
                                        <TableCell>{item.item_name}</TableCell>
                                        <TableCell>{item.item_type}</TableCell>
                                        <TableCell>{item.cost}</TableCell>
                                        <TableCell>{item.currency_symbol}</TableCell>
                                        <TableCell>{item.quantity_billable}</TableCell>
                                        <TableCell>{item.pack_unit}</TableCell>
                                        <TableCell>
                                            {item_status === '1' ?
                                                <button className="btn btn-sm sms-info-btn" onClick={()=>handleOpenEditItem(index, item)}
                                                style={{marginRight: '10px', marginBottom: '5px'}}>Edit</button> : null}
                                            <button className="btn btn-sm sms-btn-dismiss" onClick={()=>handleOpenDeactivate(index, item)}
                                                style={{marginRight: '10px', marginBottom: '5px'}}>{item_status === '1' ? 'Deactivate' : 'Activate'}</button>
                                        </TableCell>
                                    </TableRow>
                                );
                            }):
                            <TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                        {isError ? <TableRow>
                            <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow>:null}
                    </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsProviderAllow);
