import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { faUserMd } from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {
  doctorRoutes,
} from "../../Utils/Menu/ReusableDoctorTabs";
import { PatientInformation } from "../PatientInfo/PatientInformation";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {
  CollapsibleList,
  DataCell,
  ItemText,
  ModuleTable,
} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { history } from "../../../Helpers/history";
import { usePreviousPrescription } from "./hooks/usePreviousPrescription";
import { NewPrescriptionTable, StyledContainer } from "./NewPrescription";
import { LoadingGif } from "../../Utils/Loader";
import { CenteredContainer } from "../../Utils/styledComponents";
import {
  PendingStatus,
  FinishedStatus,
  OtherMedicine,
  StyledIcon,
  StyledLibraryIcon,
  P,
  Container,
  Processing,
  ExpiredStatus,
} from "../doctorStyles";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import { ConfirmationDialog } from "../../Utils/ReusableComponents/ConfirmationDialog";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import { FormDialog } from "../../Utils/Dialogs/FormDialog";
import {useVersionsContext} from "../../../Context/versions-context";


const sundriesHeadData = [{ item: "Sundry Name" }, { item: "status" }];

const PrescriptionTab = ({ actions, snackbars, match: { params } }) => {
  const {subscription} = useVersionsContext()
  const version = subscription?.version
  const isPremium = version === 'Premium'

  const {
    patient_number,
    visit_id,
    patient_type,
    patient_admission_id,
  } = params;



  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const arr = doctorRoutes(
    patient_number,
    visit_id,
    patient_type,
    patient_admission_id
  );
  const user = JSON.parse(sessionStorage.getItem("user"));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${
    !_token.last_name ? "" : _token.last_name
  }`;

  const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

  const { openBar, type, message } = snackbars;
  const {
    allPrescriptions,
    handleOpen,
    addProps,
    isSuccess,
    isLoading,
    isError,
    editProps,
    handleStopPresc,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    confirmDialog,
  } = usePreviousPrescription(actions, params, _token);

  const viewReport = (presc_id) => {
    history.push(
      `/prescriptiondetails/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}/${presc_id}`
    );
  };

  const {
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    isPending,
    handleSubmit,
    sundryProps,
    openConfirmation,
    addItemsToReceipt,
    isBlocking,
    isBlockDialog,
    setIsBlockDialog,
    handleResetForm,
    handleCloseConfirmation,
    handleCancelConfirmation,
  } = addProps;
  const {
    openSundries,
    addSundriesToReceipt,
    handleCancelSundries,
    handleCloseSundries,
  } = sundryProps;
  const {
    openEdit,
    handleCloseEdit,
    handleOpenEdit,
    isPending: isPendingEdit,
    handleSubmit: handleSubmitEdit,
    sundryProps: editSundryProps,
    isOutPatient,
    openConfirmation: openEditConfirm,
    addItemsToReceipt: addEditReceiptItems,
    handleCloseConfirmation: handleCloseEditConfirmation,
    handleCancelConfirmation: handleCancelEditConfirmation,
  } = editProps;


  const headData = [
    { item: "Drug Name", toolTip: "Enter drug name", isRequired: true },
    isPremium ? { item: "Quantity to Bill", toolTip: "Enter quantity to bill" }: null,
    { item: "Quantity Prescribed", toolTip: "Enter quantity to prescribed" },
    { item: "Dosage", toolTip: "Enter dosage" },
    { item: "Frequency", toolTip: "Enter frequency" },
    { item: "No. of Days", toolTip: "Enter number of days" },
    { item: "Instructions", toolTip: "Enter instructions" },
    { item: "Status", toolTip: "status" },
    { item: "Action", toolTip: "action" },
  ];

  return (
    <div className="journals">
      <PageTitle title="Prescription" />
      <SubHeader
        title="Doctor"
        subTitle="Prescription"
        doctor_name={doctor_name}
      >
        <FontAwesomeIcon icon={faUserMd} />
      </SubHeader>
      <MainSnackbar
        open={openBar}
        handleCloseBar={closeSnackbar}
        variant={type}
        message={message}
      />
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
        dialogClose={isBlockDialog}
        setIsBlockDialog={setIsBlockDialog}
        clearForm={handleResetForm}
      />
      <FormDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        title="Add Prescription"
        maxWidth="lg"
        buttons={
          <StyledContainer>
            <button
              type="submit"
              className="btn sms-btn btn-sm"
              disabled={isPending}
            >
              {isPending ? "Saving..." : "Save Prescription"}
            </button>
          </StyledContainer>
        }
        handleSubmit={handleSubmit}
      >
        <NewPrescriptionTable sundryProps={sundryProps} {...addProps} />
      </FormDialog>

      <ConfirmationDialog
        open={openConfirmation}
        handleClose={handleCancelConfirmation}
        title="Make cash payment"
        confirmItems={addItemsToReceipt}
        removeItems={handleCloseConfirmation}
        service="product"
      />

      <ConfirmationDialog
        open={openSundries}
        handleClose={handleCancelSundries}
        title="Make cash payment"
        confirmItems={addSundriesToReceipt}
        removeItems={handleCloseSundries}
        service="sundry"
      />

      <ConfirmationDialog
        open={openEditConfirm}
        handleClose={handleCancelEditConfirmation}
        title="Make cash payment"
        confirmItems={addEditReceiptItems}
        removeItems={handleCloseEditConfirmation}
        service="product"
      />

      <FormDialog
        open={openEdit}
        handleClose={handleCloseEdit}
        title="Edit Prescription"
        maxWidth="lg"
        buttons={
          <StyledContainer>
            <button
              type="submit"
              className="btn sms-btn btn-sm"
              disabled={isPendingEdit}
            >
              {isPendingEdit ? "Saving..." : "Save Prescription"}
            </button>
          </StyledContainer>
        }
        handleSubmit={handleSubmitEdit}
      >
        <NewPrescriptionTable
          {...{
            ...editProps,
            isEdit: true,
            sundryProps: editSundryProps,
            isOutPatient,
          }}
        />
      </FormDialog>

      <DeleteDialog
        openDialog={confirmDialog}
        handleClose={handleCloseConfirmDialog}
        title="Stop Prescription"
        message="stop"
        text="prescription"
      >
        <button className="btn sms-info-btn btn-sm" onClick={handleStopPresc}>
          Yes
        </button>
        <button
          className="btn sms-grey-btn btn-sm"
          onClick={handleCloseConfirmDialog}
        >
          No
        </button>
      </DeleteDialog>

      <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
        <PatientInformation {...{ patient_number, visit_id }} />
        <Container>
          <button
            onClick={handleOpenDialog}
            type="button"
            className="btn btn-sm sms-info-btn mb-2"
          >
            Add Prescription
          </button>
          {isLoading ? (
            <CenteredContainer>
              <LoadingGif />
            </CenteredContainer>
          ) : null}
          {isSuccess ? (
            allPrescriptions.length > 0 ? (
              allPrescriptions.map((item) => {
                const isDoctor = item.prescribed_by === user_roles_id
                const isAddedOut = item.drugs
                  .filter((item) => item.visit_id)
                  .some((item) => (item.status === 1 && item.pay_status === 3) /* insurance */|| (item.status === 0 && item.pay_status === 0)) /*cash*/;
                const isAddedIn = item.drugs
                  .filter((item) => item.visit_id)
                  .some(
                    (item) => (item.status !== 2 || item.status !== 4) //TODO: fix showing edit
                  );
                const isAdded = patient_type === "1" ? isAddedOut : isAddedIn;
                const sundry = item.drugs.filter(
                  (item) => item.category_name === "Sundries"
                );
                const presc = item.drugs.filter(
                  (item) =>
                    item.category_name === "Medicine" || !item.category_name
                );

                const user = (
                  <>
                    <ItemText
                      primary={
                        <span>
                          Prescribed By: <strong>{item.doctor_name}</strong>
                        </span>
                      }
                    />
                    {item.dispensed_by ? (
                      <ItemText
                        primary={
                          <span>
                            Dispensed By: <strong>{item.dispensed_by}</strong>
                          </span>
                        }
                      />
                    ) : null}
                    {item.dispensed_at ? (
                      <ItemText
                        primary={
                          <span>
                            Dispensed At: <strong>{item.dispensed_at}</strong>
                          </span>
                        }
                      />
                    ) : null}
                  </>
                );

                return (
                  <CollapsibleList
                    key={item.id}
                    open={item.open}
                    name={item.date}
                    handleOpen={() => handleOpen(item.id)}
                    button={
                      <>
                        {isAdded && isDoctor ? (
                          <Tooltip title="Edit Prescription">
                            <IconButton onClick={() => handleOpenEdit(item)}>
                              <StyledIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        <Tooltip title="Prescription Report">
                          <IconButton onClick={() => viewReport(item.id)}>
                            <StyledLibraryIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    user={user}
                  >
                    {presc.length > 0 ? (
                      <ModuleTable headData={headData.filter(item=>Boolean(item))}>
                        {presc.map((row, index) => {
                          const isStopped = row.status === 4;
                          const isOther =
                            row.status === null || row.status === undefined;

                          const outPatientStatus = {
                            0: <PendingStatus>Pending</PendingStatus>,
                            1: <Processing>Paid</Processing>,
                            2: (
                              <FinishedStatus>
                                Dispensed(out-patient)
                              </FinishedStatus>
                            ),
                            3: <PendingStatus>Pending</PendingStatus>,
                            4: <FinishedStatus>Dispensed</FinishedStatus>,
                            7: <ExpiredStatus>Stopped</ExpiredStatus>,
                          };

                          const inPatientStatus = {
                            0: <PendingStatus>Pending</PendingStatus>,
                            1: <Processing>Pending</Processing>,
                            3: <PendingStatus>Pending</PendingStatus>,
                            2: (
                              <FinishedStatus>
                                Dispensed(out-patient)
                              </FinishedStatus>
                            ),
                            4: <FinishedStatus>Dispensed</FinishedStatus>,
                            7: <ExpiredStatus>Stopped</ExpiredStatus>,
                          };

                          const statusItem =
                            patient_type === "1"
                              ? outPatientStatus[row.status]
                              : inPatientStatus[row.status];
                          return (
                            <TableRow key={index}>
                              <DataCell>
                                <span>
                                  {row.product_name}
                                  {isOther ? (
                                    <OtherMedicine>Other</OtherMedicine>
                                  ) : null}
                                </span>
                              </DataCell>
                              {isPremium ? (
                                <DataCell>{row.quantity ? row.quantity : 0}</DataCell>
                              ) : null}
                              <DataCell>{row.quantity_prescribed}</DataCell>
                              <DataCell>{row.dosage}</DataCell>
                              <DataCell>{row.frequency}</DataCell>
                              <DataCell>{row.number_of_days}</DataCell>
                              <DataCell>{row.key_information}</DataCell>
                              <DataCell>{statusItem}</DataCell>
                              <DataCell>
                                {isStopped &&
                                !isOther &&
                                patient_type === "2" ? (
                                  <button
                                    className="btn btn-warning"
                                    onClick={() =>
                                      handleOpenConfirmDialog(
                                        row.prescription_id,
                                        row.prescription_detial_id
                                      )
                                    }
                                  >
                                    Stop
                                  </button>
                                ) : null}
                              </DataCell>
                            </TableRow>
                          );
                        })}
                      </ModuleTable>
                    ) : null}
                    {sundry.length > 0 ? (
                      <ModuleTable headData={sundriesHeadData}>
                        {sundry.map((row, index) => {
                          const isInPatientPending = row.status === 3;
                          const isOutPatientPending = row.status === 0;
                          const isOutPatientFinished = row.status === 1;
                          const isInPatientFinished = row.status === 4;
                          const isStopped = row.status === 7;
                          const isPending =
                            patient_type === "1"
                              ? isOutPatientPending
                              : isInPatientPending;
                          const isFinished =
                            patient_type === "1"
                              ? isOutPatientFinished
                              : isInPatientFinished;
                          const isOther =
                            row.status === null || row.status === undefined;
                          return (
                            <TableRow key={index}>
                              <DataCell>
                                <span>
                                  {row.product_name}
                                  {isOther ? (
                                    <OtherMedicine>Other</OtherMedicine>
                                  ) : null}
                                </span>
                              </DataCell>
                              <DataCell>
                                {isPending ? (
                                  <PendingStatus>Pending</PendingStatus>
                                ) : isFinished ? (
                                  <FinishedStatus>Dispensed</FinishedStatus>
                                ) : isStopped ? (
                                  <PendingStatus>Stopped</PendingStatus>
                                ) : null}
                              </DataCell>
                            </TableRow>
                          );
                        })}
                      </ModuleTable>
                    ) : null}
                  </CollapsibleList>
                );
              })
            ) : (
              <CenteredContainer>
                <P>
                  No drugs prescribed for patient, click the add prescription
                  button to prescribe drugs for patient
                </P>
              </CenteredContainer>
            )
          ) : null}
          {isError ? (
            <CenteredContainer>
              <P>An error occurred on the server</P>
            </CenteredContainer>
          ) : null}
        </Container>
      </ReusableDoctorTabs>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbar,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionTab);
