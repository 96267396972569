import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert, formatDateTime} from "../../Utils/ConvertDate";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {usePatientDetails} from "../ReferralNote/usePatientDetails";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import  {NewLabTable} from "./NewLabRequest";
import {CenteredContainer, RightAlignedContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";
import {CollapsibleList, DataCell,  ModuleTable} from "../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {StyledContainer} from "../Prescription/NewPrescription";
import PatientLabReport from "../../Lab/PatientLabReport";
import {
    PendingStatus,
    FinishedStatus,
    StyledIcon,
    P,
    Container,
    ViewButton,
    Processing,
    StyledLibraryIcon
} from "../doctorStyles"
import {useNewLabRequest} from "./hooks/useNewLabRequest";
import {useEditLabRequest} from "./hooks/useEditLabRequest";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {ConfirmationDialog} from "../../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {FormDialog} from "../../Utils/Dialogs/FormDialog";
import {LabResultsReport} from "../../Lab/LabResultsReport";
import {usePrint} from "../../Utils/Templates/usePrint";







const headData = [{item:'Lab test', toolTip:'Make Lab test request here', isRequired:true},{item:'Lab', isRequired:true},
     {item:'Status', isRequired:false},{item:'Action', isRequired:false}]
const InPatientLabRequest = ({actions, snackbars, match:{params}}) => {
    const {patient_number, visit_id, labrequest_id, scan_request_id,
        patient_type,patient_admission_id,activity_id} = params;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    const [labRequests, setLabRequests] = useState([])
    const [status, setStatus] = useState('idle')
    const [previousRequest, setRequest] = useState([]);
    const [openReport, setOpenReport] = useState(false)
    const [id, setId] = useState('')

    const [report, setReport] = useState({});
    const [labTests,setLabTests] = useState([]);
    const [result_id, setResultId] = useState('')
    const [openResult, setOpenResult] = useState(false)

    const [resultDetails, setResultDetails] = useState({});
    const [resultParams,setParams] = useState([]);

    const editParams = {...params, previousRequest}

    const {addProps} = useNewLabRequest(actions, params,_token)
    const {isResolved:isResolvedAdd,openConfirmation, handleCloseConfirmation, addItemsToReceipt,handleCancelConfirmation,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm} = addProps;
    const {editProps} = useEditLabRequest(actions,editParams,_token)
    const {isResolved:isResolvedEdit, labTests:editTests} = editProps;


    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);


    useEffect(()=>{
        if(!id){
            return
        }
        const formData = new FormData();
        formData.append('labrequest_id', id);
        axios.post(`${config.smsUrl}/claboratory/get_patient_lab_report_by_id`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const rp = !dt.patient_info ? {} : dt.patient_info;
            const reportObj = {...rp,approved_first_name:rp.approved_firstname, approved_last_name:rp.approved_lastname, visit_id}
            const tests = !dt.tests ? [] : dt.tests;
            setReport(reportObj);
            setLabTests(tests)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[id]);


    const handleOpenItem = (index) =>{
        const openedArr = labTests.map((item, idx)=>{
            if(idx === index){
                return {...item, open:!item.open}
            }else {
                return item
            }

        })
        setLabTests(openedArr)
    }



    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_lab_request_by_visit_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = groupByLabRequestId(dt)
            const requests = arr.map(item=>({...item, open:true}))
            setStatus('success')
            setLabRequests(requests);
            setRequest(dt)
        }).catch(error => {
            setStatus('error')
            logoutErrorMessage(error, null, actions)
        });
    }, [isResolvedAdd, isResolvedEdit])


    useEffect(()=>{
        if (!result_id){
            return;
        }
        const formData = new FormData();
        formData.append('test_results_id',result_id);
        axios.post(`${config.smsUrl}/claboratory/get_lab_results_details_by_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient_info = !dt.patient_info ? {} : dt.patient_info;
            const parameters = !dt.params ? [] : dt.params;
            setResultDetails({...patient_info,visit_id});
            setParams(parameters)
        }).catch(err => {
            logoutErrorMessage(err,null,actions);
        })
        return ()=>{
            setResultDetails({})
            setParams([])
        }

    },[result_id])



    const groupByLabRequestId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.labrequest_id;
            if(id in knowledge){
                result[knowledge[id]].tests.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    bill_id:item.bill,
                    bill_type:item.bill_type,
                    date: formatDateTime(item.date_created),
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    tests: [item]
                })

            }
        })
        return result
    }

    const handleOpen = (id) =>{

        const itemsArr = labRequests.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setLabRequests(itemsArr)
    }

    const handleOpenReport  = (_id) =>{
        setId(_id)
        setOpenReport(true)
    }

    const handleCloseReport  = () =>{
        setOpenReport(false)
    }

    const handleOpenResult  = (test_results_id) =>{
        setResultId(test_results_id)
        setOpenResult(true)
    }

    const handleCloseResult  = () =>{
        setOpenResult(false)
    }

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {componentRef, handlePrint,  fontSize}  = usePrint();



    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)
    const {patient_name, age, gender, address, phone} = patient;
    const {openBar, type, message} = snackbars;
    const {openDialog, handleCloseDialog, handleOpenDialog, handleSubmitLabRequest, isPending} = addProps
    const {openEdit, handleCloseEdit, handleOpenEdit, handleSubmitLabRequest:handleSubmit, isPending:isPendingEdit} = editProps
    return (
        <div className='journals'>
            <PageTitle title="Lab Request"/>
            <SubHeader title="Doctor" subTitle="Lab Request" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <FormDialog open={openDialog} handleClose={handleCloseDialog} title='Add Lab Request' maxWidth='md'
                        handleSubmit={handleSubmitLabRequest}
            buttons={ <StyledContainer>
                <button type='submit' className='btn sms-btn btn-sm' disabled={isPending}>
                    {isPending ? 'Saving...' :'Save Lab Request'}</button>
            </StyledContainer>}>
                    <NewLabTable {...addProps}/>
            </FormDialog>

            <CustomDialog open={openReport} handleClose={handleCloseReport} title='Lab Results' maxWidth='lg'>
                <RightAlignedContainer>
                    <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
                        Print
                    </button>
                </RightAlignedContainer>
                <div ref={componentRef}>
                    <PatientLabReport data={report} labTests={labTests} handleOpenItem={handleOpenItem} labReport/>
                </div>
            </CustomDialog>


            <CustomDialog open={openResult} handleClose={handleCloseResult} title='Lab Results' maxWidth='lg'>
                <RightAlignedContainer>
                    <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
                        Print
                    </button>
                </RightAlignedContainer>
                <div ref={componentRef}>
                    {/*{fontSize}*/}
                    <LabResultsReport params={resultParams} resultDetails={resultDetails}/>
                </div>
            </CustomDialog>


            <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}
                                title='Make cash payment'
                                confirmItems={addItemsToReceipt}
                                removeItems={handleCloseConfirmation} service='lab test'/>

            <FormDialog open={openEdit} handleClose={handleCloseEdit} title='Edit Lab Request'
                        maxWidth='lg' handleSubmit={handleSubmit} buttons={  <StyledContainer>
                <button type='submit' className='btn sms-btn btn-sm' disabled={isPendingEdit}>
                    {isPendingEdit ? 'Saving...' :'Save Lab Request'}</button>
            </StyledContainer>}>
                    <NewLabTable {...{...editProps, isEdit:true, isOutPatient: true}}/>
            </FormDialog>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation {...{patient_number, visit_id}} />
                <Container>
                    <button onClick={handleOpenDialog}  type='button' className='btn btn-sm sms-info-btn mb-2'>Add Lab Request</button>
                    {isLoading ? <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer> : null}
                    {isSuccess ? labRequests.length > 0 ? labRequests.map((item)=>{
                        // const isAdded = item.tests.some(item=>item.item_status !== 'Done')

                        const isDone = item.tests.some(item=>item.item_status === 'Done')
                        const isProcessing = item.tests.some(item=>item.item_status === 'Processing')
                        const payStatus = item.tests.every(item=>item?.item_status?.toLowerCase() === 'pending')
                        return(
                            <CollapsibleList key={item.id} item={item} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={
                                <>
                                    {payStatus ? <Tooltip title='Edit Request'>
                                        <IconButton onClick={()=>handleOpenEdit(item)}>
                                            <StyledIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                                    {isDone ?
                                        <ViewButton onClick={()=>handleOpenReport(item.id)} className='btn btn-sm sms-info-btn btn-sm'>View Results</ViewButton>
                                        : null}
                                    {/*{isDone ? <Tooltip title='Lab Results'>*/}
                                    {/*    <IconButton onClick={()=>handleOpenReport(item.id)}>*/}
                                    {/*        <StyledLibraryIcon/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip>: null}*/}
                                </>
                            }
                                // user={ <ItemText  primary={`Requested By: ${item.doctor_name}`} />}
                            >
                                <ModuleTable headData={headData}>
                                    {item.tests.map((row, index) => {
                                        const isFinished = row.item_status === 'Done';
                                        const status = {
                                            'Pending':<PendingStatus>{row.item_status ? row.item_status : 'Pending'}</PendingStatus>,
                                            'Processing': <Processing>{row.item_status}</Processing>,
                                            'Done':<FinishedStatus>{row.item_status}</FinishedStatus>
                                        }
                                        return(
                                            <TableRow
                                                key={index}
                                            >
                                                <DataCell>
                                                    {row.hospital_test_name}
                                                </DataCell>

                                                <DataCell>{row.hospital_test_type}</DataCell>
                                                <DataCell>
                                                    {status[row.item_status]}
                                                </DataCell>
                                                <DataCell>{isFinished ?
                                                    <Tooltip title='View Results'>
                                                        <IconButton onClick={()=>handleOpenResult(row.test_results_id)}>
                                                            <StyledLibraryIcon/>
                                                        </IconButton>
                                                    </Tooltip> : null}
                                                </DataCell>
                                            </TableRow>
                                        )})}
                                </ModuleTable>
                            </CollapsibleList>
                        )
                    }) : <CenteredContainer>
                        <P>No lab tests requested for patient, click the add request button to request lab tests for patient</P>
                    </CenteredContainer>:null}
                    {isError ? <CenteredContainer>
                        <P>An error occurred on the server</P>
                    </CenteredContainer>:null}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InPatientLabRequest);