import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { config } from "../../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import { history } from "../../../../Helpers/history";
import { titleCase } from "../../../Users/addUser";
import { useAddProduct } from "../../products/hooks/useAddProduct";
import { useAddSupplier } from "./useAddSupplier";
import { useEditPurchaseOrderData } from "./useEditPurchaseData";
import {useCurrency} from "../../../../Context/currency-context";
import {useVersionsContext} from "../../../../Context/versions-context";
import { isCurrentFutureDate, isCurrentPastDate } from "../../../Utils/ConvertDate";

export const useAddPurchase = (actions, match = {}) => {
    const {purchase_id, local_purchase_order_id,draft} = match;

    // const {productsArr, purchaseObj} = useEditPurchaseData(actions, purchase_id, draft)
    const {orderObj, orderArr} = useEditPurchaseOrderData(actions, local_purchase_order_id)

    const initialState = [{
        product_name: '',
        product_id: '',
        expiry_date: '', 
        uuid: uuidv4(),
        stock: '', 
        quantity: '',
        supplier_price: '', 
        sub_total: '',
        batch_id: '',
        serial: '', 
        warranty: '',
        model: '',
        condition: '', 
        showProduct: false, 
        isError:false, 
        usd_total:0,
        usd_cost:0,
        product_purchase_details_id: "",
    }];

    const [state, setState] = useState({
        payment_method: '1', 
        purchase_date: `${moment().format("YYYY-MM-DD")}`,
        details: '',
        invoice_no:'',
        expected_payment_date:`${moment().format("YYYY-MM-DD")}`,
    });

    const [supplier, setSupplier] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [products, setProducts] = useState(initialState);
    const [grand_total, setGrandTotal] = useState(0);
    const [productIds, setIds] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setDialog] = useState(false);
    const [purchaseCsv, setPurchaseCsv] = useState('')
    const [purchases, setPurchase] = useState([])
    const [category, setCategory] = useState('medicine');
    const [submitCsv, setSubmitCsv] = useState(false);
    const [isCsv, setIsCsv] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [usdAmount, setUsdAmount] = useState(0)
    const [amountToPay, setAmountToPay] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [product_purchase_id, setPPId]=useState("")
    const [supplier_payment_id, setSPid] = useState("")

    const ref = useRef()
    
    const formRef = useRef()

    const {supplierProps, openAddSupplier, setOpenAddSupplier,isResolved} = useAddSupplier(actions, true)
    
    const {productProps,openProductDialog, setOpenProductDialog} = useAddProduct({actions, product_id:''}, true)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const {currency:selectedCurrency} = useCurrency({actions})

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'


    useEffect(()=>{
        if (isPremium){
            return
        }
        setCurrency(`${selectedCurrency?.id}-${selectedCurrency?.is_base_currency}-${selectedCurrency?.currency_symbol}`)
    },[version])

    useEffect(() => {
        axios.get(`${config.smsUrl}/csupplier/retrieve_all_suppliers`)
            .then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const supps = !dt.total_manufacturers ? [] : dt.total_manufacturers;
            const arr =supps.map(supp=>({value:supp.supplier_id,label:supp.supplier_name}))
            setSuppliers(arr);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, [isResolved]);

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`)
            .then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    // useEffect(() =>{
    //     if (!purchase_id){
    //         return
    //     }
    //     const formData = new FormData();
    //     formData.append('product_purchase_id', purchase_id)
    //     axios.post(`${config.smsUrl}/cinventory/purchase_update_form`, formData).then(res => {
    //         const data = res.data;
    //         const dt = !data ? {} : data;
    //         const prods = dt.purchase_info ?? [];
    //         const {supplier_id, supplier_name, payment_method,currency_rate,
    //             details, purchase_date, grand_total:grandTotal, chalan_no, currency_id,
    //             currency_symbol, is_base_currency, expected_date,currency_grand_total} = dt;


    //         const ex_rate = currency_rate ? currency_rate : 0

    //         const symbol = currency_symbol ? currency_symbol : ''
    //         const isBase = is_base_currency === true;

    //         const prodArr = prods.map(item=> {
    //             const rate = isBase ? item.rate : +item.rate / ex_rate
    //             const t_amount = item.quantity * rate
    //             const usd_total = isBase ? t_amount :   (item.quantity * rate) * ex_rate
    //             return {
    //                 ...item,
    //                 supplier_price:rate,
    //                 usd_cost:item.rate,
    //                 usd_total,
    //                 sub_total:t_amount,
    //                 uuid: uuidv4(),
    //             }
    //         })

    //         const g_total = isBase ? grandTotal : currency_grand_total

    //         setProducts(prodArr)
    //         setSupplier({value: supplier_id, label: supplier_name});
    //         setState({payment_method, details, purchase_date, invoice_no:chalan_no,
    //             expected_payment_date:expected_date})
    //         setGrandTotal(g_total)
    //         setUsdAmount(grandTotal)
    //         setCurrency(`${currency_id}-${is_base_currency}-${symbol}`)
    //         setExchangeRate(ex_rate)

    //     }).catch((err) => {
    //         logoutErrorMessage(err, null, actions)
    //     })
    // },[])

    useEffect (()=> {
        const formData = new FormData();
         formData.append('product_purchase_id', purchase_id)

        const url = purchase_id ?  axios.post(`${config.smsUrl}/cinventory/purchase_update_form`, formData) :
        axios.get(`${config.smsUrl}/cinventory/retrieve_draft_purchases`)
    url.then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prods = dt.purchase_info ?? [];
            // const currency_id = prods.currency_id
            // const currency_symbol = prods.currency_symbol
            const {supplier_id, supplier_name, payment_method,currency_rate,product_purchase_id,supplier_payments_id,
                details, purchase_date, grand_total:grandTotal, chalan_no,rate,currency_symbol,currency_id,
                 is_base_currency, expected_date,currency_grand_total} = dt;

            const purchaseObj = prods[0] ? prods[0] : {};



            const prod_purchase_id = product_purchase_id ?? ''
            const payment_id = purchaseObj.supplier_payment_id ?? ''
            const ex_rate = currency_rate ? currency_rate : 0

            const symbol = currency_symbol ? currency_symbol : ''
            const isBase = is_base_currency === true;

            const prodArr = prods.map(item=> {
                const rate = isBase ? item.rate : +item.rate / ex_rate
                const t_amount = item.quantity * rate
                const usd_total = isBase ? t_amount : (item.quantity * rate) * ex_rate
                return {
                    ...item,
                    supplier_price:rate,
                    usd_cost:item.rate,
                    usd_total,
                    sub_total:t_amount,
                    uuid: uuidv4(),
                }
            })

            const g_total = isBase ? grandTotal : currency_grand_total
            const isCurrency = currency_id && is_base_currency && symbol

            const arr = prodArr.length > 0 ? prodArr:initialState

            setProducts( arr)
            setSupplier({value: supplier_id, label: supplier_name});
            setState({payment_method, details, purchase_date, invoice_no:chalan_no,
                expected_payment_date:expected_date})
            setPPId(prod_purchase_id)
            setSPid(payment_id)

            setGrandTotal(g_total)
            setUsdAmount(grandTotal)
            setCurrency( isCurrency ? `${currency_id}-${is_base_currency}-${symbol}` : '')
            setExchangeRate(ex_rate)

        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    },[])
    
    //map edit data
    useEffect(() => {
        if(!local_purchase_order_id){
            return
        }
            const {supplier_id, supplier_name, grand_total} = orderObj
            setSupplier({value: supplier_id, label: supplier_name});
            setGrandTotal(grand_total)
            setProducts(orderArr)



    }, [orderObj, orderArr, local_purchase_order_id])




    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const ex_rate = +event.target.value > 0 ? 1/+event.target.value : 0
        setCurrencyExchangeRate(event.target.value)
        setExchangeRate(ex_rate)
        calculateAmount(ex_rate)
        calculateGrandTotal(products, ex_rate)
        setIsBlocking(true)
        // multiplyCurrency(event.target.value, amountToPay)
    }

    const handleChangeAmountToPay = (event) => {
        setAmountToPay(event.target.value);
        setIsBlocking(true)
        // multiplyCurrency(exchangeRate, event.target.value)
    };


    const fetchData = () => {
        axios.get(`${config.smsUrl}/cinventory/getallpurchases`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const purs = !dt.purchase_list ? [] : dt.purchase_list;
            setPurchase(purs)
            ref.current.link.click()
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    };

    const handleChangeSupplier = (value) => {
        setSupplier(value)
        setIsBlocking(true)
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    }

    const handleChangeCategory = (event) => {
        const {value} = event.target;
        setCategory(value)
        setIsBlocking(true)
    }

    const handleChangeItems = (event, index) => {
        let productCopy = [...products];
        let {name, value} = event.target;
        let tmp = productCopy[index] ? productCopy[index] : {};
        tmp[name] = value;
        productCopy[index] = tmp;
        setProducts(productCopy);
        setIsBlocking(true)
    };

    const handleAddRow = () => {
        setProducts([...products, ...initialState])
    };

    const handleRemoveRow = (item_uuid) => {
        const new_arr = products.filter(e => e.uuid !== item_uuid);
        calculateGrandTotal(new_arr, exchangeRate);
        setProducts(new_arr)
    };

    const calculateGrandTotal = (arr, rate=0) => {
        const sum = arr.reduce((a, row) => {
            return +(a + +row.sub_total);
        }, 0);

        const usd_sum = arr.reduce((a, row) => {
            const base_total = (+row.supplier_price * rate) * +row.quantity
            return +(a + +base_total);
        }, 0);

        setGrandTotal(sum)
        setUsdAmount(usd_sum)
    };

    const retrieveProducts = (eventTxt, idx) => {
        const productCopy = [...products];
        if (productCopy[idx]) {
            productCopy[idx].product_name = eventTxt;
            const formData = new FormData();
            const isMedicine = category === 'medicine'
            formData.append('category', isMedicine ? 1 : 2);
            formData.append('product_name', titleCase(eventTxt));
            if (eventTxt) {
                axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? [] : resp;
                    if (data[0] === "No Product Found") {
                        const val = [{value: "", label: "No Medicine Found"}]
                        setIds(val)
                    } else {
                        const arr = data.map(item=>({value:item.value, label: `${item.label}-${item.generic_name}-${item.country_of_origin}`}))
                        setIds(arr);
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                productCopy[idx].showProduct = true;
                productCopy[idx].isError = false;
            } else {
                productCopy[idx].isError = false;
                productCopy[idx].showProduct = false;
                setIds([]);
            }
        } else {
            productCopy[idx].showProduct = false;
        }
        setProducts(productCopy)
        setIsBlocking(true)
    };

    const clickProduct = (event, idx) => {
        const productCopy = [...products];
        const formData = new FormData();
        formData.append('product_id', event.value);
        if (productCopy[idx]) {
            productCopy[idx].product_name = event.innerText;
            productCopy[idx].product_id = event.value;
            if (event.value){
                axios.post(`${config.smsUrl}/cpharmacy/retrieve_product_info_by_id`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? {} : resp;
                    const {stock} = data;
                    productCopy[idx].stock = stock;
                    setProducts(productCopy)
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                productCopy[idx].isError = false;
            }else{
                productCopy[idx].isError = true;
            }
        
            productCopy[idx].showProduct = false;
        }

        setProducts(productCopy)
        setIsBlocking(true)
    };

    const calculateAmount = (rate) => {
        const arr = products.map(item=>({...item,
            usd_cost: +item.supplier_price * +rate, usd_total: (+item.supplier_price * rate) * +item.quantity
        }))
        setProducts(arr)
    }

    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const arr = products.map((item, idx)=>{
            const currency_total = +item.supplier_price * +value
            const base_total = item.usd_cost * +value
            if(idx === index){
                return {...item,quantity:Math.ceil(value), sub_total: currency_total, 
                    usd_total: base_total}
            }
            return item
        })
        setProducts(arr);
        calculateGrandTotal(arr, exchangeRate)
        setIsBlocking(true)
    };

    const handleChangePrice = (event, index) => {
        const {value} = event.target;
        const arr = products.map((item, idx)=>{
            const currency_total = +value * +item.quantity
            const base_total = (+value * exchangeRate) * +item.quantity
            //supplier_price: Math.round(value * 1000) / 1000 - rounded value
            if(idx === index){
                return {...item,supplier_price:value, 
                    usd_cost: +value * +exchangeRate, sub_total: currency_total, 
                    usd_total: base_total}
            }
            return item
        })
        setProducts(arr);
        setIsBlocking(true)
        calculateGrandTotal(arr, exchangeRate)
    }
    const handleChangeSubTotal = (event, index) => {
        const {value} = event.target;
        const arr = products.map((item, idx)=>{
            if(idx === index){
                return {...item, sub_total: Math.round(value * 1000)/1000, 
                    supplier_price: Math.round((+value / +item.quantity) * 1000) / 1000}
            }
            return item
        })
        setProducts(arr);
        calculateGrandTotal(arr, exchangeRate)
        setIsBlocking(true)
    }

    const handleOpenDialog = () => {
        setDialog(true)
    }
    const handleCloseDialog = () => {
        setDialog(false)
    }

    const handleOpenSupplierDialog = () => {
        setOpenAddSupplier(true)
    }
    const handleCloseSupplierDialog = () => {
        setOpenAddSupplier(false)
    }

    const handleOpenProductDialog = () => {
        setOpenProductDialog(true)
    }
    const handleCloseProductDialog = () => {
        setOpenProductDialog(false)
    }


    const handleUploadCsv = (event) => {
        event.preventDefault()
        let formData = new FormData();
        formData.append('upload_csv_file', purchaseCsv);
        setSubmitCsv(true)
        if (purchaseCsv) {
            setIsCsv(true)
            axios.post(`${config.smsUrl}/cinventory/uploadCsv_Purchase`, formData).then(() => {
                setIsCsv(false)
                actions.snackbarActions.snackSuccess('Purchases uploaded successfully');
                setDialog(false)
                history.push('/purchaselist')
            }).catch(err => {
                setIsCsv(false)
                errorMessages(err, null, actions)
            })
        }

    };

    const handleChangePurchaseCsv = event => {
        if (event.target.files[0].name.substr(event.target.files[0].name.length - 4) === ".csv") {
            setPurchaseCsv(event.target.files[0]);
        } else {
            event.target.value = null;
            actions.snackbarActions.snackError('Please upload only csv format')
        }
    };
 

    const handleSubmit = (event,is_draft=false) => {
        event.preventDefault();
        const formData = new FormData(formRef.current)

        const fieldValues = Object.fromEntries(formData.entries())
        const url = purchase_id && !draft ? 'purchase_update' : 'insert_purchase';

        const baseCurrency = !isPremium ? `${selectedCurrency?.id}-${selectedCurrency?.is_base_currency}-${selectedCurrency?.currency_symbol}` : currency

        const isBase = baseCurrency.split('-')[1]

        const isNotBase = isBase === 'false'

        const currency_id = baseCurrency.split('-')[0]

        const isNotBasData = isNotBase ?  {rate_input_by_cashier: +exchangeRate,
            grand_total_price_in_base_currency: +usdAmount, grand_total_price_in_currency: +grand_total } : {grand_total_price: grand_total};

        const productsArr =   products.filter(prod => prod.product_id  &&
            prod.quantity && prod.supplier_price && prod.sub_total).map(item=>{
                const prodData = isNotBase ? {unit_cost_in_currency: +item.supplier_price, unit_cost_in_base_currency:item.usd_cost,
                    amount_paid_in_the_currency:item.sub_total,total_amount_in_base_currency:item.usd_total}:{product_rate: +item.supplier_price,
                total_price:item.sub_total}
                return {
                    product_id:item.product_id,
                    product_quantity: +item.quantity,
                    product_purchase_details_id: item.product_purchase_details_id,
                    // supplier_payment_id: item.supplier_payment_id ? item.supplier_payment_id : '',
                    ...prodData

                }
        })



        const editParams = purchase_id && !draft ? {product_purchase_id:purchase_id} :{}
        const lpoParams = purchase_id && !draft ? {local_purchase_order_id} : {}
        
        const params = {...editParams, ...lpoParams, supplier_id:supplier.value, ...fieldValues, paytype:1,
        bank_id:1, total_discount:0,  user_id:user_roles_id,category:1,currency_id:+currency_id, is_base_currency:isBase,...isNotBasData,
            products:productsArr,is_draft, supplier_payment_id,product_purchase_id
        }

        const purchase_date = fieldValues['purchase_date']
        const expected_payment_date = fieldValues['expected_payment_date']

        const selected_purchase_date = isCurrentPastDate(purchase_date)
        const selected_future_date = isCurrentFutureDate(expected_payment_date)

        setSubmitted(true)
        if(baseCurrency && selected_purchase_date && selected_future_date){
            setIsSubmitted('pending');
            setIsBlocking(false)
            axios.post(`${config.smsUrl}/cinventory/${url}`, params).then(_ => {
                actions.snackbarActions.snackSuccess('Products purchase submitted successfully');
                setIsSubmitted('resolved');
                history.push('/purchaselist')
                sessionStorage.removeItem(purchase_id);
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
            
        
    }

    const handleSaveDraft = (event) =>{
        if(currency){
            handleSubmit(event,true)
        }
           
            
    }

    const handleOpenConfirm = (e) =>{
        e.preventDefault();
        const formData = new FormData(formRef.current)

        const fieldValues = Object.fromEntries(formData.entries())

        const baseCurrency = !isPremium ? `${selectedCurrency?.id}-${selectedCurrency?.is_base_currency}-${selectedCurrency?.currency_symbol}` : currency

        const isBase = baseCurrency.split('-')[1]

        const isNotBase = isBase === 'false'

        const isBaseCurrency = isBase === 'true'


        const currency_id = baseCurrency.split('-')[0]
        const isBaseRequired = (isNotBase && currency_id && exchangeRate) || isBaseCurrency && currency_id

        const purchase_date = fieldValues['purchase_date']
        const expected_payment_date = fieldValues['expected_payment_date']

        const selected_purchase_date = isCurrentPastDate(purchase_date)
        const selected_future_date = isCurrentFutureDate(expected_payment_date)


        const arr = products.every(prod=> prod.product_id  && prod.quantity && prod.supplier_price)

        if (supplier && arr && fieldValues['chalan_no'] && fieldValues['expected_payment_date'] &&
            fieldValues['purchase_date'] && baseCurrency && isBaseRequired && selected_purchase_date && selected_future_date ){
                setConfirmDialog(true)
            }
    }

    const handleCloseConfirm = ( ) =>{
          setConfirmDialog(false)
    }
 

    const handleReset = () => {
        setProducts([{
            product_name: '', product_id: '', expiry_date: '',
            stock: '', quantity: '', supplier_price: '', sub_total: '', batch_id: ''
        }]);
        setState({payment_method: 1, details: ''});
        setSupplier('')
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }


    const isResolvedPurchase = isSubmitted === 'resolved'
    const isPendingDraft = isSubmitted === 'pendingDraft'


    const purchaseProps = {handleSubmit, isSubmitted, products, productIds, handleChange, handleChangeSubTotal,
    handleAddRow, handleChangeItems, handleOpenDialog,removeRow:handleRemoveRow, retrieveProducts, submitted,draft,
    grand_total,handleChangeQuantity,state, handleReset,suppliers,handleChangeSupplier, supplier, clickProduct,formRef,
    category, handleChangeCategory,handleChangePrice,handleOpenSupplierDialog,handleOpenAddProduct:handleOpenProductDialog,
        currencies, currency, usdAmount, amountToPay,handleChangeCurrency, handleChangeAmountToPay,handleSaveDraft,
        handleChangeExchangeRate, exchangeRate,isResolvedPurchase,isBlocking, setIsBlocking,isPendingDraft,
        currencyExchangeRate, 
    }

    return {closeSnackbar,openDialog, purchases,purchaseCsv, ref, fetchData,submitCsv, isCsv,
        handleCloseDialog, handleUploadCsv, handleChangePurchaseCsv, handleOpenConfirm,handleCloseConfirm,confirmDialog,
        productProps, openProductDialog,  handleCloseProductDialog,formRef, isSubmitted,
        openAddSupplier, supplierProps,  handleCloseSupplierDialog, purchaseProps}
}
