import React, {useState} from 'react';
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import Card from "@material-ui/core/Card";
import {ReloadObservationTriageChart} from "../../MedicationandVisits/ObservationChart"
import { useObservationChart } from '../../MedicationandVisits/CustomHooks/useObservationChart';
import CustomDialog from '../../Utils/Dialogs/CustomDialog';
import Triage from '../../Triage/Triage';
import { usePatientDetails } from '../ReferralNote/usePatientDetails';
import { useVisitList } from '../../Visit/NewVisit/hooks/useVisitList';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '22px',
        overflow:'initial',
        padding:'20px',
        height: 'inherit'
    }
}));

const OutPatientTriage = ({actions, snackbars, match}) => {
    const {patient_number, visit_id, labrequest_id,activity_id,
        scan_request_id, patient_type,patient_admission_id} = match.params;
    const [takeVitals, setTakeVitals] = useState(false);
    const ids = { visit_id, patient_number,patient_type, activity_id};
    const {patient:state} = usePatientDetails(actions, patient_number, '')

    //const {triage} = useDoctorTriage(actions,ids);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const { handleSubmitTriage, triageProps,  handlePatientDetails, isTriage, 
         handleEditTriage} = useVisitList(actions,activity_id);

    const {tabular, handleChangeTabular, tableData, graphData,
        expanded,handleChange} = useObservationChart(actions, visit_id, isTriage);

    const {patient_name, age, gender, address, phone} = state;
    const {openBar, type, message} = snackbars;
    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [openDialog, setOpenDialog] = useState(false);
    const {triageList, triage:propsTriage, setSubmitted:setSubmitTriage, isEditTriage, setEditTriage,
        setAllMetrics,  clearTriageDetails, setSaveTriage,
         } = triageProps;
    
    const arr1 = triageList.filter(metric=>metric.metric_id !== 6).some(metric=>metric.metric_value);
    const arr2 = propsTriage.some(metric => metric.metric_value);
    const IskipTriage = (arr2);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (isEditTriage) {
            setEditTriage(false);
            clearTriageDetails();
        } else {
            setSaveTriage(false);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handlePatientDetails({patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id});
    };

    const handleTakeVitals = (e) => {
        setTakeVitals(true);
        setSaveTriage(true);
      //  setEditTriage(false);
        setSubmitTriage(false);
        handleOpenDialog();
    }

    const handleSubmitTriageSurgery = (event) => {
        handleSubmitTriage(event);
        if (IskipTriage) {
            setTakeVitals(false);
            handleCloseDialog();
        }
    }

    const handleOpenEditTriage = (item) => {
        setEditTriage(true);
        const dt = item.data ? item.data : [];
        setAllMetrics(dt);
        handleOpenDialog();
    }

    const handleCloseEditTriage = (event) => {
        setEditTriage(false);
        clearTriageDetails();
        handleEditTriage(event);
        handleCloseDialog();
    }


    return (
        <div className='journals'>
            <PageTitle title="Triage"/>
            <SubHeader title="Doctor" subTitle="Triage" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
            <CustomDialog title={!isEditTriage ? "Save Triage" : "Edit Triage"} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isPaperStyle={true} isContentOverflow={false}>
                {takeVitals && (isEditTriage === false) ?
                    <div className='okay-try'>
                        <form autoComplete='off' onSubmit={handleSubmitTriageSurgery}>
                            <Triage {...{...triageProps, patient_number,patient:{patient_name, age, gender}}}/>
                            <button type="submit" disabled={false}  className="btn btn-sm sms-btn mt-3">{'Save Triage'}</button>
                        </form>

                    </div>                
                : isEditTriage ?
                    <div className='okay-try'>
                        <form autoComplete='off' onSubmit={handleCloseEditTriage}>
                            <Triage {...{...triageProps, patient_number,patient:{patient_name, age, gender}}}/>
                            <button type="submit" disabled={false}
                                    className="btn btn-sm sms-btn mt-3">{'Edit Triage'}</button>
                        </form>

                    </div>                
                : null
                }
            </CustomDialog>

                <PatientInformation {...{patient_number, visit_id}}/>
                <div className="patient-triage">
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    {tableData && tableData.length > 0 ?
                        <Card className='p-2'>
                            <ReloadObservationTriageChart {...{ tabular, handleChangeTabular, tableData, graphData, closeSnackbar, expanded,handleChange, handleOpenEditTriage, isDoctorScreen:true }}/>
                        </Card>
                    :null}
                    <button type="submit" className="btn btn-sm sms-btn" onClick={handleTakeVitals} style={{marginTop:"10px", marginBottom:"10px"}}>Add Triage</button>
                </div>
            </ReusableDoctorTabs>
        </div>

    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(OutPatientTriage);
