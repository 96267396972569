/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, {useEffect, useState} from 'react'
import {Container, ErrorMessage, RightAlignedContainer} from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import {dateStrokeConvert, isCurrentYear} from '../../Utils/ConvertDate';
import { LoadingGif } from '../../Utils/Loader';
import {coloredInvoice, textWeight, thUppercase, printTable} from "../../../styles/tableStyles";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";
import {removeTrailingZeros} from "../utils/removeDecimals";
import {useCurrency} from "../../../Context/currency-context";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";




const borderless = css`
   border-bottom: 0;
`

const tableMargin = css`
    margin-bottom: 0;
`

const removeNumbers = (text="") =>{
    return text.replace(/[0-9]/g, '');
}

const InvoiceItemsTable = ({headData, items, isBaseCurrency,co_payment,currency_symbol,total_base_amount,total_non_base_amount,handleSplitBill}) =>{
    return(
        <ItemsTable {...{headData, coloredTableHead:coloredInvoice, tableHeading:printTable, capitalize:thUppercase}}>
            <tbody>
            {items.map((item) => {
                return (
                    <tr key={item.bill_id}>
                        <td className='table-items'><span>{item.quantity}</span></td>
                        <td className='table-items'><span>{item.name}</span></td>
                        <td className='table-items'>
                            <span>{!isBaseCurrency ? formatSsp(item.other_currency_rate) : formatDigits(item.usd_rate.toFixed(3))}</span>
                        </td>
                        <td className='table-items'>
                            <span>{!isBaseCurrency ? formatSsp(item.currency_total) : formatDigits(item.usd_total.toFixed(3))}</span>
                        </td>
                        <td className='table-items'>
                            <Form.Check type='checkbox'  checked={item.isSplit} onChange={(e)=>handleSplitBill(e,item.bill_id)}/>
                        </td>
                    </tr>
                )
            })}

            <tr>
                <td colSpan={headData.length - 1} align='right'><span css={[textWeight]}>TOTAL</span></td>
                <td><span
                    css={[textWeight]}> {co_payment.discount <= 0 ?  currency_symbol :  null} {isBaseCurrency ? formatDigits(total_base_amount.toFixed(3)) : formatSsp(total_non_base_amount)}</span>
                </td>
            </tr>
            {/*{co_payment.discount > 0 ? <>*/}
            {/*    <tr>*/}
            {/*        <td colSpan={headData.length - 1} align='right'><span css={[textWeight]}>CO-PAYMENT ({`${co_payment.discount}%`})</span></td>*/}
            {/*        <td><span*/}
            {/*            css={[textWeight]}>{co_payment.discount_amount}</span>*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td colSpan={headData.length - 1} align='right'><span css={[textWeight]}>GRAND TOTAL</span></td>*/}
            {/*        <td><span*/}
            {/*            css={[textWeight]}> {currency_symbol} {isBaseCurrency ? formatDigits(co_payment.grand_total_bill.toFixed(3)) : formatSsp(co_payment.grand_total_bill)}</span>*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*</>: null}*/}
            </tbody>
        </ItemsTable>
    )
}

function SplitInvoice({actions, snackbar, match, setOpenSplit, isSubmitted, setIsSubmitted}) {
    const {currency:selectedCurrency} = useCurrency({actions, snackbar, match})
    const {visit_id, provider_id, provider_type} = match.params;
    const [receipt, setReceipt] = useState({});
    const [items, setItems] = useState([]);
    const [total_amount, setTotalAmount] = useState({total_base_amount:0, total_non_base_amount:0,
        split_base_amount:0, split_non_base_amount:0})
    const [co_payment, setCoPayment] = useState({discount:0, discount_amount:0, grand_total_bill:0})
    const [status, setStatus] = useState('idle')
    const [currency, setCurrency] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [splitItems, setSplitItems] = useState([])
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyType, setCurrencyType] = useState(null)
    const [split_date, setSplitDate] = useState('')

    const reduceAmounts = (arr = []) =>{
        return arr.reduce((a, item) => {
            return a + item.amount
        }, 0)
    }

    const convertList = (receiptsArr,  ex_rate, isBase, total_bill) =>{
        const productArr = groupProductId(receiptsArr.filter(item=>item.product_id))
        const serviceArr = groupServiceId(receiptsArr.filter(item=>item.service_id))
        const bedArr = receiptsArr.filter(item=>item.bed_assignment_id)
        const arr = [...serviceArr, ...productArr, ...bedArr].map((item) => {
            const rate = item.rate ? item.rate : 0
            return {
                ...item,
                name:item.service_id ? item.service_name : item.product_id ? item.product_name :
                    item.bed_assignment_id ? `${item.ward_name} -> ${removeNumbers(item.room_name)}` : '',
                quantity:item.bed_assignment_id ? `${item.quantity} day${item.quantity > 1 ? 's' : ''}` : item.quantity,
                usd_rate:!isBase ? (ex_rate ? rate * ex_rate : rate) : rate,
                other_currency_rate : !isBase ? rate: (ex_rate ? rate / ex_rate : rate),
                usd_total:!isBase ? (ex_rate ? item.amount * ex_rate : item.amount) : item.amount,
                currency_total: !isBase ? item.amount: (ex_rate ? item.amount / ex_rate : item.amount)
            }
        })
        
        //original bills totals
        const base_total_amount = reduceAmounts(arr.filter(item=>!item.isSplit).map(item=>({amount:item.usd_total})))
        const non_base_total_amount = reduceAmounts(arr.filter(item=>!item.isSplit).map(item=>({amount:item.currency_total})))
        
        //split bills totals
        const split_total_amount = reduceAmounts(arr.filter(item=>item.isSplit).map(item=>({amount:item.usd_total})))
        const split_base_total_amount = reduceAmounts(arr.filter(item=>item.isSplit).map(item=>({amount:item.currency_total})))
        
        const convertedArr = arr.map(item=>({
            ...item,
            other_currency_rate:removeTrailingZeros(item.other_currency_rate.toFixed(3)),
            currency_total:removeTrailingZeros(item.currency_total.toFixed(3))
        }))
        
        const originalBills = convertedArr.filter(item=>!item.isSplit)
        
        const splitBills = convertedArr.filter(item=>item.isSplit)
        
        const base_amount = total_bill ? total_bill :  base_total_amount
        const non_base_amount = total_bill ? total_bill : non_base_total_amount
        
        setItems(convertedArr)
        setSplitItems(splitBills)
        setTotalAmount({total_base_amount:base_amount ,split_base_amount: split_total_amount,
            split_non_base_amount:  removeTrailingZeros(split_base_total_amount.toFixed(3)),
            total_non_base_amount: removeTrailingZeros(non_base_amount.toFixed(3)) })
    }

    const isResolved = isSubmitted === 'resolved'

    useEffect(() => {
        setStatus('pending')
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('provider_id', provider_id);
        axios.post(`${config.smsUrl}/cbilling/view_visit_invoices`,formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? {} : all_data.patient_info;
            const invoices = all_data.bill ?? [];
            const invoiceArr = invoices.map(item=>({
                ...item,
                isSplit:false
            }))
            const is_base_obj = all_data.base_currency ?? {}

            const obj = invoices[0] ? invoices[0] :{};
            const exchange_rate = obj.currency_rate ? obj.currency_rate : 0
            const symbol = is_base_obj.currency_symbol ? is_base_obj.currency_symbol : selectedCurrency?.currency_symbol
            const is_base = !(is_base_obj.is_base_currency == null || undefined) ? is_base_obj.is_base_currency : selectedCurrency?.is_base_currency
            obj['date_created'] = data['visit']['begin_datetime']

            setCoPayment({discount: all_data?.discount, discount_amount: all_data?.discount_amount, grand_total_bill: all_data?.grand_total_bill})
            setReceipt({...info, ...obj});
            convertList(invoiceArr,  exchange_rate, is_base,all_data.total_bill)
            setStatus('resolved')
            setCurrency(`${symbol}-${is_base}`)
            setExchangeRate(exchange_rate)
            setCurrencyType(is_base)
        }).catch(e => {
            setStatus('rejected')
        })
    }, [isResolved]);
    
    const handleSplitBill = (event, bill_id) =>{
        const arr = items.map(item=>{
            if (item.bill_id === bill_id){
                return {...item, isSplit:event.target.checked}
            }
            return item
        })
        convertList(arr,exchangeRate,currencyType)
    }

    const handleUnSplitBill = (event, bill_id) =>{
        const arr = items.map(item=>{
            if (item.bill_id === bill_id){
                return {...item, isSplit:false}
            }
            return item
        })
        convertList(arr,exchangeRate,currencyType)
    }

    const handleChangeDate = (event) =>{
        setSplitDate(event.target.value)
    }

    const this_year = isCurrentYear(split_date)
    const handleSave = (event) =>{
        event.preventDefault()
        setSubmitted(true)

        if (split_date && splitItems.length > 0 && this_year){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/split/invoice/${visit_id}`,{new_date:split_date, bills:splitItems}).then(() => {
                actions.snackbarActions.snackSuccess("Invoice split successfully")
                setOpenSplit(false)
                setIsSubmitted('resolved')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }

    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = status === 'pending'
    const isSuccessful = status === 'resolved'
    const isRejected = status === 'rejected'


    const {total_non_base_amount, total_base_amount, split_non_base_amount, split_base_amount} = total_amount


    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const isInsurance = provider_type === 'Insurance Company'

    const columnData = isInsurance ? [{key:'quantity',value:'Quantity'}, {key:'name',value:'Description'}]:
        [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'}]

    const headData = [...columnData,
        {key:'rate',value:`Rate(${currency_symbol})`}, {key:'amount',value:`Amount(${currency_symbol})`}]

    const {openBar, type, message} = snackbar;




    const year = new Date().getFullYear().toString()
    const current_year = year.split('').slice(-2).join('')

    const patientDetails = (
        <tbody>
        {/*<tr>*/}
        {/*    <td css={[textWeight, thUppercase]} ><p css={[titleText]}>Invoice To:</p></td>*/}
        {/*    <td css={[textWeight, thUppercase]}><p css={[titleText]}>Invoice Details:</p></td>*/}
        {/*</tr>*/}
        <tr>
            <td css={[thUppercase]}><span className='span-heading'>Invoice No.: </span> <span css={[textWeight]}>{`${receipt?.visit_id}/${current_year}`}</span></td>
            <td css={[thUppercase]}><span className='span-heading'>Date: </span> <span
                css={[textWeight]}>{receipt.date_created ? dateStrokeConvert(receipt.date_created) : ''}</span></td>

        </tr>
        <tr>
            <td css={[thUppercase]}><span className='span-heading'>Bill To: </span> <span css={[textWeight]}>{receipt.billed_to ? receipt.billed_to : ''}</span></td>
            <td css={[thUppercase]}><span className='span-heading'>Patient Name: </span> <span
                css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
            </span></td>
        </tr>
        {/*<tr>*/}
        {/*    <td><span className='span-heading'>Member Number:</span> <span css={[textWeight]}>{receipt.card_number}</span></td>*/}
        {/*    /!*<td><span className='span-heading'>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>*!/*/}
        {/*</tr>*/}
        </tbody>
    )

    const originalInvoice = (
        <PrintTemplate tableHeading={printTable} title='Original Invoice' {...{patientDetails,
            itemDetails: <InvoiceItemsTable {...{headData:[...columnData, {key:'rate',value:`Rate(${currency_symbol})`},
                    {key:'amount',value:`Amount(${currency_symbol})`},{key:'split', value:'Split'}], items:items.filter(item=>!item.isSplit), isBaseCurrency,co_payment,currency_symbol,
                total_base_amount,total_non_base_amount,handleSplitBill}}/>, bioBordered:borderless, bioMargin:tableMargin}}/>
    )

    const isPending = isSubmitted === 'pending'

    return (
        <div>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={handleCloseSnackbar}/>
            {isLoading ? <LoadingGif/>:null}
            {isSuccessful  ? <div>
                <form onSubmit={handleSave}>
                    <Form.Group style={{width:'30%'}}>
                        <Label name='Date' type/>
                        <TextField submitted={submitted} type='date' value={split_date} onChange={handleChangeDate}/>
                        {submitted && !split_date ? <ErrorMessage>This field is required</ErrorMessage>: split_date && !this_year ? <ErrorMessage>Enter current year</ErrorMessage> : null}
                    </Form.Group>
                    {splitItems.length > 0 ? <div className='row'>
                        <div className="col-lg-6">
                            {originalInvoice}
                        </div>
                        <div className="col-lg-6">
                            <PrintTemplate tableHeading={printTable} title='Split Invoice' {...{patientDetails,
                                itemDetails: <InvoiceItemsTable {...{headData:[...columnData, {key:'rate',value:`Rate(${currency_symbol})`},
                                        {key:'amount',value:`Amount(${currency_symbol})`},{key:'undo', value:'Undo'}], items:splitItems, isBaseCurrency,co_payment,currency_symbol,
                                    total_base_amount:split_base_amount,total_non_base_amount:split_non_base_amount,handleSplitBill:handleUnSplitBill}}/>, bioBordered:borderless, bioMargin:tableMargin}}/>
                            <RightAlignedContainer>
                                <button type='submit' disabled={isPending} className='btn mt-2 btn-sm sms-btn small-btn'>{isPending ? 'Saving...':'Save invoice'}</button>
                            </RightAlignedContainer>
                        </div>
                    </div>: originalInvoice}
                </form>

                </div> : null}
            {isRejected ? <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <p>The server did not return a valid response</p>
            </div>:null}
        </div>
    )
}


export default SplitInvoice;

