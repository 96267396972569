import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/styles";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage} from "../Utils/styledComponents";
import ReusablePrescriptionDetails from "./ReusablePrescription";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { batch } from "react-redux";
import { FinishedStatus, PendingStatus } from "../DoctorsModule/doctorStyles";


const returnHeadData = [{name:'Drug Name'},{name:'Quantity Dispensed'},
{name:'Quantity Used'},{name:'Balance'}, 
{name:'Choosen Batch ID(s)', sub_headings: ["Batch ID", "Dispensed Quantity", "Quantity to Return"]},
{name:'Quantity Returned'},{name:'Status'}]
const ReturnPrescription = ({actions, openDialog, setOpenDialog, prescriptionIds,
                                isSubmitted, setIsSubmitted}) => {

    const [medicineReturn, setMedicineReturn] = useState([]);
    const [state, setState] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);



    /**
     * get the patient details (patient_info)
     */
    useEffect(() => {
        if(!prescriptionIds?.visit_id){
            return;
        }
        axios.get(`${config.smsUrl}/cpharmacy/medicine-return/visit/${prescriptionIds?.visit_id}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient = !dt.patient ? {} : dt.patient;
            const medicine_return = dt.medicine_return_details ? dt.medicine_return_details : []

            setState({
                patient_id: patient.patient_number,
                patient_name: `${!patient.first_name ? "" : patient.first_name} ${!patient.last_name ? "" : patient.last_name}`,
                gender: patient.gender,
                age: patient.age, weight: patient.weight, blood_pressure: patient.blood_pressure,
                reference: patient.reference, visit_id: patient.visit_id, date: patient.begin_datetime,
                policy_number: patient.policy_number, address: patient.address, phone:patient.phone_no
            })

            const arr = medicine_return.map(item=>{
                const batches = item.medicine_return.batches !== null ? item?.medicine_return?.batches?.map(batch=>({
                    ...batch,
                    quantity_returned:batch.quantity
                })): item?.prescription_detail?.batches?.map(batch=>({
                    ...batch,
                    quantity_returned:0
                })) 
                return{
                ...item,
                product_id:item.product.product_id,
                product_name:item.product.product_name,
                quantity_given:item.prescription_detail.quantity_given,
                quantity_administered:+item.prescription_detail.quantity_given - +item.medicine_return.quantity_to_returned,
                quantity_remaining:+item.medicine_return.quantity_to_returned,
                batches:batches
            }})
            setMedicineReturn(arr)
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [prescriptionIds?.visit_id]);


    /**
     * Submit returned medicine to the api
     */
    const submissionReturnCall = () => {
        const request_data = medicineReturn.filter(item=>item.quantity_returned).map(item=>({
            medicine_return_id:+item.medicine_return.id,
            batches:item.batches.filter(batch=>batch.quantity_returned).map(batch=>({
                batch_id:batch.batch_id,
                quantity:batch.quantity_returned

            })).filter(item=>+item.quantity > 0)
        }))

        const params = {
            items : request_data
        }

        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cpharmacy/receive-medicine-returns/visit/${prescriptionIds?.visit_id}`, params).then(() => {
            setIsSubmitted('resolved');
            actions.snackbarActions.snackSuccess('Product(s) received successfully');
            setSubmitted(false)
            setOpenDialog(false)
            setConfirmDialog(false);
        }).catch(err => {
            errorMessages(err, null, actions);
            setIsSubmitted('rejected');
        })

    };

    /**
     * open confirmation dialog before submitting returned medicine to the api
     * @param event
     */
    const handleSubmitReturn = (event) => {
        event.preventDefault();

        const arr = medicineReturn.some(item=>{
            return (item.quantity_returned <= item.quantity_remaining && item.quantity_returned >= 0)
        });
        const batchArr = medicineReturn.map(item=>item.batches.every(batch=>batch.quantity_returned && batch.quantity_returned <= batch.quantity))

        const isBatch = batchArr.some(item=>item)

        setSubmitted(true);
        if (arr && isBatch) {
            setConfirmDialog(true)
        }

    }

    /**
     * OnChange for the quantity returned
     * @param event
     * @param btch - batch object {batch_id, quantity, ...rest}
     * @param btch_idx - Index of the current batch
     * @param index - Index of the product
     */
    const handleChangeBatchReturnQuantity = (event, btch, btch_idx, index) => {

        const {value} = event.target;
        let allRows = [...medicineReturn];

        allRows[index]['batches'][btch_idx]['quantity_returned'] = parseInt(value);
        let new_btches = allRows[index]['batches'];

        // calc the total_chosen_quantity
        let new_quantity_returned = 0;
        for (var x = 0; x < new_btches.length; x++) {
            new_quantity_returned += parseInt(new_btches[x].quantity_returned? new_btches[x].quantity_returned : 0);
        }

        allRows[index]['quantity_returned'] = parseInt(new_quantity_returned);
        setMedicineReturn(allRows);
    }

    const isPendingReturn = isSubmitted === 'pending';


    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <>
            <PrintDialog openDialog={confirmDialog} handleClose={()=>setConfirmDialog(false)} handleCancel={()=>setConfirmDialog(false)}
                         text="Would you like to save the return?" handleClick={submissionReturnCall} message="Confirm return"/>

            <CustomDialog open={openDialog} handleClose={() => setOpenDialog(false)} title={'Return Remaining Stock'}
                          maxWidth="xl" isContentOverflow={false}>
                <form onSubmit={handleSubmitReturn} id="submit-prescription" className="mt-2" autoComplete="off">
                    <ReusablePrescriptionDetails {...{
                        headData: returnHeadData,
                        text: 'Receive Balances',
                        state,
                        isFillPrescription: true
                    }}>
                        <tbody>
                        {medicineReturn.length > 0 ? medicineReturn.map((row, index) => {
                            const quantity_returned = row?.medicine_return?.batches?.reduce((prev, curr)=> +prev + +curr.quantity,0)
                            const status = {
                                0:<PendingStatus>Pending</PendingStatus>,
                                1: <FinishedStatus>Received</FinishedStatus>
                            }
                                return (
                                    <tr key={row.product_id}>
                                        <td>{row.product_name}</td>
                                        <td>{row.quantity_given}</td>
                                        <td>{row.quantity_administered}</td>
                                        <td>{row.quantity_remaining}</td>
                                        <td>
                                            <table>
                                                <tbody>
                                                {row.batches?.map((btch, btch_idx) => (
                                                    <tr key={btch_idx}>
                                                        <td style={{width: '30%'}}>
                                                            {btch.batch_id}
                                                        </td>
                                                        <td style={{width: '30%'}}>
                                                            {btch.quantity}
                                                        </td>
                                                        <td style={{width: '40%'}}>
                                                            {row?.medicine_return?.status === 0 ? <TextField submitted={submitted} type="number"
                                                                       value={btch.quantity_returned}
                                                                       style={{fontSize: '11px'}}
                                                                       onChange={(e) => handleChangeBatchReturnQuantity(e, btch, btch_idx, index)}/>: btch.quantity_returned}
                                                            {btch.quantity_returned > btch.quantity ? <ErrorMessage>Quantity should be below or equal to the quantity dispensed</ErrorMessage>:null}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            {row?.medicine_return?.status === 0 ? row.quantity_returned : quantity_returned}
                                            {row.quantity_returned > row.quantity_remaining ? <ErrorMessage>Quantity should be below or equal to the remaining quantity</ErrorMessage>:null}
                                            {/*{submitted && (row.quantity_returned < 0) ?*/}
                                            {/*    <ErrorMessage>This is a required field</ErrorMessage> : null}*/}
                                            {/*{submitted && (*/}
                                            {/*    row.quantity_returned > (row.quantity_given - row.quantity_administered)*/}
                                            {/*) ?*/}
                                            {/*    <ErrorMessage>Quantity Returned is greater than balance</ErrorMessage> :*/}
                                            {/*    null}*/}
                                        </td>
                                        <td>
                                            {status[row?.medicine_return?.status]}
                                        </td>

                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={returnHeadData.length} align="center"
                                    className={palette.type === 'dark' && 'dark-theme-color'}>No drugs
                                    given
                                </td>
                            </tr>}
                        </tbody>
                    </ReusablePrescriptionDetails>
                    <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn"
                            disabled={isPendingReturn}>{isPendingReturn ? 'Saving...' : 'Save'}</button>

                </form>
            </CustomDialog>
        </>


    )
};

export {ReturnPrescription};
