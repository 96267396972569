import React, {useCallback, useEffect, useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as doctorActions from "../../actions/DoctorsActions/doctorActions"
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {dateConvert, formatDateTime} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import Form from 'react-bootstrap/Form';
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {ErrorMessage} from "../Utils/styledComponents";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import styled from "@emotion/styled/macro";
import * as colors from "../../styles/colors"
import {FullScreenDialog} from "../Utils/Dialogs/FullScreenDialog";
import {usePrint} from '../Utils/Templates/usePrint';
import {useRetrievePatientCount} from "./hooks/useRetrievePatientCount";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../Utils/FormInputs/TextField";
import {useQuery} from "react-query";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {PatientMedicalHistory} from "./PatientHistory/PatientMedicalHistory";
import ReassignPatient from "./ReassignPatient";


const Status = styled.span({
    borderRadius: '15px',
    display: 'inline-block',
    padding: '3px 10px',
    fontSize: '11px'
})

const PendingStatus = styled(Status)({
    background: colors.lightOrange,
    color: colors.pending
})

export const Progress = styled(Status)({
    background: colors.progress,
    color: colors.progressText
})

const FinishedStatus = styled(Status)({
    background: colors.lightGreen,
    color: colors.green
})


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'Sl.', hidden: false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden: false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden: false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden: false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone ', hidden: false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden: false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name', hidden: false},
    {id: 'visit_department', numeric: false, disablePadding: false, label: 'Visit Department', hidden: false},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status', hidden: false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden: false},
];


export const DoctorDateFilter = ({state, handleChange}) => {
    return (
        <div className='row'>
            <div className='col-lg-6 col-sm-12 col-md-12'>
                <Row>
                    <Col xs={12} sm={2} md={2} lg={4} xl={3}>
                        <Label name='Start Date' type/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={7} xl={7}>
                        <TextField type='date' name='start_date' value={state.start_date} onChange={handleChange}/>
                    </Col>
                </Row>
            </div>
            <div className='col-lg-6 col-sm-12 col-md-12'>
                <Row>
                    <Col xs={12} sm={2} md={2} lg={4} xl={3}>
                        <Label name='End Date' type/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={7} xl={7}>
                        <TextField type='date' name='end_date' value={state.end_date} onChange={handleChange}/>
                        {state.end_date < state.start_date ?
                            <ErrorMessage>End date shouldn't be less than start date</ErrorMessage> : null}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export const PatientWaitingList = (props) => {
    const {actions} = props
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];

    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;
    const isAdmin = !_token.isAdmin ? false : _token.isAdmin;


    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [openAssign, setOpenAssign] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [department, setDepartment] = useState({value: '', label: ''});
    const [departments, setDepartments] = useState({})
    const [ids, setIds] = useState({patient_number: '', visit_id: '', patient_name: '', age: '', gender: ''});
    const [openEnd, setOpenEnd] = useState(false)
    const [isEnd, setIsEnd] = useState('idle')
    const [state, setState] = useState({start_date: dateConvert(), end_date: dateConvert()})
    const [loading, setLoading] = useState('idle')
    const [waitingList, setWaitingList] = useState([])
    const [total_count, setTotalCount] = useState(0)
    const [openRevert, setOpenRevert] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)

    const isResolved = isSubmitted === 'resolved';

    const isEnding = isEnd === 'pending';
    const isEnded = isEnd === 'resolved';

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`${config.smsUrl}/cvisit/retrieve_department`);

                const data = response.data ?? {}

                const departmentsData = data.departments ?? [];

                const arr = departmentsData.filter(item => item?.department_type?.toLowerCase() !== 'administrative').map((department) => ({
                    label: department.department_name,
                    value: department.department_id
                }));
                setDepartments(arr)
            } catch (error) {
                logoutErrorMessage(error, null, actions)
            }
        })()
    }, []);

    const handleClickPatient = (patient_number, visit_id, patient_type, activity_id, labrequest_id, scan_request_id, bill_id, patient_admission_id) => {
        const formData = new FormData();
        formData.append('visit_id', visit_id ? visit_id : '');
        formData.append('bill_id', bill_id ? bill_id : '');
        axios.post(`${config.smsUrl}/cvisit/Update_visit_takeup_status`, formData).then(() => {
            if (department?.label?.includes('Dental')) {
                history.push(`/dental-medical-history/${patient_number}/${visit_id}`)
            } else {
                history.push(`/previousvisits/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id ?? null}`)
            }
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        });
    };

    const handleOpenEndVisit = (item) => {
        setOpenEnd(true)
        const {visit_id, patient_number, patient_name, age, gender} = item
        setIds({visit_id, patient_number, patient_name, age, gender});
    };

    const handleOpenRevert = (item) =>{
        setOpenRevert(true)
        setIds(item)
    }

    const handleEndVisit = (action) => {
        const formData = new FormData();
        const department_value = isAdmin ? (department?.value ? department?.value : department_id) : department_id

        formData.append('visit_id', ids.visit_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('department_id', department_value);
        formData.append('action', action);

        const isEnded = action === 'end'
        if (ids.visit_id) {
            setIsEnd('pending')
            axios.post(`${config.smsUrl}/cvisit/complete_visit`, formData).then(() => {
                actions.snackbarActions.snackSuccess(`Visit ${isEnded ? 'ended' : 'reverted'} successfully`);
                setOpenEnd(false)
                setOpenRevert(false)
                setIsEnd('resolved')
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsEnd('rejected')
            });
        }
    };

    const getAllPatients = useCallback( ()=>{
        (async ()=>{
            setLoading('pending')
            try {
                const formData = new FormData();
                const department_value = isAdmin ? (department?.value ? department?.value : department_id) : department_id
                formData.append('search', searchValue);
                formData.append('start_date', state.start_date);
                formData.append('end_date', state.end_date);
                formData.append('department_id', department_value);
                formData.append('per_page', rowsPerPage);

                const response = await axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${activePage}`, formData);

                const data = response?.data ?? {};

                const patients = data?.waiting_list ?? [];

                const count = data?.total_count ?? 0;

                const list = patients.map((item, index) => {
                    const status = {
                        2: <PendingStatus>Pending</PendingStatus>,
                        3: <Progress>Treatment ongoing</Progress>,
                        4: <FinishedStatus>Complete</FinishedStatus>
                    }
                    const buttons = {
                        2: (
                            <>
                                <button
                                    className={`btn btn-sm mr-3 mb-sm-3 mb-2  ${item.status === 2 ? 'sms-info-btn' : 'sms-amber-btn'}`}
                                    onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1, item.activity_id)}>{
                                    item.status === 2 ? "Take up" : "Continue"}
                                </button>
                                {/*<button className='btn btn-sm sms-info-btn mb-sm-3 '*/}
                                {/*        onClick={() => {*/}
                                {/*            setOpenAssign(true)*/}
                                {/*            setIds(item)*/}
                                {/*        }}>Reassign patient*/}
                                {/*</button>*/}
                            </>

                        ),
                        3: (
                            <>
                                <button
                                    className={`btn btn-sm mr-3 mb-sm-3 mb-2  ${item.status === 2 ? 'sms-info-btn' : 'sms-amber-btn'}`}
                                    onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1, item.activity_id)}>{
                                    item.status === 2 ? "Take up" : "Continue"}
                                </button>
                                {/*<button className='btn btn-sm sms-info-btn mb-sm-3 '*/}
                                {/*        onClick={() => {*/}
                                {/*            setOpenAssign(true)*/}
                                {/*            setIds(item)*/}
                                {/*        }}>Reassign patient*/}
                                {/*</button>*/}
                                <button onClick={() => handleOpenEndVisit(item)}
                                        className="btn btn-sm sms-info-btn mb-sm-3 mb-2 mr-3 ">End Visit
                                </button>
                            </>


                        ),
                        4: (
                            <>
                                <button className='btn btn-sm mr-3 sms-gray-btn mb-sm-3 mb-2'
                                        onClick={() => handleOpenRevert(item)}>Revert Visit
                                </button>
                                <button className='btn btn-sm sms-info-btn mb-sm-3 '
                                        onClick={() => {
                                            setOpenDialog(true)
                                            setIds(item)
                                        }}>View Details
                                </button>
                            </>


                        )
                    }
                    return {
                        ...item,
                        count: ((activePage - 1) * rowsPerPage) + index + 1,
                        date: item.begin_datetime ? formatDateTime(item.begin_datetime) : '',
                        patient_number: item.patient_number,
                        patient_name: `${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
                        doctor_name: `${item.doctor_first_name} ${item.doctor_last_name}`,
                        status: status[item.status],
                        action: buttons[item.status]
                    }
                });
                setLoading('success')
                setWaitingList(list)
                setTotalCount(count)
            } catch (error) {
                setLoading('error')
                logoutErrorMessage(error, null, actions);
            }
        })()
    },[activePage, rowsPerPage, state.start_date, state.end_date, searchValue, isResolved, isEnded, department])

    useEffect(() => {
        getAllPatients();
        const interval = setInterval(() => {
            getAllPatients();
        }, 10000);

        return () => clearInterval(interval);
    }, [getAllPatients]);

    // const patientsQuery = useQuery(
    //     ["patientsQuery", activePage, rowsPerPage, state.start_date, state.end_date, searchValue, isResolved, isEnded, department],
    //     async () => {
    //         try {
    //             const formData = new FormData();
    //             const department_value = isAdmin ? (department?.value ? department?.value : department_id) : department_id
    //             formData.append('search', searchValue);
    //             formData.append('start_date', state.start_date);
    //             formData.append('end_date', state.end_date);
    //             formData.append('department_id', department_value);
    //             formData.append('per_page', rowsPerPage);
    //
    //             const response = await axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${activePage}`, formData);
    //
    //             const data = response?.data ?? {waiting_list: [], total_count: 0};
    //
    //             const patients = data?.waiting_list ?? [];
    //
    //             const count = data?.total_count ?? 0;
    //
    //             const list = patients.map((item, index) => {
    //                 const status = {
    //                     2: <PendingStatus>Pending</PendingStatus>,
    //                     3: <Progress>Treatment ongoing</Progress>,
    //                     4: <FinishedStatus>Complete</FinishedStatus>
    //                 }
    //                 const buttons = {
    //                     2: (
    //                         <>
    //                             <button
    //                                 className={`btn btn-sm mr-3 mb-sm-3 mb-2  ${item.status === 2 ? 'sms-info-btn' : 'sms-amber-btn'}`}
    //                                 onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1, item.activity_id)}>{
    //                                 item.status === 2 ? "Take up" : "Continue"}
    //                             </button>
    //                             {/*<button className='btn btn-sm sms-info-btn mb-sm-3 '*/}
    //                             {/*        onClick={() => {*/}
    //                             {/*            setOpenAssign(true)*/}
    //                             {/*            setIds(item)*/}
    //                             {/*        }}>Reassign patient*/}
    //                             {/*</button>*/}
    //                         </>
    //
    //                     ),
    //                     3: (
    //                         <>
    //                             <button
    //                                 className={`btn btn-sm mr-3 mb-sm-3 mb-2  ${item.status === 2 ? 'sms-info-btn' : 'sms-amber-btn'}`}
    //                                 onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1, item.activity_id)}>{
    //                                 item.status === 2 ? "Take up" : "Continue"}
    //                             </button>
    //                             {/*<button className='btn btn-sm sms-info-btn mb-sm-3 '*/}
    //                             {/*        onClick={() => {*/}
    //                             {/*            setOpenAssign(true)*/}
    //                             {/*            setIds(item)*/}
    //                             {/*        }}>Reassign patient*/}
    //                             {/*</button>*/}
    //                             <button onClick={() => handleOpenEndVisit(item)}
    //                                     className="btn btn-sm sms-info-btn mb-sm-3 mb-2 mr-3 ">End Visit
    //                             </button>
    //                         </>
    //
    //
    //                     ),
    //                     4: (
    //                         <>
    //                             <button className='btn btn-sm mr-3 sms-gray-btn mb-sm-3 mb-2'
    //                                     onClick={() => handleEndVisit('revert', item.visit_id)}>Revert Visit
    //                             </button>
    //                             <button className='btn btn-sm sms-info-btn mb-sm-3 '
    //                                     onClick={() => {
    //                                         setOpenDialog(true)
    //                                         setIds(item)
    //                                     }}>View Details
    //                             </button>
    //                         </>
    //
    //
    //                     )
    //                 }
    //                 return {
    //                     ...item,
    //                     count: ((activePage - 1) * rowsPerPage) + index + 1,
    //                     date: item.begin_datetime,
    //                     patient_number: item.patient_number,
    //                     patient_name: `${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
    //                     doctor_name: `${item.doctor_first_name} ${item.doctor_last_name}`,
    //                     status: status[item.status],
    //                     action: buttons[item.status]
    //
    //                 }
    //             });
    //             setWaitingList(list)
    //             setTotalCount(count)
    //             return {waitingList, total_count}
    //         } catch (error) {
    //             logoutErrorMessage(error, null, actions);
    //             return {waitingList: [], total_count: 0}
    //         }
    //     },
    //     {retry: 1, refetchInterval: 10000});
    //
    // const status = patientsQuery.isLoading ? "pending" : patientsQuery.isSuccess ? "success" : "rejected";
    //
    // let {waitingList, total_count} = patientsQuery.isSuccess ? patientsQuery.data : {waitingList: [], total_count: 0}



    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isRejected = loading === 'error';

    const handleChange = (event) => {
        const {value, name} = event.target
        setState({...state, [name]: value})
    }
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };



    const handleCloseEndVisit = () => {
        setOpenEnd(false)
    };


    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };


    const {total_patients} = useRetrievePatientCount(actions)

    const components = [{label: 'Waiting List', path: '/waitinglist', badge: true, badge_count: total_patients},
        {label: 'Patients Seen', path: '/patientsSeen'}];

    const doctor_list = !waitingList ? [] : waitingList;
    const {openBar, type, message} = props.snackbars;


    const {componentRef, handlePrint, fontSize} = usePrint('Medical History Report');


    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );


    const isDental = department?.label?.includes('Dental')
    return (
        <div data-testid="doctor-waiting-list" className='journals'>
            <PageTitle title="Doctor Waiting List"/>
            <SubHeader title="Doctor" subTitle="Waiting List">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <DeleteDialog message="revert" openDialog={openRevert} handleClose={()=>setOpenRevert(false)}
                          text="visit" title='Revert Visit'>
                <button className=' btn sms-info-btn btn-sm' id="revert_visit" disabled={isEnding}
                        onClick={() => handleEndVisit('revert')}>Revert Visit
                </button>
                <button className="btn sms-grey-btn btn-sm" onClick={()=>setOpenRevert(false)}>No</button>
            </DeleteDialog>

            <DeleteDialog message="end" openDialog={openEnd} handleClose={handleCloseEndVisit}
                          text="visit" title='End Visit'>
                <button className=' btn sms-info-btn btn-sm' id="end_visit" disabled={isEnding}
                        onClick={() => handleEndVisit('end')}>End Visit
                </button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseEndVisit}>No</button>
            </DeleteDialog>
            <FullScreenDialog open={openDialog} title='Visit Details' handleClose={() => setOpenDialog(false)}>
                <div ref={componentRef}>
                    {fontSize}
                    <PatientMedicalHistory {...{
                        actions,
                        isDentalDepartment: isDental,
                        visit: {visit_id: ids.visit_id, patient_number: ids.patient_number}
                    }}/>
                </div>
            </FullScreenDialog>
            <ReassignPatient {...{openAssign, setOpenAssign, isSubmitted, setIsSubmitted, ids, actions}}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">

                <div className="row mb-0">
                    {isAdmin ? <div className="col-lg-4">
                        <Form.Group>
                            <Row>
                                <Col xs={12} sm={3} md={3} lg={4} xl={3}>
                                    <Label name="Department" htmlFor='department' type/>
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={7} xl={7}>
                                    <CustomSelect onChange={value => setDepartment(value)} id='department' value={department}
                                                  options={departments} defaultValue='Select department'/>
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>: null}
                    <div className="col-lg-8">
                        <DoctorDateFilter {...{state, handleChange}}/>
                    </div>
                </div>

                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title="Waiting List" customPage handleChangeNextPage={handleChangeNextPage}
                             headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage} data={doctor_list} colSpan={5} term={searchValue}
                             handleHeadCells={handleHeadCells} all_hidden={all_hidden}
                             handleAllHeadCells={handleAllHeadCells} pagination={pagination}>

                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                      alt="loader"/></TableCell>
                        </TableRow> : null}
                        {isSuccess ? doctor_list.length > 0 ? doctor_list.slice(0, rowsPerPage)
                            .map((item, index) => {

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.patient_number}
                                        className='mui-table-row '
                                    >
                                        {headCells.map((head) => (
                                            <TableCell key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            }) : <TableRow>
                            <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                        </TableRow> : null}
                        {isRejected ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a valid
                                response</TableCell>
                        </TableRow> : null}
                    </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {doctors: state.doctors, snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            doctorActions: bindActionCreators(doctorActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientWaitingList);
