/** @jsxImportSource @emotion/react */
import {css} from "@emotion/react";
import React, { useEffect, useState } from "react";
import DentalDashboard from "./DentalDashboard";
import DentalPatientDetails from "./DentalPatientDetails";
import axios from "axios";
import { config } from "../../Helpers/env";
import { logoutErrorMessage } from "../../Helpers/ErrorMessages";
import { formatDateTime } from "../Utils/ConvertDate";
import { PreviousVisitContainer } from "../Utils/Lists/PreviousVisitContainer";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { PreviousHistoryTabs } from "../Utils/Menu/ReusableDoctorTabs";
import {
  VisitDate,
  VisitType,
} from "../DoctorsModule/PatientPreviousVisits";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import { connect } from "react-redux";
import DentalExaminationReport from "./DentalExaminationReport";
import {usePrint} from "../Utils/Templates/usePrint";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {RightAlignedContainer} from "../Utils/styledComponents";
import DentalServiceReport, {DentalServiceResults} from "./DentalServiceReport";
import {textWeight} from "../../styles/tableStyles";
import {heading} from "./DentalServiceReport";


export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
  },
  pathListItem: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabListItem: {
    borderBottom: "0.75px solid #e8e8e8",
  },
  listItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "pointer",
    textTransform: "capitalize",
    "&:hover": {
      borderRight: "2px solid #3f51b5",
      color: "#3f51b5",
      background: "rgba(14, 118, 186, .08)",
    },
  },
  activeLi: {
    borderRight: "2px solid #3f51b5",
    color: "#3f51b5",
  },
  primary: {
    fontSize: "13.86px",
  },
  item2: {
    borderLeft: "1px solid #c7c6c7",
  },
  pathItem: {
    height: "100vh",
  },
  item: {
    // borderRight: '1px solid #c7c6c7',
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c7c6c7",
      "-webkit-border-radius": "10px",
      borderRadius: "10px",
    },
  },
  tabItem: {
    height: "auto",
  },
}));

const printDiv = css`
    display: none;
  @media print{
    display: block;
  }
`

function DentalMedicalHistory(props) {
  const { patient_number } = props.match.params;
  const { actions } = props.actions;
  const [visits, setVisits] = useState([]);
  const [visitDetails, setVisitDetails] = useState({
    examination: {},
    dental_services: [],
  });

  const [openPrint, setOpenPrint] = useState(false);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const department_id = !_token.department_id ? "" : _token.department_id;

  const tabs = [
    { value: "Examination", component: "examination", isActive: true },
    { value: "Dental Services", component: "dental_services" },
  ];

  function sortArr(arr = []) {
    return arr.sort(
      (a, b) => new Date(b.begin_datetime) - new Date(a.begin_datetime)
    );
  }

  const retrieveVisitDetails = (v_id, sortedArr=[],isInitial = false,) => {
    const formData = new FormData();
    formData.append("visit_id", v_id);
    formData.append('department_id', department_id)
    axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
      const data = res.data;
      const details = !data ? {} : data;
      const dental = details.dental_results ? details.dental_results : [];
      const exam_notes = details.exam_notes ? details.exam_notes : []
      const dental_exam = details.dental_exam ? details.dental_exam : {}
      const arr = sortedArr.map((item, index)=>({
        ...item,components:tabs,activeItem:'examination', open: isInitial ? index === 0 : !item.open,
        dental_services : dental,
        examination :{...dental_exam},
      }))
      setVisits(arr)
    }).catch(err => {
      logoutErrorMessage(err, null, actions)
    })
  };

  useEffect(() => {
    if (!patient_number) {
      return;
    }
    const formData = new FormData();
    formData.append("patient_number", patient_number);
    formData.append('department_id', department_id)
    axios
      .post(`${config.smsUrl}/cpatient/get_patient_previous_visits`, formData)
      .then((res) => {
        const data = res.data;
        const visit = !data ? {} : data;
        const visitsArr = !visit.patient_visits ? [] : visit.patient_visits;
        const sortedArr = sortArr(visitsArr);
        const singleVisit = sortedArr[0] ? sortedArr[0] : {};
        const v_id = singleVisit.visit_id ? singleVisit.visit_id : "";
        if (v_id){
          retrieveVisitDetails(v_id, sortedArr,true)

        }
      }).catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  }, [patient_number]);


  const handleOpenVisit = (v_id) => {
    retrieveVisitDetails(v_id, visits);
  };

  const handleClickComponent = (index, item_name, v_id) => {
    const arr = visits.map((item) => {
      if (item.visit_id === v_id) {
        return {
          ...item,
          activeItem: item_name,
          components: item?.components?.map((component, idx) => {
            if (idx === index) {
              return { ...component, isActive: true };
            }
            return { ...component, isActive: false };
          }),
        };
      }
      return item;
    });
    setVisits(arr);
  };


  const handleOpenPrint = (item) => {
    setOpenPrint(true);
    const formData = new FormData();
    formData.append("visit_id", item.visit_id);
    formData.append('department_id', department_id)
    axios.post(
        `${config.smsUrl}/cdoctor/get_all_patient_treatment_details`,
        formData
      )
      .then((res) => {
        const data = res.data;
        const details = !data ? {} : data;
        const dental = details.dental_results ? details.dental_results : [];
        const exam_notes = details.exam_notes ? details.exam_notes : []
        const notes_obj = exam_notes[0] ? exam_notes[0] : {}
        const dental_exam = details.dental_exam ? details.dental_exam : {}
        const diagnosis = details.diagnosis ? details.diagnosis : {}
        const medication = details.prescription ? details.prescription : {}

        setVisitDetails({
          ...visitDetails,
          dental_services: dental,
          examination: {...notes_obj,extraOral:dental_exam.extra_oral,
            softIntraOral:dental_exam.intra_oral_soft_tissue,
            hardIntraOral:dental_exam.intra_oral_hard_tissue, ...dental_exam,
          diagnosis, medication},
          visit_date: item.begin_datetime,
          visit_type: item.patient_type,
          visit_id: item.visit_id,
        });
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  };

  const handleClosePrint = () => {
    setOpenPrint(false);
  };


  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const classes = useStyles();

  const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');
  const {dental_services, examination} = visitDetails


  return (
    <DentalDashboard {...{ ...props, title: "Medical History" }}>
      <DentalPatientDetails {...{ patient_number }} />
      <CustomDialog open={openPrint} handleClose={handleClosePrint} title='Print Preview' maxWidth='lg'
                    isContentOverflow={true} isPaperStyle={true}>
        <RightAlignedContainer>
          <button type='button' className='btn btn-sm small-btn sms-amber-btn' onClick={handlePrint}>Print</button>
        </RightAlignedContainer>
        <div ref={componentRef}>
          {fontSize}
          <DentalExaminationReport {...{patient_number, ...examination}}>
            <DentalServiceResults {...{dental_services,
              title: dental_services.length > 0 ? (
                  <thead>
                  <tr>
                    <td><span css={[heading, textWeight]}>Services performed</span></td>
                  </tr>
                  </thead>
              ):''}}/>
          </DentalExaminationReport>
        </div>
      </CustomDialog>

      {visits.map((item, index) => {
        const type = {
          1: "OPD Visit",
          2: "IPD Visit",
        };
        const visitTitle = (
          <>
            <VisitType>{type[item.patient_type]}</VisitType>
            <VisitDate>
              Date:{" "}
              <strong>
                {item.begin_datetime ? formatDateTime(item.begin_datetime) : ""}
              </strong>
            </VisitDate>
          </>
        );

        const tab = {
          examination: <DentalExaminationReport {...{patient_number,...item['examination']}}/>,
          dental_services:<DentalServiceReport {...{patient_number,dental_services:item.dental_services}}/>,
        };

        return (
          <PreviousVisitContainer
            open={item.open}
            key={item.visit_id}
            name={visitTitle}
            idx={index}
            handleOpen={() => handleOpenVisit(item.visit_id)}
            button={
              <Tooltip title="Print">
                <button
                  onClick={() => handleOpenPrint(item)}
                  className="btn btn-sm sms-amber-btn"
                >
                  Print
                </button>
              </Tooltip>
            }
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                md={3}
                sm={2}
                lg={2}
                classes={{ item: `${classes.item} ${classes.tabItem}` }}
              >
                {item?.components
                  ?.filter((comp) => Boolean(comp))
                  .map((comp, idx) => (
                    <PreviousHistoryTabs
                      item={comp}
                      key={idx}
                      handleClick={() =>
                        handleClickComponent(idx, comp.component, item.visit_id)
                      }
                    />
                  ))}
              </Grid>
              <Grid
                item
                sm={10}
                xs={6}
                md={9}
                lg={10}
                classes={{ item: classes.item2 }}
              >
                <div className="p-3">{tab[item?.activeItem]}</div>
              </Grid>
            </Grid>
          </PreviousVisitContainer>
        );
      })}
    </DentalDashboard>
  );
}

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DentalMedicalHistory);
