import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useAddPrescription} from "./useAddPrescription";
import {useEditPrescription} from "./useEditPrescription";
import {formatDateTime} from "../../../Utils/ConvertDate";


export const usePreviousPrescription = (actions, match, _token, isRecordedTreatment, handleRecordTreatment) => {
    const { visit_id,  patient_type}=match
    const [allPrescriptions, setAllPrescriptions] = useState([])
    const [status, setStatus] = useState('idle')

    const [ids, setIds] = useState({prescription_id: '', prescription_detial_id: ''});
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [stopResolved, setStopResolved] = useState('idle')
    const [request, setRequest] = useState([])

    const editParams = {...match, request}

    const {addProps} = useAddPrescription(actions, {...match, request})
    const {editProps} = useEditPrescription(actions,editParams, _token)
    const {isResolved:isAddResolved} = addProps
    const {isResolved:isEditResolved} = editProps;

    const isStopped = stopResolved === 'resolved'
  
    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setStatus('pending')
        const url = patient_type === '1' ? 'get_previous_prescription_by_visit_id' : 'get_previous_inprescription_by_visit_id'
        axios.post(`${config.smsUrl}/cdoctor/${url}`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const prescrip = data.prescription ? data.prescription : [];
            const other_meds = data.other_med ? data.other_med : [];
            const others = other_meds.map(item=>({...item, product_name:item.other_medicine}))
            const drugs = [...prescrip, ...others]
            const presc = groupByPrescriptionId(drugs)
            const arr = presc.map(item=>({...item, open:true}))
            setAllPrescriptions(arr)
            setRequest(data)
            setStatus('success')
           // handleRecordTreatment();
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, [isAddResolved, isEditResolved,isStopped, isRecordedTreatment])


    const handleStopPresc = () => {
        const {prescription_id, prescription_detial_id} = ids
        const formData = new FormData()
        formData.append("prescription_id", prescription_id)
        formData.append("prescription_detial_id", prescription_detial_id)
        const url = patient_type === '2' ? "stop_inpatient_prescribed_prescription": ''
        setStopResolved('pending')
        axios.post(`${config.smsUrl}/cdoctor/${url}`,formData).then(()=> {
            setConfirmDialog(false)
            setStopResolved('resolved')
            actions.snackbarActions.snackSuccess(`Prescription stopped successfully`);
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }
    const groupByPrescriptionId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.prescription_id;
            if(id in knowledge){
                result[knowledge[id]].drugs.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    date: formatDateTime(item.date_created),
                    prescribed_by:item.prescriber_id,
                    doctor_name:`${item.prescribed_by_first_name ? item.prescribed_by_first_name:''} ${item.prescribed_by_last_name ? item.prescribed_by_last_name : ''}`,
                    dispensed_by: `${!item.dispensed_by_frist_name ? "" : item.dispensed_by_frist_name} ${!item.dispensed_by_last_name ? "" : item.dispensed_by_last_name}`,
                    dispensed_at: item.dispensed_at ? formatDateTime(item.dispensed_at) : '',
                    drugs: [item]
                })

            }
        })
        return result
    }

    const handleCloseConfirmDialog =() => {
        setConfirmDialog(false)
    }

    const handleOpenConfirmDialog =(prescription_id, prescription_detial_id) => {
       setIds({prescription_id:prescription_id, prescription_detial_id:prescription_detial_id})
       setConfirmDialog(true)
    }
    const handleOpen = (id) =>{
        const itemsArr = allPrescriptions.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setAllPrescriptions(itemsArr)
    }

   

  

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'



    return {allPrescriptions, handleOpen, addProps, isSuccess, isLoading, isError,editProps, handleStopPresc, handleCloseConfirmDialog, handleOpenConfirmDialog, confirmDialog, patient_type}
}