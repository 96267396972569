/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import PageTitle from "../Utils/smsTitle";
import { SubHeader } from "../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {
  doctorRoutes,
  PreviousHistoryTabs,
} from "../Utils/Menu/ReusableDoctorTabs";
import { useOutPatientMedicalHistory } from "./CustomHooks/useOutPatientMedicalHistory";
import {
  convertDateStrokeTime,
  dateConvert,
  formatDateTime,
} from "../Utils/ConvertDate";
import {
  DisplayAnteNatalDetails,
  getMetricValues,
  GetTriageDetails,
  ReusableTreatmentHistory,
  Spacer,
} from "./ReusableTreatmentHistory";
import { Container, RightAlignedContainer } from "../Utils/styledComponents";
import styled from "@emotion/styled/macro";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import { PatientInformation } from "./PatientInfo/PatientInformation";
import * as colors from "../../styles/colors";
import { PreviousVisitContainer } from "../Utils/Lists/PreviousVisitContainer";
import { SavedNotesContainer } from "./Notes/PatientNotesContainer";
import { splitNotes } from "../Radiology/RadiologyReport";
import { LabResultsTable } from "../Lab/PatientLabReport";
import { RadiologyResultReport } from "./Radiology/RadiologyResultReport";
import {
  borderedTriage,
  detailsTable,
  paddingCell,
  table,
  tableBordered,
  textWeight,
} from "../../styles/tableStyles";
import { ItemsTable } from "../Utils/Templates/PrintTemplate";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../Utils/Menu/ReusableDoctorTabs";
import Tooltip from "@material-ui/core/Tooltip";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { usePrint } from "../Utils/Templates/usePrint";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { CustomSelect } from "../Utils/FormInputs/SelectInput";
import Label, { FormLabel, ToolTip } from "../Utils/FormInputs/Label";
import { NotesRow, PreviousNotesTable } from "./Notes/PreviousNotesTable";
import { titleCase } from "../Users/addUser";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { ResizableTextarea } from "../Utils/FormInputs/Textarea";
import DentalExaminationReport from "../Dental/DentalExaminationReport";
import { DentalServiceResults, heading } from "../Dental/DentalServiceReport";
import { useVersionsContext } from "../../Context/versions-context";
import {
  ExpiredStatus,
  FinishedStatus,
  PendingStatus,
  Processing,
} from "./doctorStyles";
import {PatientMedicalHistory} from "./PatientHistory/PatientMedicalHistory";

export const Heading = styled.h4({
  fontSize: "16px",
  fontWeight: 700,
  marginBottom: 0,
});

export const ResultsHeading = styled.div({
  marginBottom: "10px",
});

export const Border = styled.div({
  border: `2px solid ${colors.indigo}`,
  width: "60px",
});

export const P = styled.p({
  margin: 0,
});

export const VisitType = styled(P)({
  fontWeight: 700,
});

export const VisitDate = styled(P)({
  fontSize: "13px",
});

export const NotesParagraph = styled(P)({
  fontSize: "12px",
});

export const longestString = (arr = []) => {
  let longString = "";
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.length > longString?.length) {
      longString = arr[i];
    }
  }
  return longString;
};

export const UpdateCompletedDiagnosis = ({
  codes,
  handleChangeState,
  handleSubmitNotes,
  state,
  allCodes,
  removeRow,
  retrieveAllCodes,
  showDiagnosis,
  getDetails,
  status,
  handleChangeStatus,
  submitted,
  disease,
  error,
  isNew,
  handleCreateNewDisease,
  handleAddNewDiagnosis,
  cancelNewDiagnosis,
  isPending,
  handleCancelEdit,
}) => {
  const { notes, isDisabled } = state;

  const val = status === "disease" ? disease : disease.toUpperCase();
  const isDisease = status === "disease";
  const isMissingDisease = allCodes.filter((item) => item.code === "");
  const newDiagnosis = (
    <div className="mt-2">
      <ResizableTextarea value={disease} rows={2} onChange={retrieveAllCodes} />
      <RightAlignedContainer>
        <button
          type="button"
          onClick={handleAddNewDiagnosis}
          className="btn btn-sm small-btn sms-info-btn mr-2 mt-2"
        >
          Add
        </button>
        <button
          type="button"
          onClick={cancelNewDiagnosis}
          className="btn btn-sm small-btn sms-gray-btn mr-2 mt-2"
        >
          Cancel
        </button>
      </RightAlignedContainer>
    </div>
  );

  const newDisease = (
    <ul>
      {allCodes.map((val, idx) => {
        const missingDisease = (
          <p style={{ margin: "0.65rem 0" }}>
            No results found for <strong>{`"${disease}"`}</strong>
            <span
              style={{
                display: "inline-block",
                textDecoration: "underline",
                color: colors.blue,
                marginLeft: "5px",
              }}
            >
              Create {disease}
            </span>
          </p>
        );
        return (
          <li
            style={{ listStyle: "none" }}
            key={idx}
            value={val.code}
            className="drug-li"
            data-testid="lab-tests"
            onClick={handleCreateNewDisease}
          >
            {missingDisease}
          </li>
        );
      })}
    </ul>
  );
  return (
    <form autoComplete="off" onSubmit={handleSubmitNotes} className="mt-2">
      {submitted && !(notes || disease) && (
        <div className="alert alert-danger text-center">
          Please enter notes or diagnosis to proceed
        </div>
      )}
      <div className="row">
        <div className="col-md-10 searchable">
          <Form.Group as={Row}>
            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
              <Label name="Search by:" />
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <Form.Check
                inline
                value="disease"
                onChange={handleChangeStatus}
                name="status"
                label="Disease"
                type="radio"
                checked={status === "disease"}
                className="form__label"
              />
              <Form.Check
                inline
                value="code"
                onChange={handleChangeStatus}
                name="status"
                label="ICD code"
                type="radio"
                className="form__label"
              />
            </Col>
            <Col xs={11} sm={11} md={11} lg={5} xl={5}>
              {isNew ? (
                newDiagnosis
              ) : (
                <>
                  <Form.Control
                    value={disease ? val : ""}
                    type="text"
                    disabled={isDisabled}
                    name="disease_name"
                    data-testid="disease_name"
                    className={`form__control  `}
                    onChange={retrieveAllCodes}
                    placeholder={`${
                      status === "disease" ? "Enter keyword" : "Enter ICD Code"
                    }`}
                  />
                  {error && (
                    <div className="help-block">{`Please enter a valid ${
                      status === "disease" ? "keyword" : "code"
                    }`}</div>
                  )}
                </>
              )}
              {showDiagnosis && (
                <>
                  {isMissingDisease.length > 0 ? (
                    newDisease
                  ) : (
                    <Form.Control
                      as="select"
                      className="drug-ul diagnosis-ul"
                      multiple
                    >
                      {allCodes.map((val, idx) => {
                        return (
                          <option
                            key={idx}
                            value={val.code}
                            className="drug-li"
                            data-testid="lab-tests"
                            onClick={() =>
                              getDetails({
                                value: val.code,
                                innerText: val.diagnosis,
                              })
                            }
                          >{`${val.code} - ${titleCase(
                            val.diagnosis
                          )}`}</option>
                        );
                      })}
                    </Form.Control>
                  )}
                </>
              )}
            </Col>
            {!isNew ? (
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                <ToolTip
                  toolTip={
                    isDisease
                      ? "Enter disease name according to the ICD Standard"
                      : "Enter ICD code"
                  }
                />
              </Col>
            ) : null}
          </Form.Group>
        </div>
      </div>
      <div className="table-responsive">
        <table
          className={`table table-sm table-bordered billing-invoice-table procedure-table`}
        >
          <thead>
            <th>Disease</th>
            <th>ICD code</th>
            <th width="80px">Action</th>
          </thead>
          <tbody>
            {codes
              .filter((item) => item.diagnosis)
              .map((test, indx) => (
                <tr key={indx}>
                  <td>{titleCase(test.diagnosis)}</td>
                  <td>{test.code}</td>
                  <td>
                    <div className="btn-group">
                      {!isDisabled ? (
                        <button
                          onClick={() => removeRow(indx)}
                          type="button"
                          className="btn btn-sm sms-btn-dismiss"
                          data-testid="remove-test-name"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Form.Group>
        <FormLabel
          className="pl-0"
          name="Diagnosis Notes"
          isRequired
          toolTip="Capture additional diagnosis notes here"
        />
        <ResizableTextarea
          value={notes}
          name="notes"
          onChange={handleChangeState}
          disabled={isDisabled}
          id="notes"
        />
      </Form.Group>

      <button
        type="submit"
        disabled={isPending}
        className="btn sms-btn btn-sm small-btn mt-2 mr-3"
      >
        {isPending ? "Saving..." : "Save"}
      </button>
      <button
        type="button"
        onClick={handleCancelEdit}
        className="btn sms-gray-btn small-btn mt-2 "
      >
        Cancel
      </button>
    </form>
  );
};

export const PreviousTriage = ({
  patientTriage,
  isEditTriage,
  handleEditTriage,
  handleChangeValue,
  handleCancelEditTriage,
  handleSubmitTriage,
  isPending,
}) => {
  const { metrics_titles, percentage_metrics } =
    GetTriageDetails(patientTriage);

  return patientTriage.length > 0 ? (
    <div className="table-responsive">
      <table css={[table, borderedTriage]}>
        <thead>
          <tr>
            <td rowSpan={2} style={{ textAlign: "center", padding: "5px" }}>
              <strong>Date</strong>
            </td>
            <td
              colSpan={metrics_titles.length}
              style={{ textAlign: "center", padding: "5px" }}
            >
              <strong>Metrics</strong>
            </td>
            <td
              className="print_button"
              rowSpan={2}
              style={{ textAlign: "center", padding: "2px" }}
            >
              <strong>Action</strong>
            </td>
          </tr>
          <tr>
            {metrics_titles.map((it) => (
              <td
                key={it.metric_id}
                style={{ textAlign: "center", padding: "5px" }}
              >
                <span style={{ margin: "3px" }}>
                  <strong>{`${it.metric_name} ${
                    it.uom ? `(${it.uom})` : ""
                  }`}</strong>
                </span>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {patientTriage.map((element, index) => {
            const saveButtons = (
              <>
                <button
                  type="submit"
                  className="btn btn-sm sms-btn small-btn mr-3"
                  onClick={() => handleSubmitTriage(element.data, index)}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  {isPending ? "Saving..." : "Save"}
                </button>
                <button
                  type="submit"
                  className="btn btn-sm sms-gray-btn small-btn"
                  onClick={() => handleCancelEditTriage(index)}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  Cancel
                </button>
              </>
            );
            return (
              <tr key={index}>
                <td style={{ textAlign: "center", padding: "5px" }}>
                  <span style={{ margin: "3px" }}>
                    {convertDateStrokeTime(element.metric_date)}
                  </span>
                </td>
                {metrics_titles.map((it, idx) =>
                  getMetricValues(
                    element.data,
                    it.metric_name,
                    percentage_metrics,
                    element.isEdit,
                    handleChangeValue,
                    index
                  )
                )}
                <td
                  className="print_button"
                  style={{ textAlign: "center", padding: "2px" }}
                >
                  {element.isEdit ? (
                    saveButtons
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-sm sms-amber-btn small-btn "
                      onClick={() => handleEditTriage(index)}
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <p>There was no triage taken for this patient</p>
  );
};

export const PreviousAntenatal = ({ antenatal_data, chronic_illness_dt }) => {
  const antenatal = Object.keys(antenatal_data);
  return antenatal.length > 0 ? (
    <DisplayAnteNatalDetails
      antenatal_data={antenatal_data}
      chronic_illness_dt={chronic_illness_dt}
    />
  ) : (
    <p>There is no antenatal visit to display for this patient</p>
  );
};

export const PreviousClinicalNotes = ({
  clinicalNotes,
  visit_id,
  handleOpen,
}) => {
  const headData = [{ name: "Date" }, { name: "Written By" }];
  return clinicalNotes.length > 0 ? (
    <PreviousNotesTable headData={headData}>
      {clinicalNotes.map((item) => {
        const viewNotes = (
          <div className="row mb-0">
            <div className="col-lg-6">
              {item.complaints ? (
                <>
                  <Label name="Presenting complaints" />
                  <SavedNotesContainer data-testid="p_complaints">
                    {splitNotes(item.complaints)}
                  </SavedNotesContainer>
                </>
              ) : null}
              {item.past_medical_history ? (
                <>
                  <Label name="Past medical history" />
                  <SavedNotesContainer data-testid="medical_history">
                    {splitNotes(item.past_medical_history)}
                  </SavedNotesContainer>
                </>
              ) : null}
              {item.physical_examination ? (
                <>
                  <Label name="Physical examination" />
                  <SavedNotesContainer data-testid="p_examination">
                    {splitNotes(item.physical_examination)}
                  </SavedNotesContainer>
                </>
              ) : null}
            </div>
            <div className="col-lg-6">
              {item.past_obstetric_history ? (
                <>
                  <Label name="Past obstetric history" />
                  <SavedNotesContainer data-testid="obs_history">
                    {splitNotes(item.past_obstetric_history)}
                  </SavedNotesContainer>
                </>
              ) : null}
              {item.family_history ? (
                <>
                  <Label name="Family history" />
                  <SavedNotesContainer data-testid="f_history">
                    {splitNotes(item.family_history)}
                  </SavedNotesContainer>
                </>
              ) : null}
              {item.notes || item.findings ? (
                <>
                  <Label name="Notes" />
                  <SavedNotesContainer data-testid="clinical_notes">
                    {splitNotes(item.notes || item.findings)}
                  </SavedNotesContainer>
                </>
              ) : null}
            </div>
          </div>
        );
        return (
          <NotesRow
            open={item.openNote}
            date={item.start_time ? formatDateTime(item.start_time) : ""}
            handleOpen={() => handleOpen(visit_id, item.examination_notes_id)}
            person={`${item.doctor_firstname} ${item.doctor_lastname}`}
            colSpan={headData.length}
          >
            {viewNotes}
          </NotesRow>
        );
      })}
    </PreviousNotesTable>
  ) : (
    <p>There are no clinical notes to display for this patient</p>
  );
};

export const PreviousLabResults = ({
  labResults,
  visit_id,
  handleOpenResults,
  isDoctor,
}) => {
  return labResults?.length > 0 ? (
    <>
      <ResultsHeading>
        <Heading>Lab Results</Heading>
        <Border />
      </ResultsHeading>
      {labResults.map((item, index) => {
        return (
          <LabResultsTable
            item={item}
            key={index}
            handleOpenItem={() =>
              handleOpenResults(visit_id, index, labResults, "labResults")
            }
            isDoctor={isDoctor}
          />
        );
      })}
    </>
  ) : (
    <p>There are no lab results to display for this patient</p>
  );
};

export const PreviousRadiology = ({
  radiologyResults,
  visit_id,
  handleOpenResults,
  isDoctor,
}) => {
  return radiologyResults?.length > 0 ? (
    <>
      <ResultsHeading>
        <Heading>Radiology Report(s)</Heading>
        <Border />
      </ResultsHeading>
      {radiologyResults.map((item, index) => {
        return (
          <RadiologyResultReport
            isDoctor={isDoctor}
            item={item}
            key={index}
            handleOpenItem={() =>
              handleOpenResults(
                visit_id,
                index,
                radiologyResults,
                "radiologyResults"
              )
            }
          />
        );
      })}
    </>
  ) : (
    <p>There are no radiology reports to display for this patient</p>
  );
};

export const PreviousDiagnosis = ({ patientDiagnosis, editDiagnosis }) => {
  const diagnosis_arr = patientDiagnosis
    .filter((item) => item.notes)
    .map((item) => item.notes);
  const diag_notes = longestString(diagnosis_arr);
  return patientDiagnosis?.length > 0 ? (
    <>
      <ResultsHeading>
        <Heading>Diagnosis</Heading>
        <Border />
      </ResultsHeading>
      {editDiagnosis}
      <table css={[table, detailsTable, tableBordered, paddingCell]}>
        <tr>
          <td>
            <span>
              <strong>ICD code</strong>
            </span>
          </td>
          <td>
            <span>
              <strong>Disease</strong>
            </span>
          </td>
        </tr>

        {patientDiagnosis
          .filter(
            (item) => !(item.diagnosis === null && item.diagnosis_code === null)
          )
          .map((item) => (
            <tr key={item.diagnosis_id}>
              <td>
                <span>{item.diagnosis_code}</span>
              </td>
              <td>
                <span>{item.diagnosis}</span>
              </td>
            </tr>
          ))}
        <tr>
          <td colSpan={2}>
            <span>
              <strong>Diagnosis Notes</strong>
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>{splitNotes(diag_notes)}</td>
        </tr>
      </table>
    </>
  ) : (
    <p>There was no diagnosis that was recorded for this patient</p>
  );
};

export const PreviousProcedure = ({ procedure }) => {
  const headData = [
    { key: "service_name", value: "Procedure" },
    { key: "quantity", value: "Quantity" },
  ];
  return procedure?.length > 0 ? (
    <>
      <ResultsHeading>
        <Heading>Procedure(s)</Heading>
        <Border />
      </ResultsHeading>
      <ItemsTable headData={headData.filter((item) => Boolean(item))}>
        {procedure.map((item, index) => (
          <tr key={index}>
            <td>
              <span>{item.procedure_name}</span>
            </td>
            <td>
              <span>{item.quantity}</span>
            </td>
          </tr>
        ))}
      </ItemsTable>
    </>
  ) : (
    <p>There are no procedures performed for this patient</p>
  );
};

export const PreviousPrescription = ({ medication }) => {
  const { subscription } = useVersionsContext();
  const version = subscription?.version;
  const isPremium = version === "Premium";
  const headData = [
    { key: "drug_name", value: "Drug Name" },
    isPremium ? { key: "quantity", value: "Quantity to bill" } : null,
    { key: "quantity_prescribed", value: "Quantity Prescribed" },
    { key: "dosage", value: "Dosage" },
    { key: "frequency", value: "Frequency" },
    { key: "number_of_days", value: "Number of Days" },
    { key: "instructions", value: "Instructions" },
    { key: "status", value: "Status" },
    { key: "prescribed_by", value: "Prescribed by" },
    { key: "dispensed_by", value: "Dispensed by" },
  ];
  return medication?.length > 0 ? (
    <>
      <ResultsHeading>
        <Heading>Prescription</Heading>
        <Border />
      </ResultsHeading>
      <ItemsTable headData={headData.filter((item) => Boolean(item))}>
        {medication.map((item, index) => {
          const prescribed_by = `${
            item.prescribed_by_first_name ? item.prescribed_by_first_name : ""
          } ${
            item.prescribed_by_last_name ? item.prescribed_by_last_name : ""
          }`;
          const dispensed_by = `${
            item.dispensed_by_frist_name ? item.dispensed_by_frist_name : ""
          } ${item.dispensed_by_last_name ? item.dispensed_by_last_name : ""}`;
          const outPatientStatus = {
            0: <PendingStatus>Pending</PendingStatus>,
            1: <Processing>Paid</Processing>,
            2: <FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
            3: <PendingStatus>Pending</PendingStatus>,
            4: <FinishedStatus>Dispensed</FinishedStatus>,
            7: <ExpiredStatus>Stopped</ExpiredStatus>,
          };

          const inPatientStatus = {
            0: <PendingStatus>Pending</PendingStatus>,
            1: <Processing>Pending</Processing>,
            3: <PendingStatus>Pending</PendingStatus>,
            2: <FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
            4: <FinishedStatus>Dispensed</FinishedStatus>,
            7: <ExpiredStatus>Stopped</ExpiredStatus>,
          };

          const statusItem =
            item.patient_type === 1
              ? outPatientStatus[item.status]
              : inPatientStatus[item.status];
          return (
            <tr key={index}>
              <td>
                <span>{item.product_name || item.other_medicine}</span>
              </td>
              {isPremium ? (
                <td>
                  <span>{item.quantity}</span>
                </td>
              ) : null}
              <td>
                <span>{item.quantity_prescribed || item.quantity}</span>
              </td>
              <td>
                <span>{item.dosage}</span>
              </td>
              <td>
                <span>{item.frequency}</span>
              </td>
              <td>
                <span>{item.number_of_days}</span>
              </td>
              <td>
                <span>{item.key_information}</span>
              </td>
              <td>
                <span>{statusItem}</span>
              </td>
              <td>
                <span>{prescribed_by}</span>
              </td>
              <td>
                <span>{dispensed_by}</span>
              </td>
            </tr>
          );
        })}
      </ItemsTable>
    </>
  ) : (
    <p>There are no drugs prescribed for this patient</p>
  );
};

export const VisitsList = ({
  visits,
  handleOpenVisit,
  handleClickComponent,
  handleOpenPrint,
  handleOpenResults,
  handleOpenNote,
}) => {
  const classes = useStyles();
  return visits.map((item, index) => {
    const type = {
      1: "OPD Visit",
      2: "IPD Visit",
    };
    const visitTitle = (
      <>
        <VisitType>{type[item.patient_type]}</VisitType>
        <VisitDate>
          Date:{" "}
          <strong>
            {item.begin_datetime ? formatDateTime(item.begin_datetime) : ""}
          </strong>
        </VisitDate>
      </>
    );

    const tab = {
      triage: <PreviousTriage patientTriage={item?.patientTriage} />,
      notes: (
        <PreviousClinicalNotes
          clinicalNotes={item?.clinicalNotes}
          visit_id={item.visit_id}
          handleOpen={handleOpenNote}
        />
      ),
      procedure: <PreviousProcedure procedure={item?.procedure} />,
      lab: (
        <PreviousLabResults
          handleOpenResults={handleOpenResults}
          labResults={item?.labResults}
          visit_id={item.visit_id}
          isDoctor={true}
        />
      ),
      radiology: (
        <PreviousRadiology
          radiologyResults={item?.radiologyResults}
          handleOpenResults={handleOpenResults}
          visit_id={item.visit_id}
        />
      ),
      diagnosis: (
        <PreviousDiagnosis patientDiagnosis={item?.patientDiagnosis} />
      ),
      prescription: <PreviousPrescription medication={item?.medication} />,
      antenatal: (
        <PreviousAntenatal
          chronic_illness_dt={item?.chronic_illness_dt}
          antenatal_data={item?.antenatal_data}
        />
      ),
    };

    return (
      <PreviousVisitContainer
        open={item.open}
        key={item.visit_id}
        name={visitTitle}
        idx={index}
        handleOpen={() => handleOpenVisit(item.visit_id)}
        button={
          <Tooltip title="Print">
            <button
              onClick={() => handleOpenPrint(item)}
              className="btn btn-sm sms-amber-btn"
            >
              Print
            </button>
          </Tooltip>
        }
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            md={3}
            sm={2}
            lg={2}
            classes={{ item: `${classes.item} ${classes.tabItem}` }}
          >
            {item?.components
              ?.filter((comp) => Boolean(comp))
              .map((comp, idx) => (
                <PreviousHistoryTabs
                  item={comp}
                  key={idx}
                  handleClick={() =>
                    handleClickComponent(idx, comp.component, item.visit_id)
                  }
                />
              ))}
          </Grid>
          <Grid
            item
            sm={10}
            xs={6}
            md={9}
            lg={10}
            classes={{ item: classes.item2 }}
          >
            <div className="p-3">{tab[item?.activeItem]}</div>
          </Grid>
        </Grid>
      </PreviousVisitContainer>
    );
  });
};

export const SelectForm = ({
  options,
  option,
  handleChangeOption,
  handlePrint,
}) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <Form as={Row}>
          <Label name="Select" sm={2} column style={{ textAlign: "right" }} />
          <Col sm={10}>
            <CustomSelect
              value={option}
              options={options}
              onChange={handleChangeOption}
              isMulti
            />
          </Col>
        </Form>
      </div>
      <div className="col-md-6">
        <RightAlignedContainer>
          <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
            Print
          </button>
        </RightAlignedContainer>
      </div>
    </div>
  );
};

export const PatientPreviousVisitsSurgery = ({ actions, patient_number }) => {
  const {
    visits,
    patientInfo,
    handleOpenVisit,
    handleClickComponent,
    options,
    option,
    handleChangeOption,
    handleOpenResults,
    visitDetails,
    openPrint,
    handleOpenPrint,
    handleClosePrint,
    handleOpenNote,
  } = useOutPatientMedicalHistory(actions, patient_number);

  const { componentRef, handlePrint, fontSize } = usePrint(
    "Medical History Report"
  );

  const { patientDiagnosis, patientTriage, clinicalNotes } = visitDetails;

  const triageComponent =
    patientTriage?.length > 0 ? (
      <>
        <ResultsHeading>
          <Heading>Triage</Heading>
          <Border />
        </ResultsHeading>
        <PreviousTriage {...{ patientTriage }} />
      </>
    ) : null;

  const clinicalNotesComponent =
    clinicalNotes?.length > 0 ? (
      <Spacer>
        <ResultsHeading>
          <Heading>Clinical Notes</Heading>
          <Border />
        </ResultsHeading>
        <PreviousClinicalNotes
          {...{ clinicalNotes, visit_id: "", handleOpen: handleOpenNote }}
        />
      </Spacer>
    ) : null;

  const diagnosisComponent =
    patientDiagnosis?.length > 0 ? (
      <Spacer>
        <PreviousDiagnosis {...{ patientDiagnosis }} />
      </Spacer>
    ) : null;

  return (
    <div className="journals">
      <CustomDialog
        open={openPrint}
        handleClose={handleClosePrint}
        title="Print Preview"
        maxWidth="lg"
      >
        <SelectForm {...{ options, option, handleChangeOption, handlePrint }} />
        <div ref={componentRef}>
          {fontSize}
          <ReusableTreatmentHistory
            {...{
              patient_number,
              patientInfo,
              treatmentDetails: visitDetails,
              option,
              handleOpenResults,
              diagnosisComponent,
              triageComponent,
              clinicalNotesComponent,
            }}
          />
        </div>
      </CustomDialog>
      <Container>
        <Heading variant="h4">Previous Visits</Heading>
        <VisitsList
          {...{
            visits,
            handleOpenVisit,
            handleClickComponent,
            handleOpenPrint,
            handleOpenResults,
            handleOpenNote,
          }}
        />
      </Container>
    </div>
  );
};

export const ViewPatientVisitHistory = ({
  actions,
  patient_number,
  visit_id,
}) => {
  const {
    patientInfo,
    options,
    option,
    handleChangeOption,
    visitDetails,
    handleOpenResults,
    handleOpenNote,
  } = useOutPatientMedicalHistory(actions, patient_number, visit_id);
  const { componentRef, handlePrint, fontSize } = usePrint(
    "Medical Visit Report"
  );

  const {
    patientDiagnosis,
    patientTriage,
    clinicalNotes,
    dental_services,
    examination,
  } = visitDetails;

  const triageComponent =
    patientTriage?.length > 0 ? (
      <>
        <ResultsHeading>
          <Heading>Triage</Heading>
          <Border />
        </ResultsHeading>
        <PreviousTriage {...{ patientTriage }} />
      </>
    ) : null;

  const clinicalNotesComponent =
    clinicalNotes?.length > 0 ? (
      <Spacer>
        <ResultsHeading>
          <Heading>Clinical Notes</Heading>
          <Border />
        </ResultsHeading>
        <PreviousClinicalNotes
          {...{ clinicalNotes, visit_id, handleOpen: handleOpenNote }}
        />
      </Spacer>
    ) : null;

  const diagnosisComponent =
    patientDiagnosis?.length > 0 ? (
      <Spacer>
        <PreviousDiagnosis {...{ patientDiagnosis }} />
      </Spacer>
    ) : null;
  const dentalObj = examination?.dental_exam ? examination?.dental_exam : {};

  const isDental = Object.keys(dentalObj);

  const dentalHistory = (
    <DentalExaminationReport {...{ patient_number, ...examination }}>
      <DentalServiceResults
        {...{
          dental_services,
          title:
            dental_services.length > 0 ? (
              <thead>
                <tr>
                  <td>
                    <span css={[heading, textWeight]}>Services performed</span>
                  </td>
                </tr>
              </thead>
            ) : (
              ""
            ),
        }}
      />
    </DentalExaminationReport>
  );

  return isDental.length > 0 ? (
    dentalHistory
  ) : (
    <>
      <SelectForm {...{ options, option, handleChangeOption, handlePrint }} />
      <div ref={componentRef}>
        {fontSize}
        <ReusableTreatmentHistory
          {...{
            patient_number,
            patientInfo,
            treatmentDetails: visitDetails,
            option,
            diagnosisComponent,
            handleOpenResults,
            clinicalNotesComponent,
            triageComponent,
          }}
        />
      </div>
    </>
  );
};

const PatientPreviousVisits = ({ actions, match }) => {
  const {
    patient_number,
    labrequest_id,
    scan_request_id,
    visit_id,
    patient_type,
    patient_admission_id,
    activity_id,
  } = match.params;

  const {
    visits,
    patientInfo,
    handleOpenVisit,
    handleClickComponent,
    options,
    option,
    handleChangeOption,
    handleOpenResults,
    visitDetails,
    openPrint,
    handleOpenPrint,
    handleClosePrint,
    handleOpenNote,
  } = useOutPatientMedicalHistory(actions, patient_number);

  const arr = doctorRoutes(
    patient_number,
    visit_id,
    patient_type,
    patient_admission_id
  );

  const { componentRef, handlePrint, fontSize } = usePrint(
    "Medical History Report"
  );

  // get the doctor's name
  const user = JSON.parse(sessionStorage.getItem("user"));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${
    !_token.last_name ? "" : _token.last_name
  }`;

  const { patientDiagnosis, patientTriage, clinicalNotes } = visitDetails;

  const triageComponent =
    patientTriage?.length > 0 ? (
      <>
        <ResultsHeading>
          <Heading>Triage</Heading>
          <Border />
        </ResultsHeading>
        <PreviousTriage {...{ patientTriage }} />
      </>
    ) : null;

  const clinicalNotesComponent =
    clinicalNotes?.length > 0 ? (
      <Spacer>
        <ResultsHeading>
          <Heading>Clinical Notes</Heading>
          <Border />
        </ResultsHeading>
        <PreviousClinicalNotes
          {...{ clinicalNotes, visit_id, handleOpen: handleOpenNote }}
        />
      </Spacer>
    ) : null;

  const diagnosisComponent =
    patientDiagnosis?.length > 0 ? (
      <Spacer>
        <PreviousDiagnosis {...{ patientDiagnosis }} />
      </Spacer>
    ) : null;

  return (
    <div className="journals">
      <PageTitle
        title={`${
          patient_type === "1" ? "Medical History" : "Previous Rounds"
        }`}
      />
      <SubHeader
        title="Doctor"
        subTitle={`${
          patient_type === "1" ? "Medical History" : "Previous Rounds"
        }`}
        doctor_name={doctor_name}
      >
        <FontAwesomeIcon icon={faUserMd} />
      </SubHeader>
      <CustomDialog
        open={openPrint}
        handleClose={handleClosePrint}
        title="Print Preview"
        maxWidth="lg"
        isContentOverflow={true}
        isPaperStyle={true}
      >
        <PatientMedicalHistory actions={actions} visit={{patient_number, visit_id}}/>
        {/*<SelectForm {...{ options, option, handleChangeOption, handlePrint }} />*/}
        {/*<div ref={componentRef}>*/}
        {/*  {fontSize}*/}
        {/*  <ReusableTreatmentHistory*/}
        {/*    {...{*/}
        {/*      patient_type,*/}
        {/*      patient_number,*/}
        {/*      patientInfo,*/}
        {/*      treatmentDetails: visitDetails,*/}
        {/*      option,*/}
        {/*      triageComponent,*/}
        {/*      clinicalNotesComponent,*/}
        {/*      diagnosisComponent,*/}
        {/*      handleOpenResults,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </CustomDialog>
      <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
        <Container>
          <PatientInformation {...{ patient_number, visit_id }} />
          <Heading>Previous Visits</Heading>
          <Border />
          <VisitsList
            {...{
              visits,
              handleOpenVisit,
              handleClickComponent,
              handleOpenPrint,
              handleOpenResults,
              handleOpenNote,
            }}
          />
        </Container>
      </ReusableDoctorTabs>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbar } = state;
  return { snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientPreviousVisits);
