import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {useTheme} from "@material-ui/core/styles";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { ReusablePhoneInput } from "../Utils/FormInputs/FormGroup";
import { ErrorMessage } from "../Utils/styledComponents";
import TextField from "../Utils/FormInputs/TextField";
import { dateConvert, isNotFutureDate } from "../Utils/ConvertDate";


export const EmployeeForm = ({state, department_id, submitted, image, ninImage, handleChangeDepartment,
    handleChange, handleChangePicture, handleChangeNinPicture, handleSubmitForm,
     blood_group,handleChangeBloodGroup, employeeContact,
    kinContact,setKinContact,setEmployeeContact, departs, isSubmitted,  }) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
 
    const selected_date = isNotFutureDate(state.dob)
    const max_date = dateConvert()
    return (
        <div>
        <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Personal Information</h6>
        <form onSubmit={handleSubmitForm} autoComplete="off">
            <div className="row" id='_employee-info'>
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="First Name" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField onChange={handleChange}
                                       submitted={submitted}
                                       name="first_name" value={state.first_name } type="text"
                                       id="employee_first_name"
                            />
                            {submitted && !state.first_name ? <ErrorMessage>First name is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Last Name" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField onChange={handleChange}
                                       submitted={submitted}
                                       name="last_name" value={state.last_name} type="text"
                                       id="employee_last_name"
                            />
                            {submitted && !state.last_name ? <ErrorMessage>Last name is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="D.O.B" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField  onChange={handleChange}
                                        submitted={submitted}
                                        name="dob" value={state.dob} type="date"
                                        id="employee_dob"
                                        max={max_date}
                            />
                            {submitted && !state.dob ? <ErrorMessage>Date of birth is required</ErrorMessage>: state.dob && selected_date ? <ErrorMessage>D.O.B should not be in the future</ErrorMessage>:null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Gender" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Form.Check type="radio" inline label="Female" name="gender" value="female"
                                        checked={state.gender === "female"} id="female" onChange={handleChange}/>
                            <Form.Check type="radio" inline label="Male" id="male" name="gender"
                                        value="male"
                                        checked={state.gender === "male"} onChange={handleChange}/>
                            {(submitted && !state.gender) &&
                                <ErrorMessage id="gender">Gender is required</ErrorMessage>}
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        controlId="formHorizontalName"
                        id="employee_phone"
                        className="FormField">
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Phone No."/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <ReusablePhoneInput submitted={submitted} required contact={employeeContact} setContact={setEmployeeContact}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Email" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField  onChange={handleChange}
                                        submitted={submitted}
                                        name="email" value={state.email} type="email"
                                        id="employee_email"
                            />
                            {submitted && !state.email ? <ErrorMessage>Email is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Address" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <TextField onChange={handleChange} submitted={submitted}
                                       name="address" value={state.address} type="text" labelName="Address"
                                       id="employee_address"
                            />
                            {submitted && !state.address ? <ErrorMessage>Address is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>


                </div>
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Department" type />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomSelect {...{submitted}} onChange={handleChangeDepartment}
                                          id='employee_department'
                                          value={department_id}  options={departs.map(department => ({
                                value: department.department_id, label: department.department_name
                            }))} dataTest="department"/>
                            {(submitted && !department_id?.value) &&
                                <ErrorMessage>Department is required</ErrorMessage>}

                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="National ID"  />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField  onChange={handleChange}
                                        name="nin" value={(state.nin).toUpperCase()} type="text"
                                        id="employee_nin"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Picture"/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <Form.Control type="file" id="employee_picture"
                                          data-value={image} onChange={handleChangePicture}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Specialization"  />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <TextField  onChange={handleChange}
                                        name="specialisation" value={state.specialisation} type="text"
                                        labelName="Specialization"
                                        id="employee_spec"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        controlId="formHorizontalName"
                        className="FormField"
                    >
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Blood Group"  />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <CustomSelect
                                onChange={handleChangeBloodGroup}
                                value={blood_group}
                                id="employee_bg"
                                options={[
                                    { label: "A+", value: "A+" },
                                    { label: "A-", value: "A-" },
                                    { label: "B+", value: "B+" },
                                    { label: "B-", value: "B-" },
                                    { label: "O+", value: "O+" },
                                    { label: "O-", value: "O-" },
                                    { label: "AB+", value: "AB+" },
                                    { label: "AB-", value: "AB-" },
                                ]}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="NIN Picture"/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <Form.Control type="file" id="ninPicture"
                                          data-value={ninImage} onChange={handleChangeNinPicture}/>
                        </Col>
                    </Form.Group>


                </div>
            </div>
            <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Next of Kin Information</h6>
            <div className="row" id='_employee-kin'>
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="First Name" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField onChange={handleChange}
                                       name="kin_first_name" value={state.kin_first_name} type="text"
                                       id="kin_first_name"
                                      />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Last Name" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField onChange={handleChange}
                                       name="kin_last_name" value={state.kin_last_name} type="text"
                                       labelName="Last Name"
                                       id="kin_last_name"
                            />
                        </Col>
                    </Form.Group>


                    <Form.Group
                        as={Row}
                        controlId="formHorizontalName"
                        className="FormField">
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Phone No."/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <ReusablePhoneInput contact={kinContact} setContact={setKinContact}/>
                        </Col>
                    </Form.Group>


                </div>
                <div className="col-md-6">

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Address" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <TextField onChange={handleChange}
                                       name="kin_address" value={state.kin_address} type="text"
                                       id="kin_address"
                                      />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Relationship" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField onChange={handleChange}
                                       name="kin_relationship" value={state.kin_relationship} type="text"
                                       id="kin_relationship"
                            />
                        </Col>
                    </Form.Group>
                </div>
            </div>
            <div className="text-right">

                <button type="submit" disabled={isSubmitted ==='pending' } id="submit_employee" className="btn btn-sm sms-btn px-4">
                    {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
            </div>
        </form>
        </div>
    )
};



export default EmployeeForm;
