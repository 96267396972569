import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import * as snackbarActions from "../../actions/snackbarActions";
import ReusableDoctorTabs, {doctorRoutes} from "../Utils/Menu/ReusableDoctorTabs";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import { ReusableTreatmentHistory, Spacer } from './ReusableTreatmentHistory';
import axios from "axios";
import { config } from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {Container} from "../Utils/styledComponents"
import {usePrint} from "../Utils/Templates/usePrint";
import { ResultsHeading, PreviousTriage, PreviousClinicalNotes,
    PreviousDiagnosis, Heading, Border,SelectForm } from "./PatientPreviousVisits";
import {PatientMedicalHistory} from "./PatientHistory/PatientMedicalHistory";

const TreatmentDetails = ({actions, match}) => {

    const options = [{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
        {label:'Procedure', value:'procedure'},
        {label:'Laboratory Result(s)', value:'lab'},{label:'Radiology Report(s)', value:'radiology'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}]

    const {patient_number, visit_id,  patient_type, patient_admission_id} = match.params;
    const components = doctorRoutes(patient_number, visit_id, patient_type, patient_admission_id)

    const [treatmentDetails, setTreatmentDetails] = useState({labResults:[],radiologyResults:[], patientDiagnosis:[],
        medication:[], clinicalNotes:[], patientTriage:[], visit_date:'', visit_type:''})

    const [patient, setPatient] = useState({});

    const [option, setOption] = useState([{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}])





    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);


    const handleChangeOption = (value) =>{
        setOption(value)
    }

    useEffect(() => {
        if(!visit_id){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage,
                visit_detail} = details
            const presc = prescription ? prescription : [];
            const others = other_medicine ? other_medicine : [];
            const other_meds = others.filter(item=>Boolean(item));
            const medicationArr = [...presc, ...other_meds];
            const labArr = lab_result?.map(item=>({...item, open:true}))
            const scanArr = scan_result?.map(item=>({...item, open:true}))
            const arr = {
                labResults: labArr,
                radiologyResults: scanArr,
                patientDiagnosis: diagnosis,
                medication: medicationArr,
                patientTriage: triage,
                clinicalNotes: exam_notes.map(note=>({
                    ...note, openNote:true
                })),
                visit_type : parseInt(visit_detail['visit_type']),
                visit_date: visit_detail['begin_datetime'],
            }

            setTreatmentDetails(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [visit_id]);


    const handleOpenNote = (v_id,notes_id) => {
        const obj = {...treatmentDetails,  clinicalNotes: treatmentDetails?.clinicalNotes?.map((note, idx)=>{
                if (note.examination_notes_id === notes_id){
                    return {...note, openNote:!note.openNote}
                }
                return note
            })
        }
        setTreatmentDetails(obj)
    }

    const handleOpenResults = (visit_id,index,arr,item_name) =>{
        const openedArr = arr.map((child, idx)=>{
            if(idx === index){
                return {...child, open:!child.open}
            }else {
                return child
            }
        })
        const obj = {...treatmentDetails, [item_name]:openedArr}
        setTreatmentDetails(obj)
    }



    // get the doctor's name
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;   

    const {first_name, last_name, Age, address, gender, phone_no} = patient

    const patientInfo = {patient_name:`${first_name} ${last_name}`, age:Age, gender, phone:phone_no, address}

    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');

    const {patientDiagnosis, patientTriage, clinicalNotes} = treatmentDetails

    const triageComponent = (
        patientTriage?.length > 0 ?  <>
                <ResultsHeading>
                    <Heading>Triage</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousTriage {...{patientTriage}}/>
            </>: null
    )

    const clinicalNotesComponent = (
        clinicalNotes?.length > 0 ?
                <Spacer>
                    <ResultsHeading>
                        <Heading>Clinical Notes</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousClinicalNotes {...{clinicalNotes, visit_id, handleOpen:handleOpenNote}}/>
                </Spacer>:  null
    )

    const diagnosisComponent = (
        patientDiagnosis?.length > 0 ?
                <Spacer>
                    <PreviousDiagnosis {...{patientDiagnosis}} />
                </Spacer>
                : null
    )

    return (
        <div className='journals'>
            <PageTitle title="Treatment Details"/>
            <SubHeader title="Doctor" subTitle="Treatment Details"  doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <ReusableDoctorTabs components={components} patient_type={+patient_type}>
                <Container>
                    <PatientMedicalHistory actions={actions} visit={{patient_number, visit_id}}/>
                    {/*<SelectForm {...{options, option, handleChangeOption,handlePrint}}/>*/}
                    {/*<div ref={componentRef}>*/}
                    {/*    {fontSize}*/}
                    {/*<ReusableTreatmentHistory {...{patient_type, patient_number, patientInfo, treatmentDetails,*/}
                    {/*    handleOpenResults, visit_id, option, clinicalNotesComponent, diagnosisComponent, triageComponent}}/>*/}
                    {/*</div>*/}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentDetails);