import moment from 'moment'

function formatDateTime(date){
    return moment.utc(date).format('MMMM Do YYYY, h:mm a')
}

function formatLocalTime(date){
    return moment(date).format('MMMM Do YYYY, h:mm a')
}

function today(){
    return moment().format('Do MMMM YYYY')
}

function todayTime(){
    return moment().format('MMMM Do YYYY, h:mm a')
}

// function formatTime(time) {
//     return moment(time).format('LT');

// }


function formatCurrentDate(date){
    return moment(date).format()
}

function formatDate(date) {
    return moment(date).format('Do MMMM YYYY')
}

function currentTime(){
    return moment().format('LT')
}

function dateTimeMinutesNow(){

    return moment().format('YYYY-MM-DDTHH:mm')
}

function formatTime(time){
    return moment(time).format('LT')
}

export {formatDateTime, today, formatCurrentDate,formatDate, todayTime, formatLocalTime, currentTime, formatTime, dateTimeMinutesNow};

export const convertDateStrokeTime = (date) => {
    const today = moment(date.slice(0,-3)).toDate();
    return ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + ('0' + today.getMinutes()).slice(-2);
}

export const convertDate = () => {
    let today = new Date();
    // return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate() ).slice(-2);
    return ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getFullYear();

};


export const validateChosenDOBDate = (date) => {
    if (date) {
        var d1 = new Date();
        var d2 = new Date(date);
        return (d2 > d1);
    }
    return false;
}

export const dateConvert = () => {
    let today = new Date();
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
};

export const convDate = (date) => {
    let today = new Date(date);
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
}

export const dateTimeConvert = () => {
    let today = new Date();
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T' + today.getHours() + ':' + ('0' + today.getMinutes()).slice(-2) + ':' + ('0' + today.getSeconds()).slice(-2);
};

export const currentDateTimeConvert = (date) => {
    let today = new Date(date);
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T' + today.getHours() + ':' + ('0' + today.getMinutes()).slice(-2);
};

export const gmtConvert = (date) => {
    let myDate = new Date(date);
    return new Date(myDate.toLocaleDateString());
}

export const dateStringConvert = (date) => {
    let myDate = new Date(date);
    return myDate.toDateString();
}

export const gmtDateConvert = (date) => {
    let today = new Date(date);
    return ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getFullYear()
}

export const dateStrokeConvert = (date) => {
    let dateObject = new Date(date);
// Extract the day, month, and year
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1; // getUTCMonth() returns month from 0 to 11
    const year = dateObject.getUTCFullYear();

// Format day and month with leading zero if needed
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

// Create the desired date format
    return `${formattedDay}/${formattedMonth}/${year}`;
}

export const convertGmtTime = (date) => {
    let today = new Date(date)
    const dt = new Date().toString();
    const arr = dt.split(' ');
    return ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear() + ' ' + arr.slice(4, 5).join(' ');
}

export const removeGmt =  (date) =>{
    const arr = date.split(' ');
    return arr.slice(0, 5).join(' ')
}

export const extractTime = (date) => {
    let time = new Date(date).toString();
    time = time.split(' ').slice(4, 5).join(' ');
    return time
}
export const extractDate = (date) => {
    return new Date(date).toDateString()
}

export const getCurrentDate = () => {
    let curr = new Date(); // get current date
    let first = curr.getDate(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    let firstDay = convDate(new Date(curr.setDate(first)));
    let lastDay = convDate(new Date(curr.setDate(last)));
   return {firstDay, lastDay}
}

export const convertMonth = (month) =>{
    const months = {'01':'January', '02':'February','03':'March','04':'April','05':'May',
    '06':'June', '07':'July','08':'August','09':'September','10':'October','11':'November','12':'December'};
    const mths = month.split('-')[1];
    const yr = month.split('-')[0];
    return `${months[mths]}, ${yr}`
}

export const convertMonthToString = (month) =>{
    const months = {'0':'January', '1':'February','2':'March','3':'April','4':'May',
    '5':'June', '6':'July','7':'August','8':'September','9':'October','10':'November','11':'December'};
    return `${months[month]}`
}

export const convertMonthToShortString = (month) =>{
    const months = {'0':'Jan', '1':'Feb','2':'Mar','3':'Apr','4':'May',
    '5':'Jun', '6':'Jul','7':'Aug','8':'Sep','9':'Oct','10':'Nov','11':'Dec'};
    return `${months[month]}`
}

export const getActualMonthValue = (month) => {
    const months = {'0':'01', '1':'02','2':'03','3':'04','4':'05',
    '5':'06', '6':'07','7':'08','8':'09','9':'10','10':'11','11':'12'};
    return `${months[month]}`
}

export const getActualDay = (day) => {
    const days = {1:'Monday', 2:'Tuesday', 3:'Wednesday', 4:'Thursday', 5:'Friday', 6:'Saturday', 0:'Sunday'}
    return `${days[day]}`
}

export const getCurrentYear = () =>{
    const year = new Date().getFullYear().toString()
    return  year.split('').slice(-2).join('')
}

function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export function isValidDate(dateString) {
    // Check if the input value is in yyyy-mm-dd format
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
        return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);


    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return false;
    }

    // Check the number of days in the month
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 2 && isLeapYear(year)) {
        if (day < 1 || day > 29) {
            return false;
        }
    } else {
        if (day < 1 || day > monthLength[month - 1]) {
            return false;
        }
    }

    return true;
}

export const isCurrentPastDate = (date) =>{
    const today =  new Date()
    const selected_date = new Date(date)
    return convDate(selected_date) <= convDate(today)
} 

export const isCurrentFutureDate = (date) =>{
    const today =  new Date()
    const selected_date = new Date(date)
    return convDate(selected_date) >= convDate(today)
} 

export const isNotPastDate = (date) =>{
    const today =  new Date()
    const selected_date = new Date(date)
    return convDate(selected_date) < convDate(today)
} 

export const isNotFutureDate = (date) =>{
    const today =  new Date()
    const selected_date = new Date(date)
    return convDate(selected_date) > convDate(today)
} 

export const isToday = (date) =>{
    const today =  new Date()
    const selected_date = new Date(date)
    return convDate(selected_date) === convDate(today)
}

export const isCurrentYear = (date) =>{
    const current_year = new Date().getFullYear()
    const selected_year = new Date(date).getFullYear()
    return selected_year === current_year
}

