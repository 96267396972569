/** @jsxImportSource @emotion/react */
import {ItemDetails, ReportList} from "../../Utils/Lists/ReportList";
import {splitConclusion, splitNotes} from "../../Radiology/RadiologyReport";
import React from "react";
import {detailsTable,  table, textWeight} from "../../../styles/tableStyles";
import {formatDateTime} from "../../Utils/ConvertDate";

export const RadiologyResultReport = ({item,handleOpenItem}) => {
    const {hospital_scan_name,  notes, conclusion, indication, doctor_first_name, doctor_last_name,
        radiologist_first_name, radiologist_last_name, date_created} = item;
    const doctor = `${doctor_first_name ? doctor_first_name :''} ${doctor_last_name ? doctor_last_name : ''}`
    const radiologist = `${radiologist_first_name ? radiologist_first_name : ''} ${radiologist_last_name ? radiologist_last_name : ''}`
    const itemDetails = (
        <ItemDetails approved_by={radiologist} requested_by={doctor} date_requested={date_created ? formatDateTime(date_created) : date_created}/>
    )
    return (
        <ReportList  open={item?.open} test_name={hospital_scan_name} handleOpen={handleOpenItem} item_details={itemDetails}>
            <table css={[table,detailsTable]}>
                <tbody>
                <tr>
                    <td ><span><span css={[textWeight]}>EXAMINATION: </span> {hospital_scan_name}</span></td>
                </tr>
                <tr>
                    <td  style={{paddingBottom:10}}><span><span css={[textWeight]}>INDICATION: </span>{indication ? indication : ''}</span></td>
                </tr>
                <tr/>
                <tr>
                    <td  style={{paddingBottom:0}}><span css={[textWeight]}>Findings</span></td>
                </tr>
                <tr>
                    <td style={{paddingBottom:20}}>{splitNotes(notes)}</td> {/*/ <- changed it to this to fix the pushing of text to the next page when printing. */}
                </tr>

                {conclusion ? <tr>
                    <td  style={{paddingBottom:10}}><span css={[textWeight]}>Conclusion</span></td>
                </tr> : null}
                {conclusion ? <tr>
                    <td  >{splitConclusion(conclusion)}</td>
                </tr>: null}
                <tr>
                    <td colSpan="2">
                        <div className="text-right radiology-findings">
                        </div>
                    </td>
                    <td/>
                </tr>
                </tbody>
            </table>
        </ReportList>

    )
};