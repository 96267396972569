import  {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {dateStringConvert, formatDate} from "../../../Utils/ConvertDate";
import {v4 as uuidv4} from "uuid";

export const useEditLabRequest = (actions, match, _token) => {
    const {visit_id,patient_type,previousRequest} = match
    const initialState = [{
        hospital_test_id: '', test_name: '', hospital_test_type: '',
        service_id: '', qty: 1, showTest: false, disabled: false, isError: false,
        labrequests_detail_id: '', bill_id: '', labrequest_id: '',  uuid: uuidv4(),
        isEdited: false,  cost: 0, date: '', invoice_no: '',status:0
    }]
    const [submitted, setSubmitted] = useState(false);
    const [tests, setTests] = useState(initialState);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [labTests, setLabTests] = useState([]);
    const [{date_created, labrequest_id}, setBillId] = useState({date_created: '', labrequest_id: ''});
    const [invoicesRemoved, setInvoicesRemoved] = useState([]);
    const [openEdit, setOpenEdit] = useState(false)
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    useEffect(() => {
        if(!labrequest_id){
            return
        }

        const dt = previousRequest.filter(item=>(item.pay_status === 0 || item.pay_status === 3) && item?.item_status?.toLowerCase() === 'pending').filter(test => test.hospital_test_id).map(request => ({
            ...request,
            hospital_test_id: request.hospital_test_id,
            test_name: request.hospital_test_name,
            hospital_test_type: request.hospital_test_type,
            service_id: request.service_id,
            qty: 1,
            showTest: false,
            labrequests_detail_id: request.labrequests_detail_id,
            bill_id: request.bill_id, 
            labrequest_id: request.labrequest_id,
            status: request.status,
            cost: request.rate,
            invoice_no: request.invoice_no,
            bill_type:request.bill_type,
            date: request.time_requested ? dateStringConvert(request.time_requested) : '',
            uuid: uuidv4(),
        }))
        const inPatient = dt.filter(item=>item.labrequest_id === labrequest_id).filter(item=>item.item_status === 'Pending')
        const outPatient = dt.filter(item=>formatDate(item.date_created) === date_created)
        const arr = patient_type === '1' ? outPatient : inPatient
        setLabTests(arr)
    }, [previousRequest, labrequest_id]);


    const handleOpenEdit  = (item) =>{
        const {id,date} = item
        setBillId({labrequest_id: id, date_created: date})
        setOpenEdit(true)
    }

    const handleCloseEdit  = () =>{
        setOpenEdit(false)
    }


    const retrieveTests = (event, idx) => {
        const arr = labTests.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_test_name', titleCase(event.target.value));
                if (event.target.value !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_tests`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        if (dt[0] === "No Labrequests Found") {
                            setTests([{value: '', label: 'No Lab tests found'}]);
                        } else {
                            setTests(dt);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, test_name: event.target.value, showTest: true, isError: false}
                }
                return {...item, test_name: event.target.value, showTest: false, isError: false}
            }
            return item
        })
        setLabTests(arr)
        setIsSubmitted('idle')
    };

    const getTestDetails = (event, idx) => {
        const arr = labTests.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_test_id', event.value);
                item.test_name = event.innerText;
                item.hospital_test_id = event.value;
                if (event.value) {
                    axios.post(`${config.smsUrl}/cdoctor/get_testdetails_by_testid`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? {} : resp;
                        item.specimen = data.specimen ? data.specimen : '';
                        item.hospital_test_type = data.hospital_test_type ? data.hospital_test_type : '';
                        item.service_id = data.service_id ? data.service_id : '';
                        item.cost = data.cost ?? 0;
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    item.isError = false
                    item.showTest = false
                    item.isEdited = !!item.labrequests_detail_id;
                    return item
                } else {
                    item.showTest = false;
                    item.isError = true
                    return item
                }
            }
            return item
        })
        setLabTests(arr)
    };


    const handleAddRow = () => {
        const allRows = [...labTests];
        allRows.push(...initialState);
        setLabTests(allRows)
        setIsSubmitted('idle')
    };


    const removeRow = (item_uuid) => {
        const arr = labTests.filter((e) => e.uuid !== item_uuid);
        const removed = labTests.filter((e) =>  e.uuid === item_uuid).filter(item=>item.item_status === 'Pending')
        const inRemoved = labTests.filter((e) =>  e.uuid === item_uuid).filter(item=>item.item_status === 'Pending')
        const removedInv = patient_type === '1' ? removed : inRemoved
        setInvoicesRemoved(removedInv)
        setLabTests(arr);
        setIsSubmitted('idle')
    };

    const filteredArr = (arr) =>{
        const bill = arr[0] ? arr[0] : {};
        const bill_type = bill.bill_type ? bill.bill_type : ''
        return arr.filter(test => test.hospital_test_id && test.service_id).filter(test => Boolean(test)).map(item => ({
                    hospital_test_id: item.hospital_test_id,
                    labrequests_detail_id: item.labrequests_detail_id,
                    bill_type:item.bill_type ? item.bill_type : bill_type,
                    bill_id:item.bill_id,
                    bill_details:{
                        service_id: item.service_id,
                        quantity: 1,
                        rate: item.cost,
                        invoice_no: item.invoice_no ?? '',
                        bill_id:item.bill_id,
                    },
                }))
    }


    const handleSubmitLabRequest = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const arr = labTests.every(test => test.hospital_test_id && test.service_id)
        if (arr) {
            setIsSubmitted('pending');
            const lab_id = patient_type === '2' ?   labrequest_id: null
            const params = {
                visit_id: +visit_id,
                user_roles_id, labrequest_id:lab_id,
                result_type: 2,
                invoices_added: filteredArr(labTests).filter(test => !test.labrequests_detail_id),
                invoices_removed: filteredArr(invoicesRemoved).filter(test => test.labrequests_detail_id)
            }
            const url = patient_type === '1' ? 'update_labrequest' : 'update_inpatient_labrequest'
            axios.post(`${config.smsUrl}/cdoctor/update_labrequest`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('lab request updated successfully');
                setIsSubmitted('resolved');
                setSubmitted(false);
                setOpenEdit(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };


    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const editProps = {openEdit, handleOpenEdit, handleCloseEdit,
        submitted, tests, isSubmitted, labTests, previousRequest,
        retrieveTests, getTestDetails, handleAddRow, removeRow, handleSubmitLabRequest,
          isPending, isResolved, isRejected
    }

    return {editProps}
}