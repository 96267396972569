import React, {useEffect, useState} from "react";
import {useReusablePrescription} from "../DoctorsModule/Prescription/hooks/useReusablePrescription";
import {useSundries} from "../DoctorsModule/Prescription/hooks/useSundries";
import axios from "axios";
import {config} from "../../Helpers/env";
import {v4 as uuidv4} from "uuid";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {history} from "../../Helpers/history";
import {useVersionsContext} from "../../Context/versions-context";
import Alert from "@material-ui/lab/Alert";
import {DataCell, ModuleTable} from "../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {AutoCompleteProducts} from "../Utils/FormInputs/AutoCompleteInput";
import TextField from "../Utils/FormInputs/TextField";
import {ErrorMessage, RightAlignedContainer} from "../Utils/styledComponents";
import {StyledCell} from "../DoctorsModule/doctorStyles";
import Form from "react-bootstrap/Form";
import {titleCase} from "../Users/addUser";
import {
    CommonTableData,
    SundriesData
} from "../DoctorsModule/Prescription/NewPrescription";
import {ConfirmationDialog} from "../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {dateStringConvert} from "../Utils/ConvertDate";

const headData = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},
    {item:'Quantity to bill',  toolTip:'Enter quantity to bill', isRequired: true, tier:"Premium"},
    {item:'Quantity Prescribed',  toolTip:'Enter Prescribed Quantity'}, {item:'Available Stock'},
    {item:'Dosage',  toolTip:'Enter dosage'}, {item:'Frequency',  toolTip:'Enter frequency'},
    {item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'}, {item:'Cost'},{item:'Action'}]

const headDataStandard = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},
    {item:'Quantity Prescribed',  toolTip:'Enter Prescribed Quantity'}, {item:'Available Stock'},
    {item:'Dosage',  toolTip:'Enter dosage'}, {item:'Frequency',  toolTip:'Enter frequency'},
    {item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'}, {item:'Cost'},{item:'Action'}]

export function NewDentalPrescription({actions, match, paid, previousPrescription,setOpenDialog,isSubmitted, setIsSubmitted,prescription_id,prescriptionDetails}) {
    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;
    const {patient_number, visit_id} = match;
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    // const [prescriptionDetails, setPrescriptionDetails] = useState({})


    const obj = {actions, visit_id,setIsBlocking,previousDrugs:previousPrescription}


    const [presc_id, setPrescId] = useState("");
    const [printDialog, setOpenPrintDialog] = useState(false);

    const {drugProps} = useReusablePrescription(obj);
    const {submitted, prescription, drugs, others, setOther, setPrescription, setOthers, setSubmitted,
         retrieveDrugs, clickDrug, handleChangeOther, other, setDrugs,
        initialOthers, initialPrescState, openConfirmation, addItemsToReceipt,isDrugClicked,
        handleCloseConfirmation, handleCancelConfirmation, alert, setAlert, handleCloseAlert} = drugProps;

    const [isSundries, setIsSundries] = useState(false);
    const [removedInvoices, setRemovedInvoices] = useState([]);
    const [editedInvoices, setEditedInvoices] = useState([])
    const {sundryProps} = useSundries(obj);
    const {sundries, setSundries, initialState, handleResetSundries,
    openSundries, handleCancelSundries, handleCloseSundries, addSundriesToReceipt, sundriesRemoved} = sundryProps;

    const isEdit = !!prescription_id



    useEffect(() => {
        if(!prescription_id){
            return
        }
        const prescrip = prescriptionDetails.drugs ? prescriptionDetails.drugs : [];
        const other_meds = prescrip.filter(item=>!item.product_id);
        const dt = prescrip.filter(item=> item.pay_status === 0 || item.status===3 || item.status === 0 || item.pay_status === 3).filter(drug => drug.product_id).map(presc => ({
            ...presc,
            drug_name: presc.product_name, dosage: presc.dosage, status: presc.status,
            number_of_days: presc.number_of_days, key_information: presc.key_information, rate: presc.rate,
            product_id: presc.product_id, showDrug: false, frequency: presc.frequency,
            prescription_detial_id: presc.prescription_detial_id, bill_id: presc.bill_id, isEdited: false,
            date: presc.date_created ? dateStringConvert(presc.date_created) : null, invoice_no: presc.invoice_no,
            category_name: presc.category_name, prescription_id: presc.prescription_id,
            uuid: uuidv4(), quantity:presc.quantity_prescribed,stock:presc.department_quantity,
            quantity_billable:presc.quantity_billed,quantity_to_bill:presc.quantity_to_bill_insurance ?  presc.quantity_to_bill_insurance : 0,
            pack_unit:presc.insurance_id ?  presc.quantity : null, is_provided:presc.insurance_id && presc.quantity
        }))

        const sundriesArr = prescrip.filter(item=>item.category_name==='Sundries')
        if(sundriesArr.length > 0){
            setIsSundries(true)
        }
        const sunArr = sundriesArr.filter(item=> item.prescription_id === prescription_id && (item.pay_status === 0 || item.status===3)).filter(drug => drug.product_id).map(item=>({ product_name: item.product_name, product_id: item.product_id, quantity: item.quantity, category_name: item.category_name, prescription_detial_id: item.prescription_detial_id,  isEdited: false, showDrug:false, isError:false, isRemoved:false}))
        const otherMeds = other_meds.filter(item=>item.prescription_id === prescription_id)

        const presArr = dt.filter(drug => drug.category_name === 'Medicine')

        setPrescription(presArr)
        setSundries(sunArr)

        setOthers(otherMeds);

        if (otherMeds.length > 0) {
            setOther(true)
        }

        return ()=>{
            setPrescription([])
            setOthers([])
            setSundries([])
        }

    }, [prescription_id]);



    useEffect(() => {
        setIsSubmitted('idle');
    }, [isDrugClicked]);


    const handleClosePrintDialog = () => {
        setOpenPrintDialog(false)
    };

    const handleChangeItems = (event, index) => {
        const {name, value} = event.target;
        const arr = prescription.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value, isEdited: item.prescription_detial_id, invoice_no: item.invoice_no}
            }
            return item
        })
        const edited = arr.filter((item)=>item.isEdited)

        setEditedInvoices(edited)
        setPrescription(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const handleChangeOtherMedicine = (event, index) => {
        const {value, name} = event.target
        const arr = others.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setOthers(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    }

    const handleAddOther = () => {
        setOthers([...others, ...initialOthers])
    }

    const handleRemoveOther = (index) => {
        const arr = others.filter((_, idx) => idx !== index)
        setOthers(arr)
        setIsSubmitted('idle')
    }

    const handleAddRow = () => {
        const filtered_Prescription = prescription.filter(item=>!item.isExpired)
        setPrescription([...filtered_Prescription, ...initialPrescState])
        setAlert({...alert, open: false})
    };


    const removeRow = (item_uuid) => {
        const arr = prescription.filter((e) => e.uuid !== item_uuid);
        const removed = prescription.filter((e) => e.uuid === item_uuid);
        setPrescription(arr);
        setRemovedInvoices([...removedInvoices, ...removed])
        setIsSubmitted('idle')
        setAlert({...alert, open: false})
    };

    const filteredArr = (arr) =>{

        return arr.filter(item=>Boolean(item)).filter(item=>item.product_id && !item.isExpired).map(presc => ({
            dosage: presc.dosage,
            number_of_days: +presc.number_of_days,
            key_information: presc.key_information,
            rate: presc.rate,
            product_id: presc.product_id,
            frequency: presc.frequency,
            quantity_prescribed:+presc.quantity,
            is_provided: presc.is_provided,
            is_provider: presc.is_provider,
            currency_id:presc.currency_id,
            quantity_billable:presc.quantity_billable,
            prescription_detial_id: presc.prescription_detial_id,
            prescription_id: +presc.prescription_id,
            quantity_to_bill:+presc.quantity_to_bill,
            bill_details:{
                product_id: presc.product_id,
                rate: +presc.rate,
                quantity:+presc.quantity,
                invoice_no: presc.invoice_no,
                bill_id: +presc.bill_id,
                prescription_id: +presc.prescription_id,
                prescription_detial_id: presc.prescription_detial_id
            },
            bill_type:3,

        }))
    }


    const handleToggleSundries = (e) => {
        setIsSundries(e.target.checked)
        setIsBlocking(true)

    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const resetProducts = prescription.map(item=>({
            ...item, drug_name: '', dosage: '', disabled: false,generic_name:'', stock:'',
            number_of_days: 0, key_information: '', rate: 0, date: '', invoice_no: '',
            product_id: '', showDrug: false,  frequency: '', isError: false,
            prescription_detial_id: '', bill_id: '', isEdited: false, status:0,currency:'',
            currency_symbol:'', uuid : ''
        }))
        setPrescription(resetProducts)

        const resetOthers = others.map(item=>({
            ...item, other_medicine: '', dosage: '', disabled: false, quantity:0,
            number_of_days: 0, key_information: '', frequency: '', rate: 0
        }))
        setIsSundries(false)

        setOther(false)

        setOthers(resetOthers)

        handleResetSundries()
    }

    const handleCloseDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const arr = prescription.every(item => ((item.product_id && item.quantity && item.quantity > 0 && item.quantity <= item.stock && !item.isExpired) || (other && (!item.product_id && item.quantity && item.other_medicine))))
        const isSundry = sundries.every(item => item.product_name) ;

        const products = prescription.map(item=>({
            ...item,
            prescription_id
        }))

        const sunArr = isSundries ? (arr && isSundry) : arr;
        const onlySunArr = (isSundries && !arr) ? isSundry : false;
        setIsBlocking(false)
        if (sunArr || onlySunArr) {
            setIsSubmitted('pending');


            const params = prescription_id ? {
                bill_type: 3,
                prescription_id,
                products: filteredArr(products).filter(drug => !drug.prescription_detial_id),
                invoices_edited : filteredArr(editedInvoices),
                invoices_removed: filteredArr(removedInvoices).filter(drug => drug.prescription_detial_id),
                sundries_added: filteredArr(sundries).filter(item=>!item.prescription_detial_id),
                sundries_removed: filteredArr(sundriesRemoved),
                sundries: sundries.filter(item=>Boolean(item)).filter(item=>item.product_id).map(item=>({
                    bill_type:3,
                    ...item
                }))
            }: {
                bill_type: 3,
                products: prescription.filter(item=>Boolean(item)).filter(drug => drug.product_id && !drug.isExpired).map(presc => ({
                    dosage: presc.dosage,
                    number_of_days: +presc.number_of_days,
                    key_information: presc.key_information,
                    rate: presc.rate,
                    product_id: presc.product_id,
                    frequency: presc.frequency,
                    quantity_prescribed:parseInt(presc.quantity),
                    is_provided: presc.is_provided,
                    is_provider: presc.is_provider,
                    currency_id:presc.currency_id,
                    quantity_billable:presc.quantity_billable,
                    quantity_to_bill: +presc.quantity_to_bill

                })),
                sundries: sundries.filter(item=>Boolean(item)).filter(item=>item.product_id).map(item=>({
                    bill_type:3,
                    ...item
                }))
            }
            const url = prescription_id ? 'update_prescription' : 'save_prescription'
            axios.post(`${config.smsUrl}/cdoctor/${url}`, {
                visit_id, user_roles_id,
                other_products: others.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                    drug.frequency || drug.number_of_days), ...params
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const presc_id = !dt.prescription_id ? "" : dt.prescription_id;
                setPrescId(presc_id);
                actions.snackbarActions.snackSuccess(`Prescription ${prescription_id ? 'edited':'added'} successfully`);
                setIsSubmitted('resolved');
                setOpenDialog(false);
                setSubmitted(false);
                setOther(false)
                setOthers(initialOthers)

                const clearedArr  = prescription.map(item=>({
                    ...item,   drug_name: '', dosage: '', disabled: false,generic_name:'', stock:'',
                    number_of_days: 0, key_information: '', rate: 0, date: '', invoice_no: '',
                    product_id: '', showDrug: false,  frequency: '', isError: false,
                    prescription_detial_id: '', bill_id: '', isEdited: false, status:0,currency:'',
                    currency_symbol:'', uuid : uuidv4(), isExpired:false
                }))
                setPrescription(clearedArr)
                setSundries(initialState)
                setIsSundries(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);

            })
        }



    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected  = isSubmitted === 'rejected';

    const handleClick = () => {
        history.push(`/dental-prescription-report/${patient_number}/${visit_id}/${presc_id}`)
    };

    const all_drugs = drugs ? drugs : [];
    const sundriesHeadData = [ {item:' Sundry Name'}, {item:'Cost'},{item:'Action'} ];
    const isUUID = true;




    const {open, isExpired, message} = alert

    return(
        <>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}
                                title='Make cash payment'
                                confirmItems={addItemsToReceipt}
                                removeItems={handleCloseConfirmation} service='product'/>

            <ConfirmationDialog open={openSundries} handleClose={handleCancelSundries}
                                title='Make cash payment'
                                confirmItems={addSundriesToReceipt}
                                removeItems={handleCloseSundries} service='sundry'/>
            <form onSubmit={handleSubmit}>
                {open && isExpired ?
                    <Alert severity="error" style={{paddingTop: 0, paddingBottom: 0, marginBottom: '8px'}}
                           onClose={handleCloseAlert}>
                        {/*<AlertTitle>Expired Drug</AlertTitle>*/}
                        <strong>{message} is expired!</strong> Please choose another drug
                    </Alert> : null}
                <ModuleTable headData={version === 'Premium' ? headData : headDataStandard}>
                    {prescription?.map((item, index) => (
                        <TableRow
                            key={item.uuid}
                        >
                            <DataCell variant='300'>
                                {isEdit && item.prescription_detial_id ? item.drug_name :
                                    <>
                                        <AutoCompleteProducts reset={isResolved} options={all_drugs}
                                                              submitted={submitted} idx={index}
                                                              handleChange={retrieveDrugs} label_text="label"
                                                              medicine_value={{
                                                                  label: prescription[index].drug_name,
                                                                  value: prescription[index].product_id,
                                                                  generic_name: prescription[index].generic_name
                                                              }}
                                                              id="drug_name" value_text="value"
                                                              errorMsg_Valid={"Enter valid drug name"}
                                                              valid_error_check={item.isError && submitted}
                                                              testId={`doc_drug_name-${index}`}
                                                              handleProductData={clickDrug}
                                                              placeholder="Type Product Name"
                                                              errorMsg="Enter Product Name"/>
                                    </>
                                }
                            </DataCell>
                            {version === "Premium" ? <DataCell variant='150'>
                                {paid ? item.quantity_to_bill :
                                    <>
                                        <TextField type="number" disabled={!(item.is_provided && item.pack_unit)}
                                                   value={item.quantity_to_bill}
                                                   submitted={submitted && item.is_provided && item.pack_unit}
                                                   onChange={(e) => handleChangeItems(e, index)} name="quantity_to_bill"
                                                   required min={0}/>
                                        {submitted && item.is_provided && item.pack_unit && !item.quantity_to_bill ?
                                            <ErrorMessage>Quantity to bill is required</ErrorMessage> : null}
                                    </>}
                            </DataCell> : null}
                            <DataCell variant='150'>
                                {paid ? item.quantity_prescribed :
                                    <>
                                        <TextField type="number" disabled={item.isExpired} value={item.quantity}
                                                   submitted={submitted}
                                                   onChange={(e) => handleChangeItems(e, index)} name="quantity"
                                                   required min={0}/>
                                        {submitted && !item.quantity ?
                                            <ErrorMessage>Quantity is required</ErrorMessage> :
                                            item.quantity > item.stock ?
                                                <ErrorMessage>Quantity should be less than stock</ErrorMessage> :
                                                item.quantity < 0 ?
                                                    <ErrorMessage>Quantity should be more than 0</ErrorMessage> : null}
                                    </>}
                            </DataCell>
                            <DataCell variant='100'>
                                {item.stock}
                            </DataCell>
                            <CommonTableData {...{handleChangeItems, removeRow, item, index, isUUID}}/>
                        </TableRow>
                    ))}
                    {/*{!isOutPatient ?*/}
                    <TableRow>
                        <StyledCell colSpan={headData.length}>
                            <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                    id="add-row">Add another line
                            </button>
                        </StyledCell>
                    </TableRow>
                    {/*: null}*/}
                    <TableRow>
                        <DataCell>

                            <Form.Check label="Add other drugs" type="checkbox" className="radio-btn mr-4" name="other"
                                        inline
                                        onChange={handleChangeOther} checked={other}/>
                            {!other ? <Form.Check type="checkbox" label='Request sundries' className="radio-btn"
                                                  style={{fontSize: '14px'}}
                                                  defaultChecked={isSundries} onChange={handleToggleSundries}
                                                  inline/> : null}

                        </DataCell>
                    </TableRow>
                    {other ?
                        <>
                            {others.map((item, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <DataCell>
                                        <TextField
                                            value={item.other_medicine ? titleCase(item.other_medicine) : item.other_medicine}
                                            type="text"
                                            name="other_medicine" data-testid="doc-product-name"
                                            onChange={(e) => handleChangeOtherMedicine(e, index)}
                                        />
                                        {submitted && !item.other_medicine &&
                                            <ErrorMessage>Quantity is required</ErrorMessage>}
                                    </DataCell>
                                    <DataCell>
                                        <TextField type="number" value={item.quantity}
                                                   onChange={(e) => handleChangeOtherMedicine(e, index)}
                                                   name="quantity"/>
                                        {/*submitted && !item.quantity && <ErrorMessage>Quantity is required</ErrorMessage> */}
                                    </DataCell>
                                    <DataCell/>
                                    <CommonTableData {...{
                                        handleChangeItems: handleChangeOtherMedicine,
                                        removeRow: handleRemoveOther,
                                        item,
                                        index
                                    }}/>
                                </TableRow>
                            ))}
                            {/*{!isOutPatient ?*/}
                            <TableRow>
                                <DataCell colSpan={headData.length}>
                                    <button type="button" onClick={handleAddOther} className="btn btn-sm sms-gray-btn"
                                            id="add-row">Add another line
                                    </button>
                                </DataCell>
                            </TableRow>
                            {/*:null}*/}
                        </> : null}
                    <TableRow>
                        <DataCell>

                            {other ? <Form.Check type="checkbox" label='Request sundries' className="radio-btn"
                                                 style={{fontSize: '14px'}}
                                                 defaultChecked={isSundries} onChange={handleToggleSundries}
                                                 inline/> : null}
                        </DataCell>
                    </TableRow>
                </ModuleTable>
                <div className="mt-3">
                    {isSundries ?
                        <ModuleTable headData={sundriesHeadData}>
                            <SundriesData {...{...sundryProps, isOutPatient: true, isEdit}} submitted={submitted}/>
                        </ModuleTable> : null}
                </div>
                <RightAlignedContainer>
                    <button type='submit' className='btn sms-btn btn-sm'
                            disabled={isPending}>{isPending ? 'Saving...' : 'Save Prescription'}</button>
                </RightAlignedContainer>
            </form>
        </>

    )
}