import { useState} from "react";
import {titleCase} from "../../../Users/addUser";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import { v4 as uuidv4 } from 'uuid';

export const useAddDispatch = (actions) => {
    const departments = [{value:'Pharmacy', text:'Pharmacy'},
        {value:'Patient Emergency', text:'Patient Emergency'},
        {value:'Lab', text:'Lab'}]

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const requested_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_name = !_token.user_name ? "" : _token.user_name;
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;

    const [products, setProducts] = useState([{product_name: '', product_id: '', available_quantity: '', generic_name:'',
    quantity: '', batch_id:'', batches:[], showProduct: false, isError:false, uuid: uuidv4()}]);
    const [items, setItems] = useState([]);

    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [to_department, setToDepartment] = useState('')

    const [openDialog, setDialog] = useState(false);
    const [dispatch_id, setDispatchId] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const retrieveProducts = (eventTxt, idx) => {
        const productCopy = [...products];
        if (productCopy[idx]) {
            productCopy[idx].product_name = eventTxt;
            const formData = new FormData();
            formData.append('product_name', titleCase(eventTxt));
            if (eventTxt !== '') {
                axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? [] : resp;
                    if (resp === "No Medicine Found") {
                        const val = [{value: "", label: "No Medicine Found"}]
                        setItems(val)
                    } else {
                        const arr = data.map(item=>({value:item.value, label: `${item.label}-${item.generic_name}-${item.country_of_origin}`}))
                        setItems(arr);
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
            }
            productCopy[idx].showProduct = true;
            productCopy[idx].isError = false;
        } else {
            productCopy[idx].isError = false;
            productCopy[idx].showProduct = false;
        }
        setProducts(productCopy)
    };

    const clickProduct = (event, idx) => {
        const productCopy = [...products];
        const formData = new FormData();
        formData.append('product_id', event.value);
        formData.append('location', 1);
        if (productCopy[idx]) {
            productCopy[idx].product_name = event.innerText;
            productCopy[idx].product_id = event.value;
            productCopy[idx].generic_name = event.generic_name;
            if (event.value) {
                axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_ids`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? {} : resp;
                    const batches = data.product ? data.product : []
                    const arr = batches.map(item=>({value:item.batch_id, text:item.batch_id}))
                    productCopy[idx].batches = arr;
                    setProducts(productCopy)
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                productCopy[idx].isError = false;
            }else{
                productCopy[idx].isError = true;
            }
            productCopy[idx].showProduct = false;
        }
    };

    const handleChangeBatch = (event, idx) => {
        let allRows = [...products];
        allRows[idx]['batch_id'] = event.value;
        const formData = new FormData();
        formData.append('batch_id', event.value)
        formData.append('product_id', allRows[idx]['product_id'])
        axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_id`, formData).then(response => {
            const res = response.data;
            const data = res ? res : {};
            const t_product = data.total_product ? data.total_product : 0;
            allRows[idx]['available_quantity'] = t_product < 0 ? 0 : t_product;
            allRows[idx]['isBatch'] = false
            setProducts(allRows)
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const handleChangeItems = (event, index) => {
        const {value} = event.target;
        const productCopy = [...products];
        const arr = productCopy.map((item,idx)=>{
            if (idx === index){
                return {...item,quantity:value}
            }
            return item
        })
        setProducts(arr)
    };


    const handleAddRow = () => {
        const new_item = {product_name: '', product_id: '', available_quantity: '', generic_name:'',
        quantity: '', batch_id:'', batches:[], showProduct: false, isError:false, uuid: uuidv4()};
        setProducts([...products, new_item])
    };

    const removeRow = (item_uuid) => {
        const arr = products.filter((e)=> e.uuid !== item_uuid);
        setProducts(arr);
    };

    const handleChangeDepartment = (event) =>{
        setToDepartment(event.target.value)
    }

    const handleCloseDialog = () => {
        setDialog(false)
    }

    const handleClick = () => {
        history.push(`/dispatchnote/${dispatch_id}`)
    }

    const handleCancel = () => {
        history.push('/dispatchlist/new')
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const productIds = products.filter(drug=>drug.product_id !== '' && drug.quantity !== '').map(({product_id, quantity, batch_id})=>({product_id, quantity, batch_id}))
        const arr = products.every(drug=>drug.product_id !== '' && drug.quantity !== '' && +drug.quantity <= +drug.available_quantity)
        if (arr && to_department) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cinventory/add_pharmacy_dispatch_note`, {created_by:user_roles_id, to_store:to_department, dispatched_products:productIds}).then((res) => {
                const data = res.data;
                const dt = !data.data ? {} : data.data;
                const _id = !dt.dispatch_id ? '' : dt.dispatch_id;
                setDispatchId(_id)
                setDialog(true);
                actions.snackbarActions.snackSuccess('Requisition saved successfully');
                setIsSubmitted('resolved');
                const arr = products.map(item=>({
                    ...item,product_name: '', product_id: '', available_quantity: '',
                    quantity: '', showProduct: false, isError:false
                }))
                setProducts(arr)
                setSubmitted(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);
            })
        }
    };


    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';



    return {closeSnackBar, openDialog,handleCloseDialog, handleClick,
        handleCancel, submitted, isRejected, isResolved, products, items, to_department,
        departments, requested_by, retrieveProducts, clickProduct, handleChangeItems,
        handleAddRow,removeRow, handleSubmit,  user_name,   isPending, handleChangeBatch,
        handleChangeDepartment
    }
}