/** @jsxImportSource @emotion/react */
import {jsx,css} from '@emotion/react'
import * as React from 'react'
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import * as colors from "../../../styles/colors";
import {detailsTable, table, thickBorder} from "../../../styles/tableStyles";
import {formatDateTime} from "../../Utils/ConvertDate";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";
import Card from "@material-ui/core/Card";
import {removeTrailingZeros} from "../utils/removeDecimals";
import {removeNumbers} from "../out-patient/BilledInvoicesReceipt";



const textWeight = css`
    font-weight:600;
`

export function ReversedBills({open, setOpen, visit_id,provider_id, actions, currency_symbol='',isBaseCurrency=true}) {
    const [bills, setBills] = React.useState([])

    const convertList = (receiptsArr, ex_rate, total_bill, isBase, original_rate) => {
        const productArr = receiptsArr.filter((item) => item.product_id)
        const serviceArr = receiptsArr.filter((item) => item.service_id)
        const bedArr = receiptsArr.filter((item) => item.bed_assignment_id);

        const arr = [...serviceArr, ...productArr, ...bedArr].map((item, idx) => {
            const rate =  item.rate ? item.rate : 0;
            const usd_rate = rate * original_rate
            const usd_amount = +item.amount * original_rate
            const isNotBase = item.currency_rate !== 1
            return {
                ...item,
                name: item.service_id
                    ? item.service_name
                    : item.product_id
                        ? item.product_name
                        : item.bed_assignment_id
                            ? `${item.ward_name} -> ${removeNumbers(item.room_name)}`
                            : "",
                quantity: item.bed_assignment_id
                    ? `${item.quantity} day${item.quantity > 1 ? "s" : ""}`
                    : item.quantity,
                usd_rate: isNotBase ? usd_rate :  rate,
                other_currency_rate: isBase ? item.rate :  (ex_rate ? usd_rate / ex_rate : usd_rate),
                usd_total: isNotBase ? usd_amount :  item.amount,
                currency_total: isBase ? item.amount:  (ex_rate ?  usd_amount / ex_rate : item.amount),
            };
        });

        const convertedArr = arr.map((item) => ({
            ...item,
            other_currency_rate: removeTrailingZeros(
                item.other_currency_rate.toFixed(3)
            ),
            currency_total: removeTrailingZeros(item.currency_total.toFixed(3)),
        }));

        setBills(convertedArr);
    };

    React.useEffect(() => {
        if (!(visit_id && provider_id)){
            return
        }
        const formData = new FormData();
        formData.append("visit_id", visit_id);
        formData.append("provider_id", provider_id);
        axios.post(`${config.smsUrl}/cbilling/view_visit_invoices`, formData)
            .then((res) => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const invoices = all_data.reversed ?? [];
                const obj = invoices[0] ? invoices[0] : {};
                const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;
                convertList(invoices, exchange_rate, all_data.total_bill, true, exchange_rate);
            })
            .catch((e) => {
                errorMessages(e, null, actions)
            });
    }, [provider_id, visit_id]);

    return(
        <CustomDialog open={open} handleClose={()=>setOpen(false)} title='Reversed Bills'>
            {bills.map(receipt=> {
                return (
                    <Card key={receipt.id} style={{padding: '10px', border: `1px solid ${colors.gray001}`, marginBottom:'1rem', borderRadius:'10px'}}>
                        {/*<p style={{margin:'0 0 10px 0'}}>Reverse#   <strong>{receipt.id}</strong></p>*/}
                        <table css={[table, detailsTable, thickBorder]}>
                            <tr>
                                <td><span>Date reversed</span></td>
                                <td css={[textWeight]}>
                                    <span>{receipt.reversed_date ? formatDateTime(receipt.reversed_date) : ''}</span>
                                </td>
                            </tr>
                            <tr>
                                <td><span>Item</span></td>
                                <td css={[textWeight]}>
                                    <span>{receipt.name}</span>
                                </td>
                            </tr>
                            <tr>
                                <td><span>Quantity reversed</span></td>
                                <td css={[textWeight]}><span>{receipt.quantity}</span>
                                </td>
                            </tr>
                            <tr>
                                <td><span>Amount reversed</span></td>
                                <td css={[textWeight]}>
                                    <span>{isBaseCurrency ? `${currency_symbol} ${formatDigits(receipt.usd_total)} ` : `${currency_symbol} ${formatSsp(receipt.currency_total)}`}</span>
                                </td>
                            </tr>
                            <tr>
                                <td><span>Reversed by</span></td>
                                <td css={[textWeight]}>
                                    <span>{`${receipt.reversed_by_first_name ? receipt.reversed_by_first_name : ''} ${receipt.reversed_by_last_name ? receipt.reversed_by_last_name : ''}`}</span>
                                </td>
                            </tr>

                        </table>
                    </Card>
                )
            })}
        </CustomDialog>
    )
}