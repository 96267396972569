import React, {useRef, useState, useEffect} from 'react';
import {history} from "../../../Helpers/history";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { convertPhone } from '../../Utils/convertPhone';
import {isCurrentPastDate} from "../../Utils/ConvertDate";

export const useRegisterPatient = (actions) => {
    const [state, setState] = useState({
        first_name: '', last_name: '', gender: '', dob: '', email: '', id_nin: '',
        address: '', first_name1: '', last_name1: '', address1: '', relationship: '', age:0,
        patient_number :''
    });
    const[providerState,setProviderState] = useState({card_number: '',policy_holder: '',account_name:'', insurance_provider:''})
    const [contact, setContact] = useState();
    const [k_contact, setKinContact] = useState();
    const [blood_type, setBloodType] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [openVisit, setOpenVisit] = useState(false);
    const [p_number, setPatientNumber] = useState('')
    const [patient, setPatient] = useState({})
    const [patientRelation, setPatientRelation] = useState(null)
    const [kinRelation,setKinRelation] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    const [patient_number, setNumber] = useState('')
    const [isAddProvider,setAddProvider]= useState(false)
    const [providerOptions, setProviderOptions] = useState([])
    const [providers, setProviders] = useState([])
    const [insuranceProvider,setInsuranceProvider] = useState('')
    const [otherProviderDetails, setOtherDetails] = useState(null)
    const [allergies, setAllergies] = useState('')
    const [hasAllergies, setHasAllergies] = useState(false)
    const [chronic_illness, setChronicIllness] = useState(
        {cardiac_disease:false, kidney_disease:false, hypertension:false, tb:false, asthma:false, sti:false, 
            sickle_cell_disease:false, epilepsy:false, diabetes:false, other_chronic_illness:''}
    );
    const [other_medicals, setOtherMedicals] = useState('');
    const formRef = useRef();
    const [isDisableAge, setDOBAge] = useState(true);
    const [age_type, setAgeType] = useState('years');
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const isSubmitting = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted ===  'rejected';

    useEffect(()=> {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`)
          .then(res => {
            const data = res.data
            const dt = !data?{} :data
            const arr = dt.data? dt.data:[]
            setProviders(arr)
          }).catch(err => {
            logoutErrorMessage(err, null, actions)
    })
    },[])


    useEffect(()=>{
        if (!patient_number) {
            return
        }
        const {first_name, kin_firstname, last_name, kin_lastname, relationship,
            gender, dob, address, kin_address, email, id_nin,kin_contact,phone_no,
             blood_type, age,provider_name,provider_type,card_number,account_name,provider_id, chronic_illness,allergies, ot} = patient
     
        setState({first_name, first_name1:kin_firstname, last_name, last_name1:kin_lastname, relationship,
            gender, dob, address, address1:kin_address, email, id_nin, 'age': parseInt(age)})
        setContact(phone_no)
        setKinContact(kin_contact)
        setBloodType({value:blood_type, label:blood_type})
        setPatientRelation({value:relationship, label:relationship})
        !allergies?setHasAllergies(false):setHasAllergies(true)
        setAllergies(allergies)


        setChronicIllness(!chronic_illness?{cardiac_disease:false, kidney_disease:false, hypertension:false, tb:false, asthma:false, sti:false,
            sickle_cell_disease:false, epilepsy:false, diabetes:false, other_chronic_illness:''}:chronic_illness)
        if (dob === "0") {
            setDOBAge(false);
        } else {
            setDOBAge(true);
        }
        
        if(provider_id){
            setAddProvider(true)
            setInsuranceProvider(provider_name)
            setOtherDetails({provider_type:provider_type, provider_id})
            setProviderState({card_number: card_number,account_name:account_name})
        }


    }, [patient])

    const handleHasAllergies = (event) => {
        const {value} = event.target
        if(value === 'yes'){
            setHasAllergies(true)
        }else if(value === 'no'){
            setHasAllergies(false)
        }
        setIsBlocking(true)

    }

    const handleChangeAllergies = (event) => {
        const {value} = event.target
        setAllergies(value)
        setIsBlocking(true)
    }
    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
        setIsBlocking(true)
    };

    const handleChangeBloodGroup = (value) => {
        setBloodType(value)
        setIsBlocking(true)
    };

    const handleChangeAgeType = (event) => {
        setAgeType(event.target.value)
        setIsBlocking(true)
    };

    const handleChangePatientRelation = (value) => {
        setPatientRelation(value)
        setIsBlocking(true)
    };

    const handleOpenDialog = (num) =>{
        if (num){
            setNumber(num)
            const formData = new FormData()
            formData.append('patient_number', num)
            axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
                const patient = response.data;
                const pt = !patient ? {} : patient;
                setPatient(pt)
                // const providers = response.data
                // const pd = !providers ? {} : providers

            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
        }else {
            setState({first_name: '', last_name: '', gender: '', dob: '', email: '', id_nin: '',
                address: '', first_name1: '', last_name1: '', address1: '', relationship: '', age:'',
                patient_number :''})
            setProviderState({card_number: '',policy_holder: '',account_name:'', insurance_provider:''})
            setProviderOptions('')
            setAddProvider(false)
            setInsuranceProvider('')
            setOtherDetails(null)
            setPatientRelation('')
            setContact(undefined)
            setKinContact(undefined)
            setBloodType('')
            setKinRelation('')
            setHasAllergies(false)
            setAllergies('')
            setSubmitted(false)
            setOtherMedicals('')
            setChronicIllness({cardiac_disease:false, kidney_disease:false, hypertension:false, tb:false, asthma:false, sti:false,
                sickle_cell_disease:false, epilepsy:false, diabetes:false, other_chronic_illness:''})
        }

        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenDialog(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }

    }

    const handleChangeKinRelation = (event) =>{
        setKinRelation(event.target.value)
        setIsBlocking(true)
    }


    const handleOtherMedicals = (event) => {
        setChronicIllness({...chronic_illness, other_chronic_illness:event.target.value});
        setIsBlocking(true)
    }
    const handleMedicalIllness = (event) => {
        const {name, checked} = event.target;
        setChronicIllness({...chronic_illness, [name]:checked});
        setIsBlocking(true)
    }

    useEffect(() => {
       
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            setProviderOptions(arr);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
        })
    }, [isResolved]);

    const handleSelect =(event) => {
        setInsuranceProvider(providers[event.target.value].provider_name)
        setOtherDetails(providers[event.target.value])
        setIsBlocking(true)
    }

    const submitPatientDetails = (event) =>{
        event.preventDefault();
        const {gender,address,dob} = state
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        const relation = patientRelation ?? {};
        setSubmitted(true)
        const provider_id = otherProviderDetails?.provider_id
        const provider_type = otherProviderDetails?.provider_type
        if(hasAllergies === false && allergies){
            setAllergies('');
        }
        const illness = fieldValues['chronic_illness']
        const {other_chronic_illness, ...rest} = chronic_illness
        const userAge = !fieldValues['age'] ? {age:''} : {}
        const account_name = !fieldValues['account_name'] ? {account_name:''} : {}

        const allergyData = !fieldValues['allergies'] ? {allergies:''} : {}

        const card_number = !fieldValues['card_number'] ? {card_number:''} : {}
        const dataObj ={
            ...fieldValues,
            ...userAge,
            ...account_name,
            ...card_number,
            ...allergyData,
            'patient_number':patient_number,
            age_description: !isDisableAge ?  age_type : '',
            'other_names': '',
            'dob': dob,
            'phone_no': contact ? convertPhone(contact): contact,
            'residence':address,
            'image_path': '',
            'blood_type': blood_type ? (blood_type.value ? blood_type.value : '') : '',
            'kin_phone_no': k_contact ? convertPhone(k_contact):k_contact ?? '',
            'relationship': relation.value === 'Other' ? kinRelation :  (relation.value ? relation.value:''),
            'provider_type':provider_type ? provider_type:'',
            'provider_id' : provider_id ? provider_id:'',
            'status': 1,
            'chronic_illness': {...rest, other_chronic_illness:illness}
        }

        const considerProvider =  isAddProvider === false ? true : provider_type==='Insurance Company'?(fieldValues['account_name'] && fieldValues['card_number']):(fieldValues['account_name'])
        const isRequireAge = (isDisableAge && dob) || (!isDisableAge && fieldValues['age'])
        if (fieldValues['first_name'] && gender   && fieldValues['last_name']  && considerProvider && isRequireAge) {
            const url = patient_number ? 'edit_patient' : 'add_patient'
            setIsSubmitted('pending')
            setIsBlocking(false)
            return axios.post(`${config.smsUrl}/cpatient/${url}`, dataObj)
        }
    }
    

    const handleSavePatient = (event) => {
        event.preventDefault();
        const {gender,address,dob} = state

        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())

        const provider_type = otherProviderDetails?.provider_type
        if(hasAllergies === false && allergies){
            setAllergies('');
        }

        const considerProvider =  isAddProvider === false ? true : provider_type==='Insurance Company'?(fieldValues['account_name'] && fieldValues['card_number']):(fieldValues['account_name'])
        const is_dob = isCurrentPastDate(dob)
        const isRequireAge = (isDisableAge && is_dob) || (!isDisableAge && fieldValues['age'])
        setSubmitted(true)
        if(contact && fieldValues['first_name'] && gender   && fieldValues['last_name']  && considerProvider && isRequireAge){
            submitPatientDetails(event).then((res) => {
                const data = res.data;
                const dt = data ? data : {};
                const p_number = dt.patient_number ? dt.patient_number : ''
                setPatientNumber(p_number);
                setOpenVisit(true);
                setOpenDialog(false)
                actions.snackbarActions.snackSuccess("Patient registered successfully");
                setIsSubmitted('resolved')
                setSubmitted(false)

            }).catch((err) => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
                setOpenVisit(false)
            });
        }


    };

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
    }



    const handleCloseVisit = () => {
        setOpenVisit(false)
        setOpenDialog(false)
    };

    const handleClickVisit = () => {
        setOpenVisit(false);
        history.push(`/newvisit/${p_number}`);
    };

    const handleDOBAgeChange = () => {
        if (!isDisableAge == true) {
            setState({...state, age: ''});
        } else {
            setState({...state, dob: ''});
        }
        setDOBAge(!isDisableAge);
    }



    const handleProviderToggle = (event) => {
        setAddProvider(event.target.checked);
    };

    const handleChangeProvider = (event) => {
        const {name, value} = event.target;
        setProviderState({
            ...providerState, [name]: value
        })
    }
    
    const handleHaveAllergies = (event) =>{
        setAllergies(event.target.checked)
    }

    const patientProps = {state, contact, blood_type,openVisit, providers,insuranceProvider,providerOptions,
        handleCloseVisit, handleClickVisit, handleChange, handleChangeBloodGroup,setProviders,isSubmitted,
        k_contact,  kinRelation,patientRelation,isSubmitting,isRejected, handleSelect,
        setPatientNumber, setKinRelation,isBlocking,isBlockDialog,setOpenDialog,setIsBlockDialog,
        isDisableAge, handleDOBAgeChange, age_type, handleChangeAgeType, setNumber,setPatient, setIsBlocking,
        handleChangeKinRelation, handleChangePatientRelation,handleSavePatient, setBloodType,handleChangeProvider,isAddProvider,
         setState,setPatientRelation,setKinContact, setContact,  p_number,submitted, formRef, handleProviderToggle,providerState,
         otherProviderDetails,chronic_illness,handleMedicalIllness,other_medicals,handleOtherMedicals,handleHaveAllergies,
        handleHasAllergies,handleChangeAllergies,allergies,hasAllergies, handleResetForm
    }
   
    return {patientProps,openDialog, handleOpenDialog, handleCloseDialog,
        providerOptions,setAddProvider,isResolved, submitPatientDetails,
    submitted, isSubmitted, setSubmitted, setIsSubmitted}
}
