import  {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {dateStringConvert, formatDate} from "../../../Utils/ConvertDate";
import {v4 as uuidv4} from "uuid";

export const useEditRadiologyRequest = (actions, match, _token) => {
    const {visit_id,  patient_type,previousScan} = match;
    const initialState = [{
        hospital_scan_id: '', scan_name: '', scan_type: '', service_id: '',
        qty: 1, showScan: false, purpose: '', isError: false, date: '',  uuid: uuidv4(),
        scan_requests_detail_id: '', isEdited: false, cost: 0, invoice_no:'', status:0
    }]
    const [submitted, setSubmitted] = useState(false);
    const [scanTests, setScanTests] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [scans, setScans] = useState(initialState);
    const [id, setId] = useState({scan_request_id:'', date_created:''});
    const [invoicesRemoved, setInvoicesRemoved] = useState([]);
    const [invoicesEdited, setInvoicesEdited] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const {scan_request_id, date_created} = id



    useEffect(() => {
        if(!(previousScan && scan_request_id)){
            return
        }
        const pScan = previousScan ? previousScan : []
        const prevScan = pScan.filter(item=> (item.pay_status === 0 || item.pay_status === 3) &&  item?.item_status?.toLowerCase() === 'pending').map(prev => ({ ...prev,
            hospital_scan_id: prev.hospital_scan_id,
            purpose: prev.purpose,
            scan_name: prev.hospital_scan_name,
            scan_type: prev.scan_type_name,
            service_id: prev.service_id,
            qty: 1,
            showScan: false,
            scan_requests_detail_id: prev.scan_requests_detail_id,
            cost: +prev.rate,
            date: prev.time_requested ? dateStringConvert(prev.time_requested) : '',
            invoice_no:prev.invoice_no,
            bill_type:prev.bill_type,
            status:prev.status,
            uuid: uuidv4(),
        })).filter(test => test.hospital_scan_id)
        const inPatient = prevScan.filter(item=>item.scan_request_id === scan_request_id)
        const outPatient = prevScan.filter(item=>formatDate(item.date_created) === date_created)
        const arr = patient_type === '1' ? outPatient : inPatient;
        setScans(arr)
        return ()=>{

        }
    }, [previousScan, scan_request_id]);

    const handleOpenEdit = (item) =>{
        const {id, date} = item
        setId({scan_request_id:id, date_created: date})
        setOpenEdit(true)
    }

    const handleCloseEdit = () =>{
        setOpenEdit(false)
    }


    const getScanTests = (eventTxt, idx) => {
        const arr = scans.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_scan_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_scans`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data.length > 0) {
                            const arr = data.map(item=>({...item, value:item.hospital_scan_id, label:item.hospital_scan_name}))
                            setScanTests(arr);
                        } else {
                            setScanTests([{hospital_scan_id: '', hospital_scan_name: 'No Scan tests found'}])
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, scan_name: eventTxt, showScan: true, isError: false}
                }
                return {...item, scan_name: eventTxt, showScan: false, isError: false}
            }
            return item
        })
        setScans(arr)
    };

    const retrieveScanDetails = (event, idx) => {
        const arr = scans.map((item, index) => {
            if (index === idx) {
                item.scan_name = event.innerText;
                item.hospital_scan_id = event.value;
                const formData = new FormData();
                formData.append('hospital_scan_id', event.value);
                if (event.value) {
                    axios.post(`${config.smsUrl}/cdoctor/getscandetails_by_scan_id`, formData).then(res => {
                        const data = res.data;
                        const details = !data ? {} : data;
                        item.scan_type = details.scan_type_name;
                        item.service_id = details.service_id;
                        item.cost = details.cost ?? 0;
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    item.isError = false;
                    item.showScan = false;
                    item.isEdited = !!item.scan_requests_detail_id;
                    return item
                }
                item.isError = true;
                item.showScan = false
                return item
            }
            return item
        })
        setScans(arr);
    };

    const handleChangePurpose = (e, index) => {
        const {value} = e.target
        const arr = scans.map((item, idx) => {
            if (idx === index) {
                return {...item, purpose: value, isEdited: item.scan_requests_detail_id}
            }
            return item
        })
        setScans(arr)
        const edited = arr.filter(item=>item.isEdited)
        setInvoicesEdited(edited)
        
    }

    const handleAddRow = () => {
        setScans([...scans, ...initialState])
        setIsSubmitted('idle')
    };


    const removeRow = (item_uuid) => {
        const arr = scans.filter((e) => e.uuid !== item_uuid)
        const inRemoved = scans.filter((e) => e.uuid === item_uuid).filter(item=>item.item_status === 'Pending')
        const outRemoved = scans.filter((e) => e.uuid === item_uuid).filter(item=>item.item_status === null)
        const removed = patient_type === '1' ? outRemoved : inRemoved;
        setInvoicesRemoved(removed)
        setScans(arr);
        setIsSubmitted('idle')
    };

    const filteredArr = (arr) =>{
        const bill = arr[0] ? arr[0] : {};
        const bill_type = bill.bill_type ? bill.bill_type : ''
        return arr.filter(scan => scan.hospital_scan_id && scan.service_id).map(item => ({
        hospital_scan_id: item.hospital_scan_id,
        purpose: item.purpose,
        scan_requests_detail_id: item.scan_requests_detail_id,
        bill_type:item.bill_type ? item.bill_type:bill_type,
        bill_details:{
            service_id: item.service_id,
            quantity: 1,
            rate: item.cost,
            invoice_no:item.invoice_no ?? '',
            bill_id:item.bill_id,
        },
    }))
    }


    const handleSubmitScanRequest = (event) => {
        event.preventDefault();
        const arr = scans.every(item => item.hospital_scan_id)
        setSubmitted(true);
        const params = {
            visit_id, user_roles_id,scan_request_id,
            result_type: 3,  invoices_added: filteredArr(scans).filter(scan =>!scan.scan_requests_detail_id),
            invoices_edited:filteredArr(invoicesEdited),
            invoices_removed: filteredArr(invoicesRemoved).filter(scan => scan.scan_requests_detail_id)
        }
        if (arr) {
            setIsSubmitted('pending');
            // const url = patient_type === '1' ? 'update_scan_request' : 'update_inpatient_scanrequest';
            axios.post(`${config.smsUrl}/cdoctor/update_scan_request`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Request updated successfully');
                setIsSubmitted('resolved');
                setSubmitted(false);
                setOpenEdit(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const editProps = {
        submitted, scanTests, isSubmitted, getScanTests, retrieveScanDetails,handleCloseEdit,
        handleAddRow, removeRow, handleSubmitScanRequest, closeSnackBar, scans, handleChangePurpose,
        scan_request_id, isPending, isResolved, isRejected, openEdit, handleOpenEdit
    }

    return {editProps}
}