/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import {
    convertDateStrokeTime,
    formatDateTime,
} from "../../Utils/ConvertDate";
import {
    DisplayAnteNatalDetails,
    getMetricValues,
    GetTriageDetails,
} from "../ReusableTreatmentHistory";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors";
import { SavedNotesContainer } from "../Notes/PatientNotesContainer";
import { splitNotes } from "../../Radiology/RadiologyReport";
import { LabResultsTable } from "../../Lab/PatientLabReport";
import { RadiologyResultReport } from "../Radiology/RadiologyResultReport";
import {
    borderedTriage,
    detailsTable,
    paddingCell,
    table,
    tableBordered
} from "../../../styles/tableStyles";
import { ItemsTable } from "../../Utils/Templates/PrintTemplate";
import Label from "../../Utils/FormInputs/Label";
import { NotesRow, PreviousNotesTable } from "../Notes/PreviousNotesTable";
import { useVersionsContext } from "../../../Context/versions-context";
import {ExpiredStatus, FinishedStatus, PendingStatus, Processing,} from "../doctorStyles";

export const Heading = styled.h4({
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: 0,
});

export const ResultsHeading = styled.div({
    margin: "30px 0 10px 0",
});

export const Border = styled.div({
    border: `2px solid ${colors.indigo}`,
    width: "60px",
});





export const longestString = (arr = []) => {
    let longString = "";
    for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.length > longString?.length) {
            longString = arr[i];
        }
    }
    return longString;
};

export const PreviousTriage = ({patientTriage}) => {
    const { metrics_titles, percentage_metrics } = GetTriageDetails(patientTriage);

    return patientTriage.length > 0 && (
        <div className="table-responsive">
            <table css={[table, borderedTriage]}>
                <thead>
                <tr>
                    <td rowSpan={2} style={{ textAlign: "center", padding: "5px" }}>
                        <strong>Date</strong>
                    </td>
                    <td
                        colSpan={metrics_titles.length}
                        style={{ textAlign: "center", padding: "5px" }}
                    >
                        <strong>Metrics</strong>
                    </td>
                    {/*<td*/}
                    {/*    className="print_button"*/}
                    {/*    rowSpan={2}*/}
                    {/*    style={{ textAlign: "center", padding: "2px" }}*/}
                    {/*>*/}
                    {/*    <strong>Action</strong>*/}
                    {/*</td>*/}
                </tr>
                <tr>
                    {metrics_titles.map((it) => (
                        <td
                            key={it.metric_id}
                            style={{ textAlign: "center", padding: "5px" }}
                        >
                <span style={{ margin: "3px" }}>
                  <strong>{`${it.metric_name} ${
                      it.uom ? `(${it.uom})` : ""
                  }`}</strong>
                </span>
                        </td>
                    ))}
                </tr>
                </thead>
                <tbody>
                {patientTriage.map((element, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ textAlign: "center", padding: "5px" }}>
                              <span style={{ margin: "3px" }}>
                                {convertDateStrokeTime(element.metric_date)}
                              </span>
                            </td>
                            {metrics_titles.map((it, idx) =>
                                getMetricValues(
                                    element.data,
                                    it.metric_name,
                                    percentage_metrics,
                                    element.isEdit,
                                    ()=>{},
                                    index
                                )
                            )}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};





export const PreviousClinicalNotes = ({clinicalNotes, visit_id, handleOpen}) => {
    const headData = [{ name: "Date" }, { name: "Written By" }];
    return clinicalNotes.length > 0 && (
        <PreviousNotesTable headData={headData}>
            {clinicalNotes.map((item) => {
                const viewNotes = (
                    <div className="row mb-0">
                        <div className="col-lg-6">
                            {item.complaints ? (
                                <>
                                    <Label name="Presenting complaints" />
                                    <SavedNotesContainer data-testid="p_complaints">
                                        {splitNotes(item.complaints)}
                                    </SavedNotesContainer>
                                </>
                            ) : null}
                            {item.past_medical_history ? (
                                <>
                                    <Label name="Past medical history" />
                                    <SavedNotesContainer data-testid="medical_history">
                                        {splitNotes(item.past_medical_history)}
                                    </SavedNotesContainer>
                                </>
                            ) : null}
                            {item.physical_examination ? (
                                <>
                                    <Label name="Physical examination" />
                                    <SavedNotesContainer data-testid="p_examination">
                                        {splitNotes(item.physical_examination)}
                                    </SavedNotesContainer>
                                </>
                            ) : null}
                        </div>
                        <div className="col-lg-6">
                            {item.past_obstetric_history ? (
                                <>
                                    <Label name="Past obstetric history" />
                                    <SavedNotesContainer data-testid="obs_history">
                                        {splitNotes(item.past_obstetric_history)}
                                    </SavedNotesContainer>
                                </>
                            ) : null}
                            {item.family_history ? (
                                <>
                                    <Label name="Family history" />
                                    <SavedNotesContainer data-testid="f_history">
                                        {splitNotes(item.family_history)}
                                    </SavedNotesContainer>
                                </>
                            ) : null}
                            {item.notes || item.findings ? (
                                <>
                                    <Label name="Notes" />
                                    <SavedNotesContainer data-testid="clinical_notes">
                                        {splitNotes(item.notes || item.findings)}
                                    </SavedNotesContainer>
                                </>
                            ) : null}
                        </div>
                    </div>
                );
                return (
                    <NotesRow
                        open={item.openNote}
                        date={item.start_time ? formatDateTime(item.start_time) : ""}
                        handleOpen={() => handleOpen(visit_id, item.examination_notes_id)}
                        person={`${item.doctor_firstname} ${item.doctor_lastname}`}
                        colSpan={headData.length}
                    >
                        {viewNotes}
                    </NotesRow>
                );
            })}
        </PreviousNotesTable>
    );
};

export const PreviousLabResults = ({labResults}) => {
    return labResults?.length > 0 && (
        <>
            <ResultsHeading>
                <Heading>Lab Results</Heading>
                <Border />
            </ResultsHeading>
            {labResults.map((item) => {
                return (
                    <LabResultsTable item={item}/>
                );
            })}
        </>
    );
};

export const PreviousRadiology = ({radiologyResults,
                                      visit_id,
                                      handleOpenResults,
                                  }) => {
    return radiologyResults?.length > 0 && (
        <>
            <ResultsHeading>
                <Heading>Radiology Report(s)</Heading>
                <Border />
            </ResultsHeading>
            {radiologyResults.map((item, index) => {
                return (
                    <RadiologyResultReport
                        item={item}
                        handleOpenItem={() =>
                            handleOpenResults(
                                visit_id,
                                index,
                                radiologyResults,
                                "radiologyResults"
                            )
                        }
                    />
                );
            })}
        </>
    );
};

export const PreviousDiagnosis = ({ patientDiagnosis, editDiagnosis }) => {
    const diagnosis_arr = patientDiagnosis
        .filter((item) => item.notes)
        .map((item) => item.notes);
    const diag_notes = longestString(diagnosis_arr);
    return patientDiagnosis?.length > 0 && (
        <>
            <ResultsHeading>
                <Heading>Diagnosis</Heading>
                <Border />
            </ResultsHeading>
            {editDiagnosis}
            <table css={[table, detailsTable, tableBordered, paddingCell]}>
                <tr>
                    <td>
            <span>
              <strong>ICD code</strong>
            </span>
                    </td>
                    <td>
            <span>
              <strong>Disease</strong>
            </span>
                    </td>
                </tr>

                {patientDiagnosis
                    .filter(
                        (item) => !(item.diagnosis === null && item.diagnosis_code === null)
                    )
                    .map((item) => (
                        <tr key={item.diagnosis_id}>
                            <td>
                                <span>{item.diagnosis_code}</span>
                            </td>
                            <td>
                                <span>{item.diagnosis}</span>
                            </td>
                        </tr>
                    ))}
                <tr>
                    <td colSpan={2}>
            <span>
              <strong>Diagnosis Notes</strong>
            </span>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>{splitNotes(diag_notes)}</td>
                </tr>
            </table>
        </>
    );
};

export const PreviousProcedure = ({ procedure }) => {
    const headData = [
        { key: "date_created", value: "Date requested" },
        { key: "service_name", value: "Procedure" },
        { key: "quantity", value: "Quantity" },
    ];
    return procedure?.length > 0 && (
        <>
            <ResultsHeading>
                <Heading>Procedure(s)</Heading>
                <Border />
            </ResultsHeading>
            <ItemsTable headData={headData.filter((item) => Boolean(item))}>
                {procedure.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <span>{item.date_created ? formatDateTime(item.date_created) : null}</span>
                        </td>
                        <td>
                            <span>{item.procedure_name}</span>
                        </td>
                        <td>
                            <span>{item.quantity}</span>
                        </td>
                    </tr>
                ))}
            </ItemsTable>
        </>
    );
};

export const PatientAdmission = ({ admission=[] }) => {
    const headData = [
        { key: "admission_date", value: "Admission date" },
        { key: "admitted_by", value: "Admitted  by" },
        { key: "room", value: "Ward/Room/Bed" },
        { key: "no_of_days", value: "No. of days" },
        { key: "discharge_date", value: "Discharge date" },
    ];
    return admission?.length > 0 && (
        <>
            <ResultsHeading>
                <Heading>Admission</Heading>
                <Border />
            </ResultsHeading>
            <ItemsTable headData={headData.filter((item) => Boolean(item))}>
                {admission.map((item) => (
                    <tr key={item.bed_assignment_id}>
                        <td>
                            <span>{item.assign_date ? formatDateTime(item.assign_date) : null}</span>
                        </td>
                        <td>
                            <span>{`${item.admitted_by_first_name ? item.admitted_by_first_name : ''} ${item.admitted_by_last_name ? item.admitted_by_last_name : ''}`}</span>
                        </td>
                        <td>
                            <span>{`${item.ward_name} - ${item.room_name} - ${item.bed_number}`}</span>
                        </td>
                        <td>
                            <span>{item.quantity}</span>
                        </td>
                        <td>
                            <span>{item.end_date ? formatDateTime(item.end_date) : null}</span>
                        </td>
                    </tr>
                ))}
            </ItemsTable>
        </>
    );
};

export const PreviousPrescription = ({ medication }) => {
    const { subscription } = useVersionsContext();
    const version = subscription?.version;
    const isPremium = version === "Premium";
    const headData = [
        { key: "drug_name", value: "Drug Name" },
        isPremium ? { key: "quantity", value: "Quantity to bill" } : null,
        { key: "quantity_prescribed", value: "Quantity Prescribed" },
        { key: "dosage", value: "Dosage" },
        { key: "frequency", value: "Frequency" },
        { key: "number_of_days", value: "Number of Days" },
        { key: "instructions", value: "Instructions" },
        { key: "status", value: "Status" },
        { key: "date_created", value: "Date prescribed" },
        { key: "prescribed_by", value: "Prescribed by" },
        { key: "dispensed_at", value: "Date dispensed" },
        { key: "dispensed_by", value: "Dispensed by" },

    ];
    return medication?.length > 0 && (
        <>
            <ResultsHeading>
                <Heading>Prescription</Heading>
                <Border />
            </ResultsHeading>
            <ItemsTable headData={headData.filter((item) => Boolean(item))}>
                {medication.map((item, index) => {
                    const prescribed_by = `${
                        item.prescribed_by_first_name ? item.prescribed_by_first_name : ""
                    } ${
                        item.prescribed_by_last_name ? item.prescribed_by_last_name : ""
                    }`;
                    const dispensed_by = `${
                        item.dispensed_by_frist_name ? item.dispensed_by_frist_name : ""
                    } ${item.dispensed_by_last_name ? item.dispensed_by_last_name : ""}`;
                    const outPatientStatus = {
                        0: <PendingStatus>Pending</PendingStatus>,
                        1: <Processing>Paid</Processing>,
                        2: <FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                        3: <PendingStatus>Pending</PendingStatus>,
                        4: <FinishedStatus>Dispensed</FinishedStatus>,
                        7: <ExpiredStatus>Stopped</ExpiredStatus>,
                    };

                    const inPatientStatus = {
                        0: <PendingStatus>Pending</PendingStatus>,
                        1: <PendingStatus>Pending</PendingStatus>,
                        3: <PendingStatus>Pending</PendingStatus>,
                        2: <FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                        4: <FinishedStatus>Dispensed</FinishedStatus>,
                        7: <ExpiredStatus>Stopped</ExpiredStatus>,
                    };

                    const statusItem =
                        item.patient_type === 1
                            ? outPatientStatus[item.status]
                            : inPatientStatus[item.status];
                    return (
                        <tr key={index}>
                            <td>
                                <span>{item.product_name || item.other_medicine}</span>
                            </td>
                            {isPremium ? (
                                <td>
                                    <span>{item.quantity}</span>
                                </td>
                            ) : null}
                            <td>
                                <span>{item.quantity_prescribed || item.quantity}</span>
                            </td>
                            <td>
                                <span>{item.dosage}</span>
                            </td>
                            <td>
                                <span>{item.frequency}</span>
                            </td>
                            <td>
                                <span>{item.number_of_days}</span>
                            </td>
                            <td>
                                <span>{item.key_information}</span>
                            </td>
                            <td>
                                <span>{statusItem}</span>
                            </td>
                            <td>
                                <span>{item.date_created ? formatDateTime(item.date_created) : null}</span>
                            </td>
                            <td>
                                <span>{prescribed_by}</span>
                            </td>
                            <td>
                                <span>{item.dispensed_at ? formatDateTime(item.dispensed_at) : null}</span>
                            </td>
                            <td>
                                <span>{dispensed_by}</span>
                            </td>
                        </tr>
                    );
                })}
            </ItemsTable>
        </>
    );
};



